.step-form {
  position: relative;

  &.u-step-form {
    &--showing-success {
      .step-form__el,
      .step-form__cta-container {
        display: none;
      }

      .step-form__msg--success {
        display: block;
      }
    }

    &--showing-error {
      .step-form__msg--success {
        display: none;
      }

      .step-form__msg--error {
        display: block;
      }
    }

    &--showing-submit {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--submit {
        display: inline-flex;
      }
    }

    &--showing-next {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--next {
        display: inline-flex;
      }
    }

    &--showing-prev {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta.step-form__cta--prev {
        display: inline-flex;
      }
    }
  }

  &__msg {
    display: none;

    &--error {
      border: var(--emu-common-border-width-thin) solid
        var(--emu-semantic-colors-error-light);
      color: var(--emu-semantic-colors-error-light);
    }
  }

  &__cta {
    &-container {
      // needed to increase the specificity
      .emu-button.emu-button-v2.step-form__cta {
        display: none;

        @include aem-editor-view {
          display: inline-flex !important;
        }
      }
    }
  }
}
