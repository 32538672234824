.survey {
  &__modal {
    &--success {
      .modal__title {
        display: none;
      }
    }

    .modal-content {
      position: relative;

      .step-form {
        position: static;

        &__cta-container {
          gap: var(--emu-common-spacing-none);
        }
      }
    }
  }
}
