header {
  --eyebrow-header-height: 67px;

  @include mq('large') {
    --eyebrow-header-height: 84px;
  }

  &.experiencefragment {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: var(--emu-common-other-z-index-header);
  }

  &.header {
    position: relative;
  }
}

.header {
  &__desktop-navbar {
    display: none;
  }

  &__logo {
    img {
      max-width: 268px;
      height: auto;
      width: auto;

      @include mq('large') {
        max-width: 240px;
      }

      @include mq('desktop') {
        max-width: 268px;
      }
    }
  }

  // mobile nav bar with logo and the buttons
  &__mobile-navbar {
    > .container > .aaaem-container {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    &-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;

      // logo image
      > .image {
        max-width: calc(
          100% - 48px
        ); // 100% - menu trigger width - some spacing

        img {
          width: 100%;
        }
      }
    }

    &-trigger-container {
      position: relative;

      .button {
        position: static;
        display: flex;
      }
    }

    &-trigger {
      &--close {
        position: absolute;
        top: 50%;
        left: 0;
        pointer-events: none;
        opacity: 0;
        transform: translateY(-50%);

        img {
          width: var(--emu-component-layers-modal-close-size-width-narrow);
          height: var(--emu-component-layers-modal-close-size-height-narrow);
        }
      }

      &--open {
        &::after {
          content: '';
          position: fixed;
          top: 67px;
          left: 0;
          width: 100%;
          height: calc(100vh - 67px);
          background-color: var(--emu-semantic-colors-surface-modal-backdrop);
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  // navigation containers. Contains the eyebrow nav and the main nav
  &__navs-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    transition: all var(--emu-common-other-time-duration-instant) ease-out;
    max-height: calc(100vh - var(--eyebrow-header-height));

    @include mq('large') {
      transition: none;
      transform: none;
      position: relative;
      pointer-events: initial;
      opacity: 1;
      overflow: initial;
    }

    > .container {
      transform: translateX(100%);
      transition: inherit;

      @include mq('large') {
        transform: none;
      }
    }

    .u-container--large > .container > .aaaem-container {
      padding-top: var(--emu-semantic-spacing-horizontal-narrow);
      padding-bottom: var(--emu-semantic-spacing-horizontal-narrow);

      @include mq('large') {
        padding-top: 14px;
        padding-bottom: 14px;

        &.header__eyebrow-nav-inner {
          padding-top: 19px;
          padding-bottom: 19px;
        }
      }
    }
  }

  // eyebrow navigation with the AMI, Clinic Locator etc links
  &__eyebrow-nav {
    // class needed here to increase the specificity
    .emu-navigation__content-wrapper & {
      > ul {
        > li:last-child {
          &:hover {
            a {
              &::after {
                transform: scaleX(1) translateX(10px);
              }
            }
          }
          a {
            @include mq('large') {
              padding-right: var(--emu-common-spacing-none);
            }
          }
        }
      }
    }

    &-inner {
      @include mq('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      > .image {
        @include mq('large') {
          flex-shrink: 0;
          min-width: 268px;
        }
      }
    }

    &--desktop {
      .emu-navigation__menu--level-0 {
        @include mq('large') {
          gap: var(--emu-common-spacing-none);

          li a {
            padding-left: calc(
              0.5 * var(--emu-semantic-spacing-horizontal-narrow)
            );
            padding-right: calc(
              0.5 * var(--emu-semantic-spacing-horizontal-narrow)
            );
          }
        }

        @include mq('desktop') {
          gap: var(--emu-semantic-spacing-horizontal-narrow);

          li a {
            padding-left: var(--emu-semantic-spacing-horizontal-narrow);
            padding-right: var(--emu-semantic-spacing-horizontal-narrow);
          }
        }
      }
    }
  }

  // main navigation inner
  &__main-nav {
    &-container {
      @include mq('large') {
        background-color: rgba(
          255,
          255,
          255,
          0.5
        ); // no variable is present for this and is being used in only one place
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        transition: all var(--emu-common-other-time-duration-instant);
        border-bottom: var(--emu-common-border-width-thin) solid
          rgba(0, 0, 0, 0);
      }

      > .container > .aaaem-container {
        @include mq('large') {
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }

    &-inner {
      @include mq('large') {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  // login, register and user button container
  // specificity is required to override some common styles
  .header__cta-container {
    display: flex;
    gap: var(--emu-common-spacing-large);
    margin-top: var(--emu-common-spacing-medium);

    @include mq('large') {
      margin-top: var(--emu-common-spacing-none);
    }
  }
}

#header-main-wrapper,
#header-main-wrapper-loggedin {
  &.js-toggle-on {
    .header {
      &__mobile-navbar-trigger {
        &--open {
          img {
            opacity: 0;
          }
        }

        &--close {
          opacity: 1;
        }
      }

      &__navs-container {
        opacity: 1;
        pointer-events: initial;

        > .container {
          transform: translateX(0);
        }
      }
    }
  }
}

.mobile-menu-open {
  header.experiencefragment .header__mobile-navbar-trigger--open::after {
    @include mq('1023px', 'max-width') {
      opacity: 1;
      pointer-events: initial;
    }
  }

  .header__navs-container {
    @include mq('1023px', 'max-width') {
      overflow-y: auto;
    }
  }
}

.has-sticky-header {
  .header__main-nav-container {
    @include mq('large') {
      background-color: var(--emu-common-colors-white);
      border-bottom-color: rgba(0, 0, 0, 0.1);
    }
  }
}
