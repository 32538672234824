.knowledge-hub {
  &__grid {
    display: grid;
    justify-content: center;
    gap: var(--emu-semantic-spacing-horizontal-wide);

    @include mq('large') {
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      gap: var(--emu-semantic-spacing-horizontal-narrow);
    }

    @include mq('x-large') {
      gap: var(--emu-semantic-spacing-horizontal-wide);
    }

    > .container {
      max-width: 450px;

      @include mq('large') {
        max-width: 100%;
      }
    }
  }

  &__card {
    position: relative;
  }

  &__image {
    img {
      min-height: 412px;
      object-fit: cover;
      width: 100%;
    }
  }

  &__card-text {
    position: absolute;
    bottom: var(--emu-semantic-spacing-horizontal-narrow);
    border-radius: var(--emu-common-border-radius-xs);
    background: rgba(255, 255, 255, 0.8);
    max-width: 100%;
    margin-left: var(--emu-semantic-spacing-horizontal-narrow);
    margin-right: var(--emu-semantic-spacing-horizontal-narrow);

    @include aem-editor-view {
      position: static;
    }
  }
}
