@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Regular.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 200;
}

@font-face {
  font-family: 'Beatrice';
  src: url('./assets/fonts/Beatrice/Beatrice-Light.woff2') format('woff2'),
    url('./assets/fonts/Beatrice/Beatrice-Light.woff') format('woff');
  font-weight: 300;
}
