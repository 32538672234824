@mixin expandNestedMenuStyles {
  > ul {
    max-height: 500px;
    opacity: 1;
    left: 0;

    > li {
      a,
      span {
        pointer-events: initial;
      }
    }
  }
}

.emu-navigation {
  &__content-wrapper {
    position: static;

    a,
    span {
      sup {
        font-size: 80%;
        top: 1px;
      }
    }

    ul {
      margin-top: var(--emu-common-spacing-none);
      margin-bottom: var(--emu-common-spacing-none);
    }

    // first level menu, the main navigation (wrapper of home, support, resources, products etc)
    nav > ul {
      flex-direction: column;

      @include mq('large') {
        flex-direction: row;
        flex-wrap: wrap;
        gap: var(--emu-semantic-spacing-horizontal-narrow);
        align-items: center;
      }

      // first level menu items, the main navigation items (home, support, resources, products etc)
      > li {
        @include mq('large') {
          // only first level menu item should open on hover, while the inner nested elements will open on click
          &.emu-navigation__item-parent:hover {
            @include expandNestedMenuStyles();
          }

          // on hover showing a fancy underline for the links
          &:hover,
          &.emu-navigation__item--active {
            > a,
            > span {
              &::after {
                transform: scaleX(1) translateX(0);
                transform-origin: bottom left;
              }
            }
          }
        }

        > a,
        > span {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: var(--emu-common-border-width-thin);
            transform: scaleX(0) translateX(-20px);
            transform-origin: bottom right;
            background-color: currentColor;
            transition: transform var(--emu-common-other-time-transition-short)
              ease-out;
            transform-origin: right;
          }
        }
      }

      // inner nested menu items
      li {
        &.emu-navigation__item.emu-navigation__item-parent ul {
          &.emu-navigation__menu--level-1 {            
            @include mq('large') {
              position: absolute;
            }
          }
        }

        ul {
          position: static;
          margin-left: var(--emu-semantic-spacing-horizontal-narrow);
  
          // first level submenu
          &.emu-navigation__menu--level-1 {
            @include mq('large') {
              margin-left: var(--emu-common-spacing-none);
              top: 100%;
              left: 0;
              min-width: 300px;
            }
          }
        }
      }

      li {
        // submenu expansion related logic
        &.emu-navigation__item-parent {
          position: relative;

          &.js-open--mobile,
          &.emu-navigation__item-parent--active {
            @include expandNestedMenuStyles;

            > ul {
              position: relative;
            }
          }

          > ul {
            display: block;
            max-height: var(--emu-common-sizing-none);
            overflow: hidden;
            transition: all var(--emu-common-other-time-duration-instant) linear;
            opacity: 0;
            left: -22px;

            a,
            span {
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}
