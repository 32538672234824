// caption related styles in figma
.u-body-font--xs {
  font: var(--emu-semantic-typography-narrow-body-xs);
  @include letterSpacing('default');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-xs);
    @include letterSpacing('md');
  }
}

// body2 related styles in figma
.u-body-font--small {
  font: var(--emu-semantic-typography-narrow-body-small);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-small);
  }
}

// subhead2 related styles from figma
.u-body-font--large {
  font: var(--emu-semantic-typography-narrow-body-large);

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-large);
  }
}

// subhead1 related styles from figma
.u-body-font--xl {
  font: var(--emu-semantic-typography-narrow-body-xl);
  @include letterSpacing('sm');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-xl);
  }
}

.u-body-font--eyebrow {
  @include eyebrowFont;
}

.u-body-font--link {
  font: var(--emu-semantic-typography-narrow-body-link);
  @include letterSpacing('l');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-link);
  }
}

.u-body-font--cta {
  font: var(--emu-semantic-typography-narrow-body-cta);
  @include letterSpacing('l');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

.u-body-font--h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

.u-body-font--h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

.u-body-font--h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

.u-body-font--h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

.u-body-font--h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

.u-body-font--h6 {
  font: var(--emu-component-ingredients-title-h6-typography-narrow);

  @include mq('large') {
    font: var(--emu-component-ingredients-title-h6-typography-wide);
  }
}

[class*='u-body-font'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span {
    font: inherit !important;
    color: inherit !important;
    letter-spacing: inherit !important;
    text-transform: inherit !important;
  }
}

// makes the text capitals
.u-text-transform--all-capitals {
  text-transform: uppercase;
}

// adds text with no breaks
.u-text-break--no-break {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a,
  span {
    white-space: nowrap;
  }
}

// adds light font weight to text elements
.u-font-weight--light {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span,
  a {
    font-weight: var(--emu-semantic-font-weight-light);
  }
}

// adds bold font weight to text elements
.u-font-weight--bold {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span,
  a {
    font-weight: var(--emu-semantic-font-weight-bold);
  }
}

// aligns heading in center only in mobile
.u-text-align--center-narrow {
  text-align: center;

  @include mq('large') {
    text-align: left;
  }
}

// aligns text elements in center
[class*='u-text-align--'] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  span,
  a,
  button {
    text-align: inherit !important;
  }
}

.u-text-align--center {
  text-align: center;
}

.u-text-align--right {
  text-align: right;
}

.u-text-align--left {
  text-align: left;
}
