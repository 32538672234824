html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400% 0;
  }
  100% {
    background-position: 400% 0;
  }
}

body {
  background-color: var(--emu-common-colors-light-brown-100);
  transition: background-color 0.6s ease-in, opacity 0.6s ease-in;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    height: 496px;
    z-index: var(--emu-common-other-z-index-header);
    pointer-events: none;
    animation-duration: 10s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #f6f7f8;
    background: linear-gradient(to right, #f5ecea 8%, #e0c4bd 50%, #f5ecea 92%);
    background-size: 400%;
    overflow: hidden;
    transition: opacity 0.4s ease-in-out;

    @include aem-editor-view {
      display: none !important;
    }
  }

  &::after {
    top: 496px;
    max-width: 1120px;
    transform: translateX(-50%);
    left: 50%;
    height: 100vh;
    background: linear-gradient(to right, #f1f1f1 8%, #fff 50%, #f1f1f1 92%);
  }

  header + div,
  header + main,
  footer,
  .footer {
    opacity: 0;
    transition: all 0.6s ease-in 0.4s;

    @include aem-editor-view {
      opacity: 1 !important;
    }
  }

  &.visible {
    background-color: var(--emu-common-colors-transparent);

    &::before,
    &::after {
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
    }

    header + div,
    header + main,
    footer,
    .footer {
      opacity: 1;
    }
  }
}

html,
body {
  scroll-padding-top: 70px;
  font: var(--emu-semantic-typography-narrow-body-regular);

  @include mq('large') {
    scroll-padding-top: 160px;
    font: var(--emu-semantic-typography-wide-body-regular);
  }

  // Adobe adds a negative margin, need to overwrite. Do not remove.
  .xf-content-height {
    margin: inherit;
  }

  .experiencefragment {
    padding: 0;
  }
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a,
span {
  margin: 0;
  padding: 0;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*,
::before,
::after {
  box-sizing: border-box;
}

.cmp-image__image,
image {
  max-width: 100%;
  width: auto;
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
}

sup {
  line-height: 0;
  position: relative;
  vertical-align: super;
  top: 3px;
  font-size: 80%;
}

a {
  color: inherit;

  &:hover,
  &:focus,
  &,
  &:active {
    text-decoration: underline;
    text-underline-offset: var(--emu-common-spacing-xs);
  }
}

ul,
ol {
  padding-left: var(--emu-common-spacing-medium);
  margin-top: var(--emu-semantic-spacing-half-line-height-narrow);
  margin-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
}

label {
  cursor: pointer;
}

main [data-component='text'] a {
  text-decoration: underline;
  text-underline-offset: var(--emu-common-spacing-xs);
}
