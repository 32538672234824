.aaaem-tabs {
  &.aaaem-tabs__horizontal-ui > ul:first-child li {
    margin-left: var(--emu-common-spacing-none);
  }

  &__tab {
    a {
      text-align: center;
      border-radius: 12px 12px 0 0;
      font-family: var(
        --emu-semantic-font-families-heading
      ) !important; // needed to override the styles from the mixin below.
      // mixin needs to the the last declaration. So using important in the above line
      @include eyebrowFont;

      &,
      &:active,
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }

  &__tabpanel {
    &[hidden] {
      @include aem-editor-view {
        visibility: hidden !important;
        position: static !important;
        display: none !important;
      }
    }
  }
}
