[data-component='carousel-v2'].emu-carousel {
  // hides liveregion, adds carousel arrows on either sides of the slide
  &.u-carousel--regular {
    .tns-liveregion {
      display: none;
    }

    .tns-controls.js-show {
      display: block;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      padding: var(--emu-common-spacing-none);
    }

    .emu-carousel {
      &__action {
        position: absolute;
        top: 0;
        background-color: var(--emu-common-colors-transparent);
        height: var(--emu-common-sizing-small);

        &-prev {
          transform: translateX(-100%) translateY(-50%) translateY(-8px);

          @include mq('large') {
            transform: translateX(-100%)
              translateX(
                calc(-1 * var(--emu-semantic-spacing-horizontal-narrow))
              )
              translateY(-50%) translateY(-8px);
          }
        }

        &-next {
          transform: translateX(100%) translateY(-50%) translateY(-8px);

          @include mq('large') {
            transform: translateX(100%)
              translateX(var(--emu-semantic-spacing-horizontal-narrow))
              translateY(-50%) translateY(-8px);
          }
        }
      }
    }
  }

  // hides liveregion, arrows, indicators, and centers the content
  &.u-carousel--disabled.emu-carousel {
    .tns-inner {
      display: flex;
      justify-content: center;
    }

    .emu-carousel__content {
      transform: none !important; // needed to override the styles added via JS
      width: auto !important; // needed to override the styles added via JS
    }

    .tns-slide-cloned {
      display: none;
    }
  }

  // hides arrows only in mobile view
  &.u-carousel--hide-arrows-in-mobile.emu-carousel {
    @include mq('1023px', 'max-width') {
      .tns-controls.js-show {
        display: none;
      }
    }
  }

  // vertically aligns the content to the center
  &.u-carousel--vertical-center-content {
    .emu-carousel__content {
      display: flex;
      align-items: center;
    }
  }
}

.u-carousel-title {
  margin-bottom: -24px; // height of the carousel count and arrows
  max-width: 40%;

  @include mq('large') {
    max-width: 50%;
  }

  @include aem-editor-view {
    margin-bottom: var(--emu-common-spacing-none);
  }
}
