.pagination {
  button {
    width: 48px;
    height: 48px;
    max-width: 13vw; // making sure that a horizontal bar is not shown
    background-color: var(--emu-common-colors-light-grey-500);
    border: var(--emu-common-border-width-thin) solid
      var(--emu-common-colors-black);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      background-color: var(--emu-common-colors-blush-100);
    }
  }
}
