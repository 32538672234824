.emu-language-nav[data-layout=pipes] {
  @include mq('medium') {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
    padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  }

  @include mq('large') {
    padding: var(--emu-common-spacing-none);
  }

  @include mq('desktop') {
    margin-right: var(--emu-semantic-spacing-horizontal-wide);
  }

  .emu-language-nav {
    &__item {
      position: relative;
      font-size: var(--emu-semantic-font-sizes-narrow-xs);
      line-height: var(--emu-semantic-line-heights-narrow-medium);
      @include letterSpacing('l');

      // Using custom pipe instead of the default one provided by clientlib to match the design
      &:after {
        content: '';
        position: absolute;
        height: 45%;
        width: var(--emu-common-sizing-xxs);
        right: 0;
        top: 28%;
        background-color: var(--emu-common-colors-white);
      }

      &:last-of-type {
        &:after {
          content: none;
        }
      }

      // overriding the selected language with bold instead of underline
      &--active {
        a.emu-language-nav__item-link {
          font-weight: var(--emu-semantic-font-weight-bold);

          &:after {
            content: none;
          }
        }
      }
    }
  }
}