.teaser {
  position: relative;

  &:has(.inline-text-below) {
    height: 412px; // this will be removed when image inside the teaser is completely loaded on the page.
    transition: all 0.4s linear;
    overflow: hidden;
    min-height: 412px;

    .cmp-image__image--is-loading {
      max-height: 412px !important; // needed to override the default styles
    }

    // as a part of AAOUS-1095 and 1096, in the headInclude via caConfig, added the following internal styles, so that they will be applied as soon as the page is loaded
    // <style>body { transition: all 0.6s ease-in; background-color: #f5ecea;} body header + div, body header + main {opacity: 0;} body.visible { background-color: transparent; } body.visible header + div, body.visible header + main{opacity: 1;} .teaser {height: 412px; overflow:hidden;background-color: #e9dedd}.teaser img {object-fit: cover; opacity: 0; height: 412px; transition: all .4s linear;} .teaser .cmp-image__image--is-loading { max-height: 412px !important; }</style>

    img {
      height: 412px; // this will be removed when image is completely loaded on the page.
      transition: all 0.4s linear;
      object-fit: cover;
      opacity: 0; // this will be removed when image is completely loaded on the page.
    }

    &.u-teaser--img-loaded {
      height: auto;
      background-color: var(--emu-common-colors-transparent);
      min-height: 0;

      img {
        max-height: 1000px;
        opacity: 1;
        height: auto;
      }
    }
  }

  .inline-text-below {
    // container containing the image and content as its children
    .teaser-container {
      display: flex;
      flex-direction: column-reverse;
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      position: relative;

      @include mq('large') {
        display: block;
        max-width: var(--emu-semantic-sizing-container);
      }
    }

    &.cmp-teaser .teaser-image-container {
      .cmp-teaser__image {
        img {
          width: 100%;

          @include mq('large') {
            min-height: 412px;
            object-fit: cover;
          }
        }
      }
    }

    .content-container {
      position: static;
      padding: var(--emu-component-cards-teaser-padding-top-narrow)
        var(--emu-component-cards-teaser-padding-right-narrow)
        var(--emu-component-cards-teaser-padding-bottom-narrow)
        var(--emu-component-cards-teaser-padding-left-narrow);

      @include mq('large') {
        position: absolute;
        top: 74px;
        height: calc(100% - 74px);
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: var(--emu-common-sizing-xxxl);
        padding-top: var(--emu-semantic-spacing-vertical-wide);
        min-height: 338px; // making sure that there is some minimum height to the content
      }

      @include mq('desktop') {
        padding-left: var(--emu-common-spacing-none);
        padding-right: var(--emu-common-spacing-none);
      }

      .content {
        width: fit-content;

        .title-lockup {
          margin-bottom: var(--emu-common-spacing-none);
        }

        &.content-top-left,
        &.content-middle-left,
        &.content-bottom-left {
          margin-left: var(--emu-common-spacing-none);
        }

        &.content-top-right,
        &.content-middle-right,
        &.content-bottom-right {
          margin-right: var(--emu-common-spacing-none);
        }

        // all the immediate children of the content
        > div {
          @include mq('large') {
            max-width: min(45vw, 600px); // as per the design
            margin: var(--emu-common-spacing-none);
            width: 100%;
          }
        }

        br {
          display: none;

          @include mq('large') {
            display: block;
          }
        }

        .emu-teaser {
          &__title {
            color: inherit;
            margin: var(--emu-common-spacing-none);
          }

          &__pretitle {
            margin-top: var(--emu-common-spacing-medium);
            @include eyebrowFont;
          }

          &__subtitle {
            margin-top: var(--emu-common-spacing-medium);
            font: var(--emu-component-ingredients-title-h4-typography-narrow);

            @include mq('large') {
              font: var(--emu-component-ingredients-title-h4-typography-wide);
            }
          }

          &__description {
            margin-top: var(--emu-common-spacing-small);
            padding: var(--emu-common-spacing-none);

            p {
              display: block !important; // important is needed override the inline style being added
            }
          }

          &__action-container {
            margin-top: var(--emu-common-spacing-large);
            display: flex;
            gap: var(--emu-common-spacing-medium);
          }

          &__subtext {
            padding-top: var(--emu-common-spacing-none);
            margin-top: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));
            font: var(--emu-semantic-typography-narrow-body-xs);
            @include letterSpacing('md');

            p,
            li {
              font-size: inherit;
              line-height: inherit;
              color: inherit;
              font-weight: inherit;
              font-family: inherit;
            }
          }
        }
      }
    }

    .emu-teaser__disclaimer {
      position: absolute;
      bottom: 10px;
      left: 0;
      width: 100%;
      padding: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-medium);
      padding-right: var(--emu-common-spacing-medium);
      margin-top: var(--emu-common-spacing-none);
      display: none;
      font: var(--emu-semantic-typography-narrow-body-xs);
      @include letterSpacing('md');

      @include aem-editor-view {
        display: block;
      }

      &.show {
        display: block;
      }

      &.text-theme-light {
        color: var(--emu-component-cards-teaser-text-color-light);
      }

      &.text-theme-dark {
        color: var(--emu-component-cards-teaser-text-color-dark);
      }

      p,
      li {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
        font-weight: inherit;
        font-family: inherit;
      }
    }
  }
}
