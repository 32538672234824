.article-list {
  .article {
    &__filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--emu-common-spacing-large);

      @include mq('large') {
        flex-direction: row;
      }

      .emu-form-dropdown {
        display: flex;
        flex-direction: column;
      }

      .emu-dropdown-menu {
        &__button {
          min-width: 200px;
          height: 58px;

          svg {
            width: var(--emu-common-sizing-small);
          }
        }
      }
    }

    &__pagination {
      .first-btn,
      .last-btn,
      .page-counter {
        display: none;
      }
    }

    &__cta {
      &--back {
        // commented for reason. May have to revert
        // .emu-button-v2 {
        //   gap: var(--emu-common-spacing-small);
        // }

        a {
          &::before {
            content: '';
            background-image: url('../../assets/images/prev-icon.png');
            width: var(--emu-common-sizing-small);
            height: var(--emu-common-sizing-small);
            display: inline-block;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
      }
    }

    &__grid {
      gap: 20px;
      // gap: var(--emu-common-spacing-small); // commented for reason. May have to revert
      padding-top: var(--emu-common-spacing-large);

      @include mq('medium') {
        // gap: var(--emu-semantic-spacing-horizontal-wide);  // commented for reason. May have to revert
        grid-template-columns: repeat(2, 300px);
        justify-content: center;
      }

      @include mq('large') {
        padding-top: var(--emu-common-spacing-xl);
        grid-template-columns: repeat(3, 1fr);
        margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
        margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
        max-width: none;
      }
    }

    &__message {
      min-height: 348px;
      display: block;

      &--hidden {
        display: none;
      }
    }
  }
}
