.ami {
  &__wrapper {
    position: relative;

    @include mq('large') {
      display: flex;
      flex-wrap: wrap;
    }

    > .container:has(.ami__content) {
      @include mq('large') {
        width: 268px;
        margin-right: calc(2 * var(--emu-semantic-spacing-horizontal-narrow));
      }
    }

    > .container:has(.ami__main) {
      @include mq('large') {
        flex: 1;
        align-self: center;
      }
    }

    &--has-msg {
      .ami__tab-content--has-msg {
        .ami__msg-container {
          display: flex;
        }
      }
    }

    &--non-ami {
      .ami__msg--non-ami {
        display: block;
      }
    }

    &--no-courses {
      .ami__msg--no-courses {
        display: block;
      }
    }

    &--no-events {
      .ami__msg--no-events {
        display: block;
      }
    }
  }

  &__content {
    .image {
      display: flex;
      justify-content: center;

      @include mq('large') {
        justify-content: flex-start;
      }
    }

    img {
      max-width: min(400px, 100%);
      height: 300px;
      object-fit: contain;
      object-position: left top;
    }
  }

  &__main {
    @include mq('large') {
      min-height: 350px;
      display: flex;
    }

    @include aem-editor-view {
      display: block !important;
    }

    .tabs {
      @include mq('large') {
        min-height: 100%;
        flex: 1;
      }
    }
  }

  &__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui {
    @include mq('large') {
      display: flex;
      flex-wrap: wrap;
      min-height: 100%;
    }

    .aaaem-tabs {
      &__tablist {
        @include mq('large') {
          flex-direction: column;
          min-height: 100%;
          border-right: var(--emu-common-border-width-medium) solid
            var(--emu-common-colors-dark-brown-700);
        }

        .aaaem-tabs__tab {
          @include mq('large') {
            writing-mode: vertical-rl;
            margin: var(--emu-common-spacing-none);
            transform: rotate(180deg);
            margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);
          }

          a {
            @include mq('large') {
              padding: var(
                  --emu-component-containers-tabs-navigation-item-padding-right-wide
                )
                var(
                  --emu-component-containers-tabs-navigation-item-padding-top-wide
                )
                var(
                  --emu-component-containers-tabs-navigation-item-padding-left-wide
                )
                var(
                  --emu-component-containers-tabs-navigation-item-padding-bottom-wide
                );
              border-radius: 0 12px 12px 0;
            }
          }
        }
      }

      &__tabpanels {
        border-radius: var(--emu-common-border-radius-xs);
        border-top: var(--emu-common-border-width-medium) solid
          var(--emu-common-colors-dark-brown-500);

        @include mq('large') {
          border: none;
          flex: 1;
          position: relative;
        }
      }

      // individual panel
      &__tabpanel {
        opacity: 1;
        transition: all 0.2s linear 0.4s;

        &[hidden] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          pointer-events: none;
          transition: all 0.2s linear;
        }

        @include mq('large') {
          min-height: 100%;
        }

        &:has(.ami__tab-content--has-msg) {
          @include mq('large') {
            display: flex;
            align-items: center;
            width: 100%;
          }

          > .container {
            @include mq('large') {
              width: 100%;
            }
          }
        }
      }
    }
  }

  &__msg {
    display: none;

    &-container {
      display: none;
      min-height: 200px;
      justify-content: center;
      align-items: center;

      @include mq('large') {
        justify-content: flex-start;
      }
    }

    @include aem-editor-view {
      display: block;
    }
  }

  &__stage {
    margin-top: var(--emu-semantic-spacing-vertical-narrow);

    &.aaaem-container {
      padding-left: var(--emu-common-spacing-none);
    }

    .ami__card:first-child {
      @include mq('large') {
        margin-top: var(--emu-common-spacing-none);
      }
    }
  }

  &__card {
    margin-top: var(--emu-semantic-spacing-vertical-narrow);
    background-color: var(--emu-common-colors-light-grey-100);
    border-radius: var(--emu-common-border-radius-xs);
    padding-top: var(--emu-semantic-spacing-vertical-narrow);
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
    padding-left: calc(0.5 * var(--emu-semantic-spacing-horizontal-narrow));
    padding-right: calc(0.5 * var(--emu-semantic-spacing-horizontal-narrow));

    @include mq('large') {
      padding-left: var(--emu-semantic-spacing-horizontal-narrow);
      padding-right: var(--emu-semantic-spacing-horizontal-narrow);
    }

    &-inner-container {
      @include mq('large') {
        display: flex;
      }
    }

    &-img-container {
      margin-bottom: var(--emu-common-spacing-medium);

      @include mq('large') {
        margin-bottom: var(--emu-common-spacing-none);
        margin-right: var(--emu-semantic-spacing-horizontal-narrow);
      }

      img {
        width: 125px;
        height: auto;
        display: block;
      }
    }

    &-content {
      &-container {
        @include mq('large') {
          flex: 1;
        }
      }

      &-pretitle-container {
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: var(--emu-common-spacing-small);
        }
      }

      &-pretitle {
        &--type {
          display: flex;
          margin-bottom: var(--emu-common-spacing-xs);

          @include mq('large') {
            margin-right: var(--emu-semantic-spacing-horizontal-narrow);
            margin-bottom: var(--emu-common-spacing-none);
          }

          &::before {
            content: '';
            width: 17px;
            height: 13px;
            background-image: url('../../assets/images/user-icon.png');
            background-size: cover;
            display: inline-block;
            margin-right: 12px;
          }
        }
      }

      &-title {
        margin-bottom: var(--emu-common-spacing-medium);

        @include mq('large') {
          margin-bottom: var(--emu-common-spacing-small);
        }
      }

      &-cta {
        .emu-button-v2.emu-button {
          font-family: var(--emu-semantic-font-families-heading);
        }
      }
    }
  }
}
