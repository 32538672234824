// labels of the form
.emu-form-text__label,
.emu-form-textarea__label,
.emu-form-dropdown__label,
.emu-form-checkbox__label,
.emu-form-radio__label {
  margin-left: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  cursor: pointer;
}

.emu-form-checkbox__label,
.emu-form-radio__label {
  margin-bottom: var(--emu-common-spacing-none);
}

.emu-form-text__label,
.emu-form-textarea__label,
.emu-form-dropdown__label {
  display: inline-block;
  font: var(--emu-semantic-typography-narrow-body-cta);
  @include letterSpacing('l');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

// radio button and checkbox styles
.options {
  .emu-form-radio,
  .emu-form-checkbox {
    display: flex;
    column-gap: var(--emu-semantic-spacing-horizontal-narrow);
    row-gap: var(--emu-common-spacing-medium);
    flex-wrap: wrap;

    .emu-form-checkbox__label,
    .emu-form-radio__label {
      @include letterSpacing('sm');
      gap: var(--emu-common-spacing-none);

      span {
        margin-left: var(
          --emu-component-ingredients-checkbox-input-label-gap-narrow
        );
      }
    }

    &:not([data-layout='stacked']) {
      gap: var(--emu-common-spacing-none);

      label {
        margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);

        &:not(:last-child) {
          margin-right: var(--emu-semantic-spacing-horizontal-narrow);
        }

        &:only-of-type {
          margin-bottom: var(--emu-common-spacing-none);
          margin-right: var(--emu-common-spacing-none);
        }
      }
    }

    &[data-layout='stacked'] {
      flex-direction: column;
      gap: var(--emu-common-spacing-none);

      .emu-form-checkbox__label,
      .emu-form-radio__label {
        display: flex;
        padding: var(--emu-semantic-spacing-half-line-height-narrow)
          var(--emu-common-spacing-medium);
        border: var(--emu-common-border-width-thin) solid
          var(--emu-common-colors-light-grey-400);

        &:not(:last-of-type) {
          margin-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
        }
      }
    }
  }

  .emu-form-radio {
    &[data-customize] {
      .emu-form-radio {
        &__input {
          cursor: pointer;
          margin-bottom: var(--emu-common-spacing-none);
          min-width: var(
            --emu-component-ingredients-radio-input-control-size-all-narrow
          );

          @include mq('large') {
            min-width: var(
              --emu-component-ingredients-radio-input-control-size-all-wide
            );
          }

          &:checked:hover {
            background-color: var(
              --emu-component-ingredients-radio-input-control-color-background-checked-light
            );
            border-color: var(
              --emu-component-ingredients-radio-input-control-color-border-checked-light
            );
          }

          &::before {
            content: '';
            background-color: CanvasText;
            box-shadow: inset 1em 1em
              var(
                --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light
              );
            clip-path: polygon(
              14% 44%,
              0 65%,
              50% 100%,
              100% 16%,
              80% 0,
              43% 62%
            );
            display: block;
            height: 100%;
            transform: scale(0) rotate(60deg);
            transition: all var(--emu-common-other-time-transition-short) linear;
            transform-origin: center;
            width: 100%;
          }

          &:checked::before {
            transform: scale(0.75) rotate(9deg);
          }
        }

        &__label {
          align-items: center;
        }
      }
    }
  }

  .emu-form-checkbox {
    &[data-customize] {
      .emu-form-checkbox {
        &__input {
          margin-bottom: var(--emu-common-spacing-none);
          cursor: pointer;
          min-width: var(
            --emu-component-ingredients-checkbox-input-control-size-all-narrow
          );

          @include mq('large') {
            min-width: var(
              --emu-component-ingredients-checkbox-input-control-size-all-wide
            );
          }

          &::before {
            transform: scale(0) rotate(60deg);
            transition: all var(--emu-common-other-time-transition-short) linear;
          }

          &:checked::before {
            transform: scale(0.75) rotate(9deg);
          }

          &:checked:hover {
            background-color: var(
              --emu-component-ingredients-checkbox-input-control-color-background-checked-light
            );
            border-color: var(
              --emu-component-ingredients-checkbox-input-control-color-border-checked-light
            );
          }
        }

        &__label {
          align-items: center;
        }
      }
    }
  }
}

// form element fonts
[data-component='form-text-input'].emu-form-text input,
[data-component='form-text-textarea'].emu-form-textarea textarea,
.emu-form-dropdown .emu-dropdown-menu__button,
.emu-form-dropdown .emu-dropdown-menu__item {
  font: var(--emu-semantic-typography-narrow-body-cta);
  @include letterSpacing('l');

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

// text area specific styles
[data-component='form-text-textarea'].emu-form-textarea {
  .emu-form-textarea__container {
    width: 100%;
  }

  textarea {
    resize: none;
    width: 100%;

    &::placeholder {
      color: inherit;
    }
  }
}

// input specific styles
[data-component='form-text-input'].emu-form-text {
  input {
    width: 100%;

    &::placeholder {
      color: inherit;
    }
  }
}

// select dropdown styles
.emu-form-dropdown {
  position: relative;

  [data-disabled] .emu-dropdown-menu__button {
    background-color: var(--emu-common-colors-transparent);
  }

  // default dropdown styles
  &:not(.emu-form-dropdown__outline) {
    .emu-dropdown-menu__button {
      background-color: var(
        --emu-component-ingredients-select-input-option-color-background-hover-filled-light
      );
    }

    .emu-dropdown-menu__group {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  &.emu-form-dropdown.js-hide-dropdown {
    .emu-dropdown-menu__group {
      transform: translateY(40px);
      opacity: 0;
      pointer-events: none;
      height: auto;
    }

    .emu-dropdown-menu__item {
      visibility: visible;
    }
  }

  &.emu-form-dropdown:not(.js-hide-dropdown) {
    .emu-dropdown-menu__group {
      transform: translateY(0);
      opacity: 1;
      pointer-events: initial;
      z-index: var(--emu-common-other-z-index-overlay);
    }
  }

  .emu-dropdown-menu {
    &__group {
      max-width: 100%;
      width: 100% !important; // to override the inline styles being appended via JS
      transition: all var(--emu-common-other-time-transition-short) linear;
    }

    &__button {
      text-align: left;
      width: 100% !important; // to override the inline styles being appended via JS

      svg {
        transition: all var(--emu-common-other-time-transition-short) linear;
        width: 20px;
        height: auto;
      }
    }

    &__item {
      visibility: visible;
    }
  }
}

// custom input checkbox/radio with label
.input-with-custom-text {
  margin-bottom: var(--emu-common-spacing-none);
  display: inline-flex;
  position: relative;
  z-index: var(--emu-common-other-z-index-layer);

  [data-ref='error-message'].js-show,
  label span {
    display: none;
  }

  label {
    margin-bottom: var(
      --emu-common-spacing-none
    ) !important; // important is needed to override the other styles from form elements
  }

  &__label {
    position: relative;
    z-index: var(--emu-common-other-z-index-layer);
    padding-left: calc(
      var(--emu-component-ingredients-checkbox-input-control-size-all-narrow) +
        var(--emu-component-ingredients-checkbox-input-label-gap-narrow)
    );
    margin-top: calc(
      (
        -1 * var(--emu-component-ingredients-checkbox-input-control-size-all-narrow)
      )
    );
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
    cursor: pointer;
    @include letterSpacing('sm');

    > * {
      color: inherit;
    }

    &--has-error {
      color: var(
        --emu-component-ingredients-checkbox-input-error-message-text-color-light
      );
    }

    @include mq('large') {
      padding-left: calc(
        var(--emu-component-ingredients-checkbox-input-control-size-all-wide) +
          var(--emu-component-ingredients-checkbox-input-label-gap-wide)
      );
      margin-top: calc(
        (
          -1 * var(--emu-component-ingredients-checkbox-input-control-size-all-wide)
        )
      );
    }

    @include aem-editor-view {
      margin-top: var(--emu-common-spacing-none);
      padding-left: var(--emu-common-spacing-none);
    }
  }

  &__msg {
    margin-top: calc(
      (
        -1 * var(--emu-component-ingredients-checkbox-input-control-size-all-narrow)
      )
    );
    margin-bottom: var(--emu-semantic-spacing-vertical-wide);

    @include mq('large') {
      margin-top: calc(
        (
          -1 * var(--emu-component-ingredients-checkbox-input-control-size-all-wide)
        )
      );
    }

    @include aem-editor-view {
      margin-top: var(--emu-common-spacing-none);
      display: block !important;
    }
  }
}

// error message styles
[data-ref='error-message'],
.input-with-custom-text__msg {
  font: var(--emu-semantic-typography-narrow-body-cta);
  @include letterSpacing('sm');
  color: var(
    --emu-component-ingredients-checkbox-input-error-message-text-color-light
  );

  @include mq('large') {
    font: var(--emu-semantic-typography-wide-body-cta);
  }

  p {
    font: inherit;
    color: inherit;
    letter-spacing: inherit;
  }
}

.options {
  .emu-form-radio,
  .emu-form-checkbox {
    &.js-has-error {
      flex-wrap: wrap;

      [data-ref='error-message'] {
        width: 100%;
      }
    }
  }
}

// main error message of the form
.emu-form__error-message {
  padding: var(--emu-component-ingredients-text-input-text-padding-wide);
  border: var(--emu-component-ingredients-text-input-border-width-wide) solid
    var(--emu-component-ingredients-text-input-color-border-error-light);
  color: var(
    --emu-component-ingredients-text-input-label-text-color-error-light
  );
  margin-left: var(--emu-semantic-spacing-horizontal-narrow);
  margin-right: var(--emu-semantic-spacing-horizontal-narrow);
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

// main success message of the form, when the form is present inside a modal
.modal-content {
  .emu-form__success-message {
    h1,
    h2,
    h3 {
      font: var(--emu-component-ingredients-title-h4-typography-narrow);
      margin-bottom: var(--emu-semantic-spacing-vertical-narrow);

      @include mq('large') {
        font: var(--emu-component-ingredients-title-h4-typography-wide);
      }
    }
  }
}

// form success message
.emu-form__success-message {
  display: block;
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s linear;
}

// when form contains a success message
.emu-form.u-form--with-success-animation {
  > *:not(.emu-form__success-message) {
    transition: all 0.2s linear;
    max-height: 400px;
    opacity: 1;

    @include aem-editor-view {
      max-height: none;
    }
  }

  .emu-form__loader {
    max-height: 100%;
  }

  .form--show-success-msg & {
    > *:not(.emu-form__success-message) {
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s;
      opacity: 0;
    }

    .emu-form__success-message {
      max-height: 400px;
      opacity: 1;
      pointer-events: initial;
      transition: all 0.2s linear 0.2s;
    }
  }
}

// styles for a disabled form element
// from authoring use disabled option. This class must be used only via JS where elements must be disabled via JS
// also from JS the tabindex for those components needs to be set to -1
.u-form-element--disabled {
  label {
    pointer-events: none;
    color: var(
      --emu-component-ingredients-text-input-label-text-color-disabled-light
    );
  }

  .emu-form-text__input {
    background-color: var(
      --emu-component-ingredients-text-input-color-background-disabled-light
    );
    border-color: var(
      --emu-component-ingredients-text-input-color-border-disabled-light
    );
    color: var(
      --emu-component-ingredients-text-input-text-color-disabled-light
    );
    pointer-events: none;
  }

  .emu-form-textarea__textarea {
    background-color: var(
      --emu-component-ingredients-textarea-input-color-background-disabled-light
    );
    border-color: var(
      --emu-component-ingredients-textarea-input-color-border-disabled-light
    );
    color: var(
      --emu-component-ingredients-textarea-input-text-color-disabled-light
    );
    pointer-events: none;
  }

  .emu-dropdown-menu__button,
  .emu-dropdown-menu__item {
    pointer-events: none;
    background-color: var(
      --emu-component-ingredients-select-input-option-color-background-disabled-filled-light
    );
    border-color: var(
      --emu-component-ingredients-select-input-color-border-disabled-filled-light
    );
    color: var(
      --emu-component-ingredients-select-input-option-text-color-filled-disabled-light
    );
  }

  .emu-form-radio__input {
    pointer-events: none;

    background-color: var(
      --emu-component-ingredients-radio-input-control-color-background-disabled-light
    );
    border-color: var(
      --emu-component-ingredients-radio-input-control-color-border-disabled-light
    );
    pointer-events: none;

    & + span {
      color: var(
        --emu-component-ingredients-radio-input-label-text-color-disabled-light
      );
    }
  }

  .emu-form-checkbox__input {
    background-color: var(
      --emu-component-ingredients-checkbox-input-control-color-background-disabled-light
    );
    border-color: var(
      --emu-component-ingredients-checkbox-input-control-color-border-disabled-light
    );
    pointer-events: none;

    & + span {
      color: var(
        --emu-component-ingredients-checkbox-input-label-text-color-disabled-light
      );
    }
  }
}
