.logo-carousel {
  min-height: 50px;

  img {
    width: auto;
    margin: auto;
    height: auto;
    object-fit: contain;
    object-position: center;
  }
}
