// adds 450px width to the parent div
div:has(>.u-width--xs) {
  width: 450px;
  max-width: 100%;
}

// adds 575px width to the parent div
div:has(>.u-width--sm) {
  width: 575px;
  max-width: 100%;
}

// adds 700px width to the parent div
div:has(>.u-width--md) {
  width: 700px;
  max-width: 100%;
}

// adds 450px width to the parent div (by overriding any existing styles)
div:has(>.u-width--xs-strict) {
  width: 450px !important;
  max-width: 100% !important;
}