.teaser {
  .u-teaser--overlapped-content.emu-teaser {
    .content-container {
      @include mq('large') {
        padding-bottom: 120px;
      }
    }
  }

  // should be used as a combination with .u-teaser--overlapped-content class
  .u-teaser--overlapped-content-xs.emu-teaser {
    .content-container {
      @include mq('large') {
        padding-bottom: 40px;
      }
    }
  }

  // should be used as a combination with .u-teaser--overlapped-content class
  .u-teaser--overlapped-content-sm.emu-teaser {
    .content-container {
      @include mq('large') {
        padding-bottom: 80px;
      }
    }
  }

  // should be used as a combination with .u-teaser--overlapped-content class
  .u-teaser--overlapped-content-lg.emu-teaser {
    .content-container {
      @include mq('large') {
        padding-bottom: 160px;
      }
    }
  }

  // when there is not content at all
  .u-teaser--no-content.emu-teaser {
    // class needed to add more specificity
    &.cmp-teaser {
      .content-container {
        padding: var(--emu-common-spacing-none);
      }
    }
  }

  // when the content should not take eyebrow header height into consideration
  .u-teaser--content-vertically-centered-to-img.emu-teaser {
    .content-container {
      @include mq('large') {
        height: 100%; // needed to override other customizations
        top: 0; // needed to override other customizations
      }
    }
  }

  // when the image should be displayed first in mobile
  .u-teaser--img-first-in-mobile.emu-teaser {
    .teaser-container {
      flex-direction: column;
    }
  }

  // when the content has border on all four sides
  .u-teaser--content-boxed.emu-teaser.inline-text-below  {
    .teaser-image-container {
      .cmp-teaser__image img {
        @include mq('large') {
          min-height: auto; // Overriding default teaser styles
        }
      }
    }

    .content-container {
      top: 0;
      height: 100%;
      padding-top: var(--emu-semantic-spacing-vertical-narrow);

      @include mq('large') {
        padding: var(--emu-common-spacing-none);
      }

      &.text-theme-light {
        // we are giving bg-color for container in mobile only due to padding on all sides
        @include mq('1023px', 'max-width') {
          background-color: var(--emu-common-colors-light-brown-500);
        }

        .content {
          background-color: var(--emu-common-colors-light-brown-500);
        }
      }

      &.text-theme-dark {
        // we are giving bg-color for container in mobile only due to padding on all sides
        @include mq('1023px', 'max-width') {
          background-color: var(--emu-common-colors-white);
        }

        .content {
          background-color: var(--emu-common-colors-white);
        }
      }

      .content {
        max-width: 450px; // As per design

        @include mq('large') {
          border: var(--emu-semantic-border-width-thin) solid
            var(--emu-common-colors-dark-brown-700);
          border-radius: var(--emu-common-border-radius-xxs);
          padding: var(--emu-semantic-spacing-vertical-narrow);
        }

        &.content-top-left,
        &.content-middle-left,
        &.content-bottom-left {
          @include mq('large') {
            margin-left: calc(2 * var(--emu-semantic-spacing-vertical-narrow));
          }
        }

        &.content-top-right,
        &.content-middle-right,
        &.content-bottom-right {
          @include mq('large') {
            margin-right: calc(2 * var(--emu-semantic-spacing-vertical-narrow));
          }
        }
      }

      .emu-teaser {
        &__title,
        &__description {
          margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
        }

        &__description {
          @include letterSpacing('sm');
          margin-top: var(--emu-common-spacing-none);
        }

        &__action-container {
          margin: var(--emu-common-spacing-none);
        }
      }
    }
  }

  // overlay text in mobile with a white background
  .u-teaser--overlay-text-in-mobile.inline-text-below {
    .teaser-container {

      .teaser-image-container {
        .cmp-teaser__image--mobile img {
          object-fit: cover;
          min-height: 400px; //as per design
        }
      }

      .content-container {
        @include mq('1023px', 'max-width') {
          position: absolute;
          top: 0;
          padding-top: var(--emu-common-spacing-large);
          padding-bottom: var(--emu-common-spacing-large);

          .content {
            margin-top: var(--emu-common-spacing-none);
            padding-top: var(--emu-semantic-spacing-vertical-narrow);
            padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
            padding-left: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));
            padding-right: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));
            border-radius: var(--emu-common-border-radius-xxs);
            background-color: var(--emu-common-colors-white);
            opacity: 0.75; //as per design

            .emu-teaser {
              &__description {
                margin-top: calc(0.5 * var(--emu-semantic-spacing-vertical-narrow));

                p {
                  font-size: var(--emu-semantic-font-sizes-narrow-medium);
                  line-height: var(--emu-semantic-line-heights-narrow-medium);
                  @include letterSpacing('md');
                }
              }

              &__action-container {
                margin-top: var(--emu-semantic-spacing-vertical-narrow);
              }
            }
          }
        }
      }
    }
  }

  // hide disclaimer in mobile
  .u-teaser--hide-disclaimer-in-mobile.inline-text-below {
    .teaser-container {
      .emu-teaser__disclaimer {
        @include mq('1023px', 'max-width') {
          display: none;
        }

        @include aem-editor-view {
          display: block;
        }
      }
    }
  }
}
