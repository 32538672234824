@font-face {
  font-family: Beatrice;
  src: url("resources/fonts/Beatrice-Regular.woff2") format("woff2"), url("resources/fonts/Beatrice-Regular.woff") format("woff");
  font-weight: 400;
}

@font-face {
  font-family: Beatrice;
  src: url("resources/fonts/Beatrice-Light.woff2") format("woff2"), url("resources/fonts/Beatrice-Light.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: Beatrice;
  src: url("resources/fonts/Beatrice-Light.woff2") format("woff2"), url("resources/fonts/Beatrice-Light.woff") format("woff");
  font-weight: 200;
}

@font-face {
  font-family: Beatrice;
  src: url("resources/fonts/Beatrice-Light.woff2") format("woff2"), url("resources/fonts/Beatrice-Light.woff") format("woff");
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--emu-semantic-font-families-heading);
  font-weight: var(--emu-semantic-font-weight-regular);
  letter-spacing: .5px !important;
}

h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1024px) {
  h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 1024px) {
  h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

h6 {
  font: var(--emu-component-ingredients-title-h6-typography-narrow);
}

@media (min-width: 1024px) {
  h6 {
    font: var(--emu-component-ingredients-title-h6-typography-wide);
  }
}

:root {
  --emu-common-spacing-xs: 4px;
  --emu-common-spacing-xxs: 2px;
  --emu-common-spacing-small: 8px;
  --emu-common-spacing-medium: 16px;
  --emu-common-spacing-large: 32px;
  --emu-common-spacing-xl: 64px;
  --emu-common-spacing-none: 0px;
  --emu-common-spacing-unset: unset;
  --emu-common-colors-white: #fff;
  --emu-common-colors-black: #000;
  --emu-common-colors-red-50: #fff5f5;
  --emu-common-colors-red-100: #ffdcdb;
  --emu-common-colors-red-200: #ffaba8;
  --emu-common-colors-red-300: #ff7f7a;
  --emu-common-colors-red-400: #ff4e47;
  --emu-common-colors-red-500: #ff1d15;
  --emu-common-colors-red-600: #db0700;
  --emu-common-colors-red-700: #a30500;
  --emu-common-colors-red-800: #700400;
  --emu-common-colors-red-900: #380200;
  --emu-common-colors-red-950: #190100;
  --emu-common-colors-grey-100: #f4f5f5;
  --emu-common-colors-grey-200: #d4d7d8;
  --emu-common-colors-grey-300: #b4b8bb;
  --emu-common-colors-grey-400: #d3d2d2;
  --emu-common-colors-grey-500: #7e868b;
  --emu-common-colors-grey-600: #61676b;
  --emu-common-colors-grey-700: #44484b;
  --emu-common-colors-grey-800: #303436;
  --emu-common-colors-grey-900: #1d1f20;
  --emu-common-colors-grey-1000: #231f20;
  --emu-common-colors-transparent: #0000;
  --emu-common-colors-teal-50: #e0fbff;
  --emu-common-colors-teal-100: #c2f7ff;
  --emu-common-colors-teal-200: #85efff;
  --emu-common-colors-teal-300: #42e6ff;
  --emu-common-colors-teal-400: #05deff;
  --emu-common-colors-teal-500: #00abc7;
  --emu-common-colors-teal-600: #00899e;
  --emu-common-colors-teal-700: #006675;
  --emu-common-colors-teal-800: #004752;
  --emu-common-colors-teal-900: #002329;
  --emu-common-colors-teal-950: #001214;
  --emu-common-colors-beige-50: #fefcfb;
  --emu-common-colors-beige-100: #fdf9f7;
  --emu-common-colors-beige-200: #f9efeb;
  --emu-common-colors-beige-300: #f7e8e3;
  --emu-common-colors-beige-400: #f5e2db;
  --emu-common-colors-beige-500: #f2d9d0;
  --emu-common-colors-beige-600: #dfa18b;
  --emu-common-colors-beige-700: #cb6743;
  --emu-common-colors-beige-800: #8c4227;
  --emu-common-colors-beige-900: #482214;
  --emu-common-colors-beige-950: #24110a;
  --emu-common-colors-light-brown-50: #f4ebe8;
  --emu-common-colors-light-brown-100: #f5ecea;
  --emu-common-colors-light-brown-200: #f7edea;
  --emu-common-colors-light-brown-300: #e0c4bd;
  --emu-common-colors-light-brown-400: #d5aea5;
  --emu-common-colors-light-brown-500: #ca9a8e;
  --emu-common-colors-light-brown-600: #b4705f;
  --emu-common-colors-light-brown-700: #8e5243;
  --emu-common-colors-light-brown-800: #5e362c;
  --emu-common-colors-light-brown-900: #311c17;
  --emu-common-colors-light-brown-950: #180e0b;
  --emu-common-colors-medium-brown-50: #f6f0ef;
  --emu-common-colors-medium-brown-100: #ebdddb;
  --emu-common-colors-medium-brown-200: #d6bcb8;
  --emu-common-colors-medium-brown-300: #c29a94;
  --emu-common-colors-medium-brown-400: #ad7971;
  --emu-common-colors-medium-brown-500: #925c54;
  --emu-common-colors-medium-brown-600: #754a43;
  --emu-common-colors-medium-brown-700: #573732;
  --emu-common-colors-medium-brown-800: #3a2522;
  --emu-common-colors-medium-brown-900: #1d1211;
  --emu-common-colors-medium-brown-950: #100a09;
  --emu-common-colors-dark-brown-50: #f1e4e5;
  --emu-common-colors-dark-brown-100: #e3cacb;
  --emu-common-colors-dark-brown-200: #c8989a;
  --emu-common-colors-dark-brown-300: #ac6365;
  --emu-common-colors-dark-brown-400: #7e4345;
  --emu-common-colors-dark-brown-500: #492728;
  --emu-common-colors-dark-brown-600: #3c2021;
  --emu-common-colors-dark-brown-700: #91827d;
  --emu-common-colors-dark-brown-800: #1e1010;
  --emu-common-colors-dark-brown-900: #0d0707;
  --emu-common-colors-dark-brown-950: #070404;
  --emu-common-colors-light-grey-50: #fff;
  --emu-common-colors-light-grey-100: #fafafa;
  --emu-common-colors-light-grey-200: #fdfcfc;
  --emu-common-colors-light-grey-300: #fcf9f8;
  --emu-common-colors-light-grey-400: #c4c4c4;
  --emu-common-colors-light-grey-500: #faf6f5;
  --emu-common-colors-light-grey-600: #dabcb4;
  --emu-common-colors-light-grey-700: #b87f70;
  --emu-common-colors-light-grey-800: #855042;
  --emu-common-colors-light-grey-900: #221411;
  --emu-common-colors-medium-grey-50: #fff;
  --emu-common-colors-medium-grey-100: #f8f7f7;
  --emu-common-colors-medium-grey-200: #e6e5e5;
  --emu-common-colors-medium-grey-300: #d8d5d5;
  --emu-common-colors-medium-grey-400: #c9c5c5;
  --emu-common-colors-medium-grey-500: #b9b4b4;
  --emu-common-colors-medium-grey-600: #958e8e;
  --emu-common-colors-medium-grey-700: #716a6a;
  --emu-common-colors-medium-grey-800: #4c4747;
  --emu-common-colors-medium-grey-900: #252222;
  --emu-common-colors-medium-grey-950: #121111;
  --emu-common-colors-dark-grey-50: #f7f7f7;
  --emu-common-colors-dark-grey-100: #e8e8e8;
  --emu-common-colors-dark-grey-200: #c9c9ca;
  --emu-common-colors-dark-grey-300: #adadae;
  --emu-common-colors-dark-grey-400: #8e8f90;
  --emu-common-colors-dark-grey-500: #6f7071;
  --emu-common-colors-dark-grey-600: #58595a;
  --emu-common-colors-dark-grey-700: #424243;
  --emu-common-colors-dark-grey-800: #2d2e2e;
  --emu-common-colors-dark-grey-900: #171717;
  --emu-common-colors-dark-grey-950: #0a0a0a;
  --emu-common-colors-blush-50: #fff;
  --emu-common-colors-blush-100: #e9dedd;
  --emu-common-colors-blush-200: #f4ebef;
  --emu-common-colors-blush-300: #eddee4;
  --emu-common-colors-blush-400: #e6d1d9;
  --emu-common-colors-blush-500: #ddc1cc;
  --emu-common-colors-blush-600: #c08ca1;
  --emu-common-colors-blush-700: #a15876;
  --emu-common-colors-blush-800: #693a4d;
  --emu-common-colors-blush-900: #351d26;
  --emu-common-colors-blush-950: #1a0e13;
  --emu-common-colors-abbvie-blue-50: #e3ebfc;
  --emu-common-colors-abbvie-blue-100: #bed1f9;
  --emu-common-colors-abbvie-blue-200: #6a97f1;
  --emu-common-colors-abbvie-blue-300: #1b60e9;
  --emu-common-colors-abbvie-blue-400: #0f3d9a;
  --emu-common-colors-abbvie-blue-500: #071d49;
  --emu-common-colors-abbvie-blue-600: #06183c;
  --emu-common-colors-abbvie-blue-700: #04112a;
  --emu-common-colors-abbvie-blue-800: #030b1c;
  --emu-common-colors-abbvie-blue-900: #01060e;
  --emu-common-colors-abbvie-blue-950: #010409;
  --emu-common-font-families-sans: Arial, sans-serif;
  --emu-common-font-families-serif: Times New Roman, serif;
  --emu-common-font-families-mono: Courier, monospace;
  --emu-common-font-weight-bold: 700;
  --emu-common-font-weight-light: 300;
  --emu-common-font-weight-black: 900;
  --emu-common-font-weight-regular: 400;
  --emu-common-font-sizes-narrow-medium: 12px;
  --emu-common-font-sizes-narrow-large: 16.97px;
  --emu-common-font-sizes-narrow-xl: 23.99px;
  --emu-common-font-sizes-narrow-xxl: 33.93px;
  --emu-common-font-sizes-narrow-xxxl: 47.97px;
  --emu-common-font-sizes-wide-medium: 16px;
  --emu-common-font-sizes-wide-large: 22.62px;
  --emu-common-font-sizes-wide-xl: 31.99px;
  --emu-common-font-sizes-wide-xxl: 45.23px;
  --emu-common-font-sizes-wide-xxxl: 63.96px;
  --emu-common-line-heights-narrow-large: 18px;
  --emu-common-line-heights-narrow-medium: 18px;
  --emu-common-line-heights-narrow-xl: 36px;
  --emu-common-line-heights-narrow-xxl: 36px;
  --emu-common-line-heights-narrow-xxxl: 54px;
  --emu-common-line-heights-wide-large: 24px;
  --emu-common-line-heights-wide-medium: 24px;
  --emu-common-line-heights-wide-xl: 48px;
  --emu-common-line-heights-wide-xxl: 48px;
  --emu-common-line-heights-wide-xxxl: 72px;
  --emu-common-border-radius-xxs: 2px;
  --emu-common-border-radius-xs: 4px;
  --emu-common-border-radius-small: 8px;
  --emu-common-border-radius-medium: 16px;
  --emu-common-border-radius-large: 32px;
  --emu-common-border-radius-none: 0px;
  --emu-common-border-width-thin: 1px;
  --emu-common-border-width-medium: 2px;
  --emu-common-border-width-thick: 4px;
  --emu-common-border-width-none: 0px;
  --emu-common-outline-width-thin: thin;
  --emu-common-outline-width-medium: medium;
  --emu-common-outline-width-thick: thick;
  --emu-common-outline-width-unset: unset;
  --emu-common-sizing-none: 0px;
  --emu-common-sizing-xxs: 1px;
  --emu-common-sizing-xs: 8px;
  --emu-common-sizing-small: 16px;
  --emu-common-sizing-medium: 32px;
  --emu-common-sizing-large: 64px;
  --emu-common-sizing-xl: 128px;
  --emu-common-sizing-xxl: 256px;
  --emu-common-sizing-xxxl: 1120px;
  --emu-common-sizing-container: 1440px;
  --emu-common-other-time-transition-short: .2s;
  --emu-common-other-time-transition-base: .4s;
  --emu-common-other-time-transition-long: .6s;
  --emu-common-other-time-transition-xl: 1s;
  --emu-common-other-time-duration-instant: .4s;
  --emu-common-other-time-duration-short: 2s;
  --emu-common-other-time-duration-base: 4s;
  --emu-common-other-time-duration-long: 6s;
  --emu-common-other-time-delay-none: 0s;
  --emu-common-other-time-delay-short: 50ms;
  --emu-common-other-time-delay-base: .1s;
  --emu-common-other-time-delay-long: .2s;
  --emu-common-other-z-index-cookie-banner: 700;
  --emu-common-other-z-index-modal: 600;
  --emu-common-other-z-index-header: 500;
  --emu-common-other-z-index-isi: 400;
  --emu-common-other-z-index-overlay: 300;
  --emu-common-other-z-index-layer: 100;
  --emu-common-other-z-index-base: 0;
  --emu-common-other-z-index-behind: -1;
  --emu-common-box-shadow-soft-light: 0 0 6 0 var(--emu-common-colors-grey-700);
  --emu-semantic-colors-none: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-light: var(--emu-common-colors-light-brown-100);
  --emu-semantic-colors-surface-dark: var(--emu-common-colors-transparent);
  --emu-semantic-colors-surface-modal-backdrop: #0006;
  --emu-semantic-colors-actions-primary-light: var(--emu-semantic-colors-primary-light);
  --emu-semantic-colors-actions-primary-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-semantic-colors-actions-primary-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-semantic-colors-actions-primary-default-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-hover-light: var(--emu-common-colors-light-brown-600);
  --emu-semantic-colors-actions-primary-hover-dark: var(--emu-common-colors-beige-300);
  --emu-semantic-colors-actions-primary-active-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-semantic-colors-actions-primary-active-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-focus-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-semantic-colors-actions-primary-focus-dark: var(--emu-common-colors-beige-500);
  --emu-semantic-colors-actions-primary-disabled-light: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-primary-disabled-dark: var(--emu-common-colors-medium-grey-300);
  --emu-semantic-colors-actions-primary-error-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-primary-error-dark: var(--emu-common-colors-red-500);
  --emu-semantic-colors-actions-on-primary-light: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-on-primary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-actions-secondary-light: var(--emu-semantic-colors-secondary-light);
  --emu-semantic-colors-actions-secondary-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-semantic-colors-actions-secondary-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-semantic-colors-actions-secondary-default-dark: var(--emu-common-colors-blush-500);
  --emu-semantic-colors-actions-secondary-hover-light: var(--emu-common-colors-dark-brown-600);
  --emu-semantic-colors-actions-secondary-hover-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-active-light: var(--emu-common-colors-dark-brown-600);
  --emu-semantic-colors-actions-secondary-active-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-focus-light: var(--emu-common-colors-dark-brown-600);
  --emu-semantic-colors-actions-secondary-focus-dark: var(--emu-common-colors-blush-600);
  --emu-semantic-colors-actions-secondary-disabled-light: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-secondary-disabled-dark: var(--emu-common-colors-medium-grey-200);
  --emu-semantic-colors-actions-on-secondary-light: var(--emu-common-colors-white);
  --emu-semantic-colors-actions-on-secondary-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-text-light: var(--emu-common-colors-black);
  --emu-semantic-colors-text-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-text-accent: var(--emu-semantic-colors-actions-secondary-light);
  --emu-semantic-colors-foreground-light: var(--emu-common-colors-white);
  --emu-semantic-colors-foreground-dark: var(--emu-common-colors-black);
  --emu-semantic-colors-primary-light: var(--emu-common-colors-light-brown-500);
  --emu-semantic-colors-primary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-secondary-light: var(--emu-common-colors-dark-brown-500);
  --emu-semantic-colors-secondary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-tertiary-light: var(--emu-common-colors-medium-brown-100);
  --emu-semantic-colors-tertiary-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-error-light: var(--emu-common-colors-red-500);
  --emu-semantic-colors-error-dark: var(--emu-common-colors-white);
  --emu-semantic-colors-background-light: var(--emu-common-colors-white);
  --emu-semantic-colors-background-dark: var(--emu-common-colors-white);
  --emu-semantic-font-weight-bold: var(--emu-common-font-weight-bold);
  --emu-semantic-font-weight-light: var(--emu-common-font-weight-light);
  --emu-semantic-font-weight-black: var(--emu-common-font-weight-black);
  --emu-semantic-font-weight-regular: var(--emu-common-font-weight-regular);
  --emu-semantic-font-families-body: "Roboto", sans-serif;
  --emu-semantic-font-families-heading: Beatrice, sans-serif;
  --emu-semantic-font-families-mono: var(--emu-common-font-families-mono);
  --emu-semantic-spacing-none: var(--emu-common-spacing-none);
  --emu-semantic-spacing-one-line-height-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-semantic-spacing-one-line-height-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-semantic-spacing-two-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 2);
  --emu-semantic-spacing-two-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 2);
  --emu-semantic-spacing-three-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * 3);
  --emu-semantic-spacing-three-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * 3);
  --emu-semantic-spacing-rem-horizontal: var(--emu-common-spacing-none);
  --emu-semantic-spacing-horizontal-narrow: 22px;
  --emu-semantic-spacing-horizontal-wide: 44px;
  --emu-semantic-spacing-horizontal-onerem-narrow: 1rem;
  --emu-semantic-spacing-horizontal-onerem-wide: 1rem;
  --emu-semantic-spacing-horizontal-tworem-narrow: calc(var(--emu-semantic-spacing-horizontal-onerem-narrow) * 2);
  --emu-semantic-spacing-horizontal-tworem-wide: calc(var(--emu-semantic-spacing-horizontal-onerem-wide) * 2);
  --emu-semantic-spacing-rem-vertical: var(--emu-common-spacing-none);
  --emu-semantic-spacing-vertical-narrow: 22px;
  --emu-semantic-spacing-vertical-wide: 22px;
  --emu-semantic-spacing-half-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * .5);
  --emu-semantic-spacing-half-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * .5);
  --emu-semantic-spacing-quarter-line-height-wide: calc(var(--emu-semantic-spacing-one-line-height-wide) * .25);
  --emu-semantic-spacing-quarter-line-height-narrow: calc(var(--emu-semantic-spacing-one-line-height-narrow) * .25);
  --emu-semantic-font-sizes-narrow-medium: 20px;
  --emu-semantic-font-sizes-narrow-large: 24px;
  --emu-semantic-font-sizes-narrow-xl: 32px;
  --emu-semantic-font-sizes-narrow-xxl: 36px;
  --emu-semantic-font-sizes-narrow-xxxl: 40px;
  --emu-semantic-font-sizes-narrow-small: 16px;
  --emu-semantic-font-sizes-narrow-xs: 12px;
  --emu-semantic-font-sizes-wide-medium: 20px;
  --emu-semantic-font-sizes-wide-large: 26px;
  --emu-semantic-font-sizes-wide-xl: 32px;
  --emu-semantic-font-sizes-wide-xxl: 40px;
  --emu-semantic-font-sizes-wide-xxxl: 48px;
  --emu-semantic-font-sizes-wide-small: 16px;
  --emu-semantic-font-sizes-wide-xs: 12px;
  --emu-semantic-line-heights-narrow-medium: 22px;
  --emu-semantic-line-heights-narrow-large: 28px;
  --emu-semantic-line-heights-narrow-xl: 34px;
  --emu-semantic-line-heights-narrow-xxl: 42px;
  --emu-semantic-line-heights-narrow-xxxl: 48px;
  --emu-semantic-line-heights-narrow-small: 16px;
  --emu-semantic-line-heights-wide-large: 32px;
  --emu-semantic-line-heights-wide-medium: 24px;
  --emu-semantic-line-heights-wide-xl: 42px;
  --emu-semantic-line-heights-wide-xxl: 48px;
  --emu-semantic-line-heights-wide-xxxl: 56px;
  --emu-semantic-line-heights-wide-small: 18px;
  --emu-semantic-border-radius-xs: var(--emu-common-border-radius-xxs);
  --emu-semantic-border-radius-small: var(--emu-common-border-radius-small);
  --emu-semantic-border-radius-medium: var(--emu-common-border-radius-medium);
  --emu-semantic-border-radius-large: var(--emu-common-border-radius-large);
  --emu-semantic-border-radius-none: var(--emu-common-border-radius-none);
  --emu-semantic-border-width-thin: var(--emu-common-border-width-thin);
  --emu-semantic-border-width-medium: var(--emu-common-border-width-medium);
  --emu-semantic-border-width-thick: var(--emu-common-border-width-thick);
  --emu-semantic-border-width-none: 0px;
  --emu-semantic-outline-width-thin: var(--emu-common-outline-width-thin);
  --emu-semantic-outline-width-medium: var(--emu-common-outline-width-medium);
  --emu-semantic-outline-width-thick: var(--emu-common-outline-width-thick);
  --emu-semantic-outline-width-unset: unset;
  --emu-semantic-sizing-none: var(--emu-common-sizing-none);
  --emu-semantic-sizing-xxs: var(--emu-common-sizing-xxs);
  --emu-semantic-sizing-xs: var(--emu-common-sizing-xs);
  --emu-semantic-sizing-small: var(--emu-common-sizing-small);
  --emu-semantic-sizing-medium: var(--emu-common-sizing-medium);
  --emu-semantic-sizing-large: var(--emu-common-sizing-large);
  --emu-semantic-sizing-xl: var(--emu-common-sizing-xl);
  --emu-semantic-sizing-xxl: var(--emu-common-sizing-xxl);
  --emu-semantic-sizing-xxxl: var(--emu-common-sizing-xxxl);
  --emu-semantic-sizing-container: var(--emu-common-sizing-container);
  --emu-semantic-sizing-breakpoints-small: 320px;
  --emu-semantic-sizing-breakpoints-medium: 768px;
  --emu-semantic-sizing-breakpoints-large: 1024px;
  --emu-semantic-sizing-breakpoints-x-large: 1120px;
  --emu-semantic-sizing-breakpoints-desktop: 1200px;
  --emu-semantic-sizing-breakpoints-xx-large: 1440px;
  --emu-semantic-sizing-one-line-height-narrow: 20px;
  --emu-semantic-sizing-one-line-height-wide: 20px;
  --emu-semantic-sizing-two-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 2);
  --emu-semantic-sizing-two-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 2);
  --emu-semantic-sizing-three-line-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * 3);
  --emu-semantic-sizing-three-line-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * 3);
  --emu-semantic-sizing-max-width-text-narrow: 40em;
  --emu-semantic-sizing-max-width-text-wide: 55em;
  --emu-semantic-typography-narrow-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-narrow-xxl) / var(--emu-semantic-line-heights-narrow-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-xl: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-large: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-narrow-large) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-medium: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-headings-small: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-regular: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-xs: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-common-line-heights-narrow-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-small: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-narrow-small) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-large: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-xl: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xl) / var(--emu-semantic-line-heights-narrow-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-eyebrow: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-narrow-body-link: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-narrow-body-cta: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-headings-xxxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxxl) / var(--emu-semantic-line-heights-wide-xxxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xxl: var(--emu-semantic-font-weight-light) var(--emu-semantic-font-sizes-wide-xxl) / var(--emu-semantic-line-heights-wide-xxl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-xl: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xl) / var(--emu-semantic-line-heights-wide-xl) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-large: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-medium: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-headings-small: var(--emu-common-font-weight-light) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-regular: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-small) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-xs: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-small: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-large: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-medium) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-xl: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-eyebrow: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-semantic-typography-wide-body-link: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-heading);
  --emu-semantic-typography-wide-body-cta: var(--emu-common-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-medium) var(--emu-semantic-font-families-body);
  --emu-semantic-border-black: [object Object];
  --emu-semantic-border-white: [object Object];
  --emu-component-cards-card-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-card-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-cards-card-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-card-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-header-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-size-height: 30px;
  --emu-component-cards-card-header-button-icon-size-width: 30px;
  --emu-component-cards-card-header-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-cards-card-header-button-icon-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-hover-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-color-active-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-cards-card-header-button-icon-color-active-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-cards-card-header-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-cards-card-header-button-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-background-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-background-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-focus-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-focus-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-header-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-cards-card-header-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-cards-card-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-cards-card-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-cards-teaser-padding-top-narrow: var(--emu-semantic-spacing-two-line-height-narrow);
  --emu-component-cards-teaser-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-cards-teaser-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-cards-teaser-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-component-cards-teaser-title-max-width: 100%;
  --emu-component-cards-teaser-title-margin-top-narrow: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-component-cards-teaser-title-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-title-margin-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-medium);
  --emu-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-color-background-light: var(--emu-component-lists-accordion-item-body-color-background-light);
  --emu-component-lists-accordion-color-background-dark: var(--emu-component-lists-accordion-item-body-color-background-dark);
  --emu-component-lists-accordion-color-text-light: var(--emu-component-lists-accordion-item-header-text-color-light);
  --emu-component-lists-accordion-color-text-dark: var(--emu-component-lists-accordion-item-header-text-color-dark);
  --emu-component-lists-accordion-color-border-light: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-light);
  --emu-component-lists-accordion-color-border-dark: var(--emu-component-lists-accordion-item-header-color-border-bottom-open-dark);
  --emu-component-lists-accordion-margin-narrow: var(--emu-component-lists-accordion-margin-top-narrow) var(--emu-component-lists-accordion-margin-right-narrow) var(--emu-component-lists-accordion-margin-bottom-narrow) var(--emu-component-lists-accordion-margin-left-narrow);
  --emu-component-lists-accordion-margin-wide: var(--emu-component-lists-accordion-margin-top-wide) var(--emu-component-lists-accordion-margin-right-wide) var(--emu-component-lists-accordion-margin-bottom-wide) var(--emu-component-lists-accordion-margin-left-wide);
  --emu-component-lists-accordion-margin-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-margin-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-accordion-item-color-border-bottom-closed-dark: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-color-border-bottom-closed-light: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-color-border-bottom-open-dark: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-color-border-bottom-open-light: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-body-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-body-color-border-open-light: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-body-color-border-open-dark: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-body-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-body-padding-narrow: var(--emu-component-lists-accordion-item-body-padding-top-narrow) var(--emu-component-lists-accordion-item-body-padding-right-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-component-lists-accordion-item-body-padding-left-narrow);
  --emu-component-lists-accordion-item-body-padding-wide: var(--emu-component-lists-accordion-item-body-padding-top-wide) var(--emu-component-lists-accordion-item-body-padding-right-wide) var(--emu-component-lists-accordion-item-body-padding-bottom-wide) var(--emu-component-lists-accordion-item-body-padding-left-wide);
  --emu-component-lists-accordion-item-body-padding-top-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-body-padding-top-wide: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-body-padding-right-narrow: var(--emu-common-spacing-large);
  --emu-component-lists-accordion-item-body-padding-right-wide: var(--emu-common-spacing-large);
  --emu-component-lists-accordion-item-body-padding-bottom-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-body-padding-bottom-wide: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-lists-accordion-item-body-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-body-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-lists-accordion-item-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-lists-accordion-item-header-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-padding-narrow: var(--emu-component-lists-accordion-item-header-padding-top-narrow) var(--emu-component-lists-accordion-item-header-padding-right-narrow) var(--emu-component-lists-accordion-item-header-padding-bottom-narrow) var(--emu-component-lists-accordion-item-header-padding-left-narrow);
  --emu-component-lists-accordion-item-header-padding-wide: var(--emu-component-lists-accordion-item-header-padding-top-wide) var(--emu-component-lists-accordion-item-header-padding-right-wide) var(--emu-component-lists-accordion-item-header-padding-bottom-wide) var(--emu-component-lists-accordion-item-header-padding-left-wide);
  --emu-component-lists-accordion-item-header-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-lists-accordion-item-header-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-lists-accordion-item-header-padding-right-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-right-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-lists-accordion-item-header-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-component-lists-accordion-item-header-padding-left-narrow: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-padding-left-wide: var(--emu-common-spacing-none);
  --emu-component-lists-accordion-item-header-icon-margin-narrow: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-margin-wide: var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-semantic-sizing-none) var(--emu-component-lists-accordion-item-header-icon-spacing);
  --emu-component-lists-accordion-item-header-icon-spacing: var(--emu-common-spacing-small);
  --emu-component-lists-accordion-item-header-icon-thickness: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-icon-border-radius: var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small) var(--emu-semantic-border-radius-small);
  --emu-component-lists-accordion-item-header-icon-transform-open: rotate(0);
  --emu-component-lists-accordion-item-header-icon-transform-closed: rotate(180deg);
  --emu-component-lists-accordion-item-header-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-open-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-accordion-item-header-icon-color-fill-closed-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-accordion-item-header-icon-size-height-narrow: 20px;
  --emu-component-lists-accordion-item-header-icon-size-height-wide: 20px;
  --emu-component-lists-accordion-item-header-icon-size-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-icon-size-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-item-header-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-light: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-header-color-border-bottom-open-dark: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-light: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-header-color-border-bottom-closed-dark: var(--emu-common-colors-grey-400);
  --emu-component-lists-accordion-item-border-width-bottom-open: var(--emu-semantic-border-width-thin);
  --emu-component-lists-accordion-border-radius-narrow: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-radius-wide: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-lists-accordion-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-accordion-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-none);
  --emu-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-transparent);
  --emu-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-component-lists-navigation-text-padding-top-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-top-wide: 14px;
  --emu-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-lists-navigation-text-padding-bottom-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-navigation-text-padding-bottom-wide: 14px;
  --emu-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-light: var(--emu-component-ingredients-select-input-option-text-color-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-default-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-light: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-light: var(--emu-component-ingredients-select-input-option-text-color-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-active-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-light: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-filled-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-filled-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-light: var(--emu-component-ingredients-select-input-option-text-color-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-default-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-light: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-hover-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-light: var(--emu-component-ingredients-select-input-option-text-color-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-active-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-light: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-light);
  --emu-component-lists-language-navigation-dropdown-option-text-color-outline-focus-dark: var(--emu-component-ingredients-select-input-option-text-color-outline-focus-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-light: var(--emu-component-ingredients-select-input-option-color-background-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-light: var(--emu-component-ingredients-select-input-option-color-background-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-default-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-light: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-light: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-hover-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-light: var(--emu-component-ingredients-select-input-option-color-background-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-light: var(--emu-component-ingredients-select-input-option-color-background-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-active-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-light: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-filled-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-light: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-option-color-background-focus-outline-dark: var(--emu-component-ingredients-select-input-option-color-background-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-light: var(--emu-component-ingredients-select-input-color-border-default-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-filled-dark: var(--emu-component-ingredients-select-input-color-border-default-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-light: var(--emu-component-ingredients-select-input-color-border-default-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-default-outline-dark: var(--emu-component-ingredients-select-input-color-border-default-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-light: var(--emu-component-ingredients-select-input-color-border-hover-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-filled-dark: var(--emu-component-ingredients-select-input-color-border-hover-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-light: var(--emu-component-ingredients-select-input-color-border-hover-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-hover-outline-dark: var(--emu-component-ingredients-select-input-color-border-hover-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-light: var(--emu-component-ingredients-select-input-color-border-active-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-filled-dark: var(--emu-component-ingredients-select-input-color-border-active-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-light: var(--emu-component-ingredients-select-input-color-border-active-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-active-outline-dark: var(--emu-component-ingredients-select-input-color-border-active-outline-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-light: var(--emu-component-ingredients-select-input-color-border-focus-filled-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-filled-dark: var(--emu-component-ingredients-select-input-color-border-focus-filled-dark);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-light: var(--emu-component-ingredients-select-input-color-border-focus-outline-light);
  --emu-component-lists-language-navigation-dropdown-color-border-focus-outline-dark: var(--emu-component-ingredients-select-input-color-border-focus-outline-dark);
  --emu-component-lists-language-navigation-dropdown-border-radius-narrow: var(--emu-component-ingredients-select-input-border-radius-narrow);
  --emu-component-lists-language-navigation-dropdown-border-radius-wide: var(--emu-component-ingredients-select-input-border-radius-wide);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-filled-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-filled-active-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-default-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-default-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-hover-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-light: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-light);
  --emu-component-lists-language-navigation-dropdown-icon-color-fill-outline-active-dark: var(--emu-component-ingredients-select-input-icon-color-fill-outline-active-dark);
  --emu-component-lists-language-navigation-dropdown-padding-narrow: var(--emu-component-ingredients-select-input-padding-narrow);
  --emu-component-lists-language-navigation-dropdown-padding-wide: var(--emu-component-ingredients-select-input-padding-wide);
  --emu-component-lists-language-navigation-dropdown-gap-narrow: var(--emu-component-ingredients-select-input-gap-narrow);
  --emu-component-lists-language-navigation-dropdown-gap-wide: var(--emu-component-ingredients-select-input-gap-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-narrow: var(--emu-component-ingredients-select-input-border-width-filled-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-filled-wide: var(--emu-component-ingredients-select-input-border-width-filled-wide);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-narrow: var(--emu-component-ingredients-select-input-border-width-outline-narrow);
  --emu-component-lists-language-navigation-dropdown-border-width-outline-wide: var(--emu-component-ingredients-select-input-border-width-outline-wide);
  --emu-component-lists-language-navigation-pipes-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-gap-wide: var(--emu-common-spacing-small);
  --emu-component-lists-language-navigation-pipes-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-lists-language-navigation-pipes-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-lists-language-navigation-pipes-color-border-light: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-color-border-dark: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-light: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-default-dark: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-light: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-lists-language-navigation-pipes-item-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-component-feeds-instagram-post-gap-wide: 40px;
  --emu-component-ingredients-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-height-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-icon-size-width-wide: var(--emu-semantic-sizing-small);
  --emu-component-ingredients-separator-color-background-light: var(--emu-common-colors-dark-brown-700);
  --emu-component-ingredients-separator-color-background-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-separator-size-height: var(--emu-common-border-width-medium);
  --emu-component-ingredients-title-h1-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxxl);
  --emu-component-ingredients-title-h1-typography-wide: var(--emu-semantic-typography-wide-headings-xxxl);
  --emu-component-ingredients-title-h2-typography-narrow: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-ingredients-title-h2-typography-wide: var(--emu-semantic-typography-wide-headings-xxl);
  --emu-component-ingredients-title-h3-typography-narrow: var(--emu-semantic-typography-narrow-headings-xl);
  --emu-component-ingredients-title-h3-typography-wide: var(--emu-semantic-typography-wide-headings-xl);
  --emu-component-ingredients-title-h4-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-ingredients-title-h4-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-ingredients-title-h5-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-ingredients-title-h5-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-ingredients-title-h6-typography-narrow: var(--emu-semantic-typography-narrow-headings-small);
  --emu-component-ingredients-title-h6-typography-wide: var(--emu-semantic-typography-wide-headings-small);
  --emu-component-ingredients-title-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-title-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-text-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-text-text-typography-narrow: var(--emu-component-ingredients-text-typography-narrow);
  --emu-component-ingredients-text-text-typography-wide: var(--emu-component-ingredients-text-typography-wide);
  --emu-component-ingredients-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-typography-narrow: var(--emu-semantic-typography-narrow-body-regular);
  --emu-component-ingredients-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-ingredients-loader-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-loader-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-image-map-buttons-size-height: 27px;
  --emu-component-ingredients-image-map-buttons-size-width: 27px;
  --emu-component-ingredients-image-map-buttons-color-background-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-image-map-buttons-color-background-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-image-map-buttons-color-icon-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-ingredients-image-map-buttons-color-icon-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-ingredients-image-map-buttons-border-radius-top-left: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-top-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-right: 50%;
  --emu-component-ingredients-image-map-buttons-border-radius-bottom-left: 50%;
  --emu-component-ingredients-image-map-buttons-shadow: 0 0 2 5 #0009;
  --emu-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-ingredients-image-caption-padding-narrow: calc(var(--emu-semantic-spacing-vertical-narrow) * .5);
  --emu-component-ingredients-label-margin-narrow: var(--emu-common-spacing-xs);
  --emu-component-ingredients-label-margin-wide: var(--emu-common-spacing-xs);
  --emu-component-ingredients-label-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-gap-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-ingredients-label-text-color-default-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-label-text-color-default-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-label-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-label-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-label-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-label-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-legend-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-legend-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-legend-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-field-message-margin-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-margin-wide: var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-field-message-text-color-light: var(--emu-component-ingredients-text-color-light);
  --emu-component-ingredients-field-message-text-color-dark: var(--emu-component-ingredients-text-color-dark);
  --emu-component-ingredients-error-message-margin-narrow: var(--emu-common-spacing-xs) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-margin-wide: var(--emu-common-spacing-xs) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none);
  --emu-component-ingredients-error-message-text-color-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-error-message-text-color-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-active-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-select-input-option-text-color-outline-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-option-text-color-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-option-text-color-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-text-color-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-option-color-background-default-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-default-filled-dark: var(--emu-common-colors-black);
  --emu-component-ingredients-select-input-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-light: var(--emu-common-colors-light-brown-100);
  --emu-component-ingredients-select-input-option-color-background-hover-filled-dark: var(--emu-common-colors-light-brown-100);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-light: var(--emu-common-colors-light-grey-100);
  --emu-component-ingredients-select-input-option-color-background-hover-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-active-filled-light: var(--emu-common-colors-light-brown-100);
  --emu-component-ingredients-select-input-option-color-background-active-filled-dark: var(--emu-common-colors-light-brown-100);
  --emu-component-ingredients-select-input-option-color-background-active-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-active-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-focus-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-light: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-disabled-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-ingredients-select-input-option-color-background-error-filled-light: var(--emu-common-colors-white);
  --emu-component-ingredients-select-input-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-default-outline-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-hover-outline-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-active-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-active-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-active-outline-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-ingredients-select-input-color-border-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-focus-outline-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-color-border-disabled-filled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-color-border-disabled-outline-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-color-border-error-filled-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-filled-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-color-border-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-color-border-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-border-radius-narrow: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-select-input-border-radius-wide: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-default-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-hover-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-active-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-focus-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-default-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-hover-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-active-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-light: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-focus-dark: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-select-input-icon-color-fill-outline-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-select-input-padding-narrow: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-padding-wide: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-select-input-gap-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-ingredients-select-input-gap-wide: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-ingredients-select-input-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-ingredients-select-input-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-select-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-select-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-select-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-select-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-select-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-select-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-select-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-select-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-select-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-select-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-select-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-select-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-select-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-select-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-select-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-select-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-select-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-text-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-empty-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-empty-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-text-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-text-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-text-padding-narrow: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-text-input-text-padding-wide: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-text-input-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-ingredients-text-input-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-border-empty-light: var(--emu-common-colors-black);
  --emu-component-ingredients-text-input-color-border-empty-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-border-focus-light: var(--emu-common-colors-black);
  --emu-component-ingredients-text-input-color-border-focus-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-text-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-text-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-text-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-text-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-text-input-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-empty-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-empty-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-focus-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-error-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-color-background-error-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-text-input-border-radius-narrow: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-text-input-border-radius-wide: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-text-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-text-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-text-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-text-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-text-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-text-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-text-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-text-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-text-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-text-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-text-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-text-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-text-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-text-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-text-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-text-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-text-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-text-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-radio-input-control-size-all-narrow: 20px;
  --emu-component-ingredients-radio-input-control-size-all-wide: 20px;
  --emu-component-ingredients-radio-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-radio-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-radio-input-control-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-radio-input-control-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-radio-input-control-color-background-focus-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-focus-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-checked-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-background-checked-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-radio-input-control-color-background-disabled-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-disabled-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-error-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-error-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-active-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-active-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-background-hover-light: var(--emu-component-ingredients-radio-input-control-color-background-default-light);
  --emu-component-ingredients-radio-input-control-color-background-hover-dark: var(--emu-component-ingredients-radio-input-control-color-background-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-radio-input-control-color-border-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-radio-input-control-color-border-focus-light: var(--emu-component-ingredients-radio-input-control-color-border-default-light);
  --emu-component-ingredients-radio-input-control-color-border-focus-dark: var(--emu-component-ingredients-radio-input-control-color-border-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-checked-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-radio-input-control-color-border-checked-dark: var(--emu-component-ingredients-radio-input-control-color-border-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-radio-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-radio-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-control-color-border-active-light: var(--emu-component-ingredients-radio-input-control-color-border-default-light);
  --emu-component-ingredients-radio-input-control-color-border-active-dark: var(--emu-component-ingredients-radio-input-control-color-border-default-dark);
  --emu-component-ingredients-radio-input-control-color-border-hover-light: var(--emu-component-ingredients-radio-input-control-color-border-default-light);
  --emu-component-ingredients-radio-input-control-color-border-hover-dark: var(--emu-component-ingredients-radio-input-control-color-border-default-dark);
  --emu-component-ingredients-radio-input-control-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-control-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-light: var(--emu-common-colors-black);
  --emu-component-ingredients-radio-input-icon-color-fill-checked-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-light: var(--emu-common-colors-black);
  --emu-component-ingredients-radio-input-icon-color-fill-disabled-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-radio-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-radio-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-radio-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-radio-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-radio-input-label-margin-narrow: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-radio-input-label-margin-wide: var(--emu-semantic-spacing-none);
  --emu-component-ingredients-radio-input-label-gap-narrow: var(--emu-component-ingredients-label-gap-narrow);
  --emu-component-ingredients-radio-input-label-gap-wide: var(--emu-component-ingredients-label-gap-wide);
  --emu-component-ingredients-radio-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-radio-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-radio-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-radio-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-radio-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-radio-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-radio-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-radio-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-radio-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-radio-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-radio-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-radio-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-radio-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-radio-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-radio-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-radio-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-radio-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-radio-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-radio-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-radio-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-radio-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-radio-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-control-size-all-narrow: 20px;
  --emu-component-ingredients-checkbox-input-control-size-all-wide: 20px;
  --emu-component-ingredients-checkbox-input-control-border-radius-narrow: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-border-radius-wide: var(--emu-common-border-radius-xxs);
  --emu-component-ingredients-checkbox-input-control-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-checkbox-input-control-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-checkbox-input-control-color-background-checked-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-disabled-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-error-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-error-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-background-active-light: var(--emu-component-ingredients-checkbox-input-control-color-background-default-light);
  --emu-component-ingredients-checkbox-input-control-color-background-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-background-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-checkbox-input-control-color-border-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-focus-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-light: var(--emu-common-colors-light-brown-500);
  --emu-component-ingredients-checkbox-input-control-color-border-checked-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-control-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-control-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-active-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-active-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-light: var(--emu-component-ingredients-checkbox-input-control-color-border-default-light);
  --emu-component-ingredients-checkbox-input-control-color-border-hover-dark: var(--emu-component-ingredients-checkbox-input-control-color-border-default-dark);
  --emu-component-ingredients-checkbox-input-control-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-control-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-checked-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-checkbox-input-icon-color-fill-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-checkbox-input-icon-size-all-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow)  - 4px);
  --emu-component-ingredients-checkbox-input-icon-size-all-wide: calc(var(--emu-semantic-sizing-one-line-height-wide)  - 4px);
  --emu-component-ingredients-checkbox-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-checkbox-input-label-gap-narrow: var(--emu-common-spacing-medium);
  --emu-component-ingredients-checkbox-input-label-gap-wide: var(--emu-common-spacing-medium);
  --emu-component-ingredients-checkbox-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-checkbox-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-checkbox-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-light: var(--emu-component-ingredients-label-text-color-checked-light);
  --emu-component-ingredients-checkbox-input-label-text-color-checked-dark: var(--emu-component-ingredients-label-text-color-checked-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-checkbox-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-checkbox-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-checkbox-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-checkbox-input-legend-margin-narrow: var(--emu-component-ingredients-legend-margin-narrow);
  --emu-component-ingredients-checkbox-input-legend-margin-wide: var(--emu-component-ingredients-legend-margin-wide);
  --emu-component-ingredients-checkbox-input-legend-text-color-light: var(--emu-component-ingredients-legend-text-color-light);
  --emu-component-ingredients-checkbox-input-legend-text-color-dark: var(--emu-component-ingredients-legend-text-color-dark);
  --emu-component-ingredients-checkbox-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-checkbox-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-checkbox-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-checkbox-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-checkbox-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-checkbox-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-checkbox-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-ingredients-textarea-input-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-empty-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-empty-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-ingredients-textarea-input-text-color-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-ingredients-textarea-input-text-color-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-text-color-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-text-color-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-text-color-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-text-padding-narrow: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-textarea-input-text-padding-wide: var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium) var(--emu-common-spacing-medium);
  --emu-component-ingredients-textarea-input-color-border-default-light: var(--emu-common-colors-black);
  --emu-component-ingredients-textarea-input-color-border-default-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-textarea-input-color-border-empty-light: var(--emu-common-colors-black);
  --emu-component-ingredients-textarea-input-color-border-empty-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-textarea-input-color-border-focus-light: var(--emu-common-colors-black);
  --emu-component-ingredients-textarea-input-color-border-focus-dark: var(--emu-common-colors-white);
  --emu-component-ingredients-textarea-input-color-border-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-ingredients-textarea-input-color-border-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-ingredients-textarea-input-color-border-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-ingredients-textarea-input-color-border-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-ingredients-textarea-input-color-background-default-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-default-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-empty-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-empty-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-focus-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-error-light: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-color-background-error-dark: var(--emu-semantic-colors-none);
  --emu-component-ingredients-textarea-input-border-radius-narrow: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-textarea-input-border-radius-wide: var(--emu-semantic-border-radius-xs);
  --emu-component-ingredients-textarea-input-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-ingredients-textarea-input-label-margin-narrow: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-margin-wide: var(--emu-component-ingredients-label-margin-narrow);
  --emu-component-ingredients-textarea-input-label-text-color-default-light: var(--emu-component-ingredients-label-text-color-default-light);
  --emu-component-ingredients-textarea-input-label-text-color-default-dark: var(--emu-component-ingredients-label-text-color-default-dark);
  --emu-component-ingredients-textarea-input-label-text-color-hover-light: var(--emu-component-ingredients-label-text-color-hover-light);
  --emu-component-ingredients-textarea-input-label-text-color-hover-dark: var(--emu-component-ingredients-label-text-color-hover-dark);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-light: var(--emu-component-ingredients-label-text-color-disabled-light);
  --emu-component-ingredients-textarea-input-label-text-color-disabled-dark: var(--emu-component-ingredients-label-text-color-disabled-dark);
  --emu-component-ingredients-textarea-input-label-text-color-error-light: var(--emu-component-ingredients-label-text-color-error-light);
  --emu-component-ingredients-textarea-input-label-text-color-error-dark: var(--emu-component-ingredients-label-text-color-error-dark);
  --emu-component-ingredients-textarea-input-field-message-margin-narrow: var(--emu-component-ingredients-field-message-margin-narrow);
  --emu-component-ingredients-textarea-input-field-message-margin-wide: var(--emu-component-ingredients-field-message-margin-wide);
  --emu-component-ingredients-textarea-input-field-message-text-color-light: var(--emu-component-ingredients-field-message-text-color-light);
  --emu-component-ingredients-textarea-input-field-message-text-color-dark: var(--emu-component-ingredients-field-message-text-color-dark);
  --emu-component-ingredients-textarea-input-error-message-margin-narrow: var(--emu-component-ingredients-error-message-margin-narrow);
  --emu-component-ingredients-textarea-input-error-message-margin-wide: var(--emu-component-ingredients-error-message-margin-wide);
  --emu-component-ingredients-textarea-input-error-message-text-color-light: var(--emu-component-ingredients-error-message-text-color-light);
  --emu-component-ingredients-textarea-input-error-message-text-color-dark: var(--emu-component-ingredients-error-message-text-color-dark);
  --emu-component-actions-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-xs) / var(--emu-semantic-line-heights-narrow-small) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-xs) / var(--emu-semantic-line-heights-wide-small) var(--emu-semantic-font-families-body);
  --emu-component-actions-button-text-color-link-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-hover-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-hover-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-button-text-color-link-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-button-text-color-link-active-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-active-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-link-focus-light: var(--emu-component-actions-button-text-color-link-default-light);
  --emu-component-actions-button-text-color-link-focus-dark: var(--emu-component-actions-button-text-color-link-default-dark);
  --emu-component-actions-button-text-color-primary-filled-light: var(--emu-component-actions-button-text-color-primary-filled-default-light);
  --emu-component-actions-button-text-color-primary-filled-dark: var(--emu-component-actions-button-text-color-primary-filled-default-dark);
  --emu-component-actions-button-text-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-light: var(--emu-component-actions-button-text-color-primary-outline-default-light);
  --emu-component-actions-button-text-color-primary-outline-dark: var(--emu-component-actions-button-text-color-primary-outline-default-dark);
  --emu-component-actions-button-text-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-text-color-primary-outline-default-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-primary-outline-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-text-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-text-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-text-color-secondary-filled-light: var(--emu-component-actions-button-text-color-secondary-filled-default-light);
  --emu-component-actions-button-text-color-secondary-filled-dark: var(--emu-component-actions-button-text-color-secondary-filled-default-dark);
  --emu-component-actions-button-text-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-text-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-text-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-light: var(--emu-component-actions-button-text-color-secondary-outline-default-light);
  --emu-component-actions-button-text-color-secondary-outline-dark: var(--emu-component-actions-button-text-color-secondary-outline-default-dark);
  --emu-component-actions-button-text-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-text-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-text-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-text-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-border-width-primary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-primary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-filled: var(--emu-common-border-width-thin);
  --emu-component-actions-button-border-width-secondary-outline: var(--emu-common-border-width-thin);
  --emu-component-actions-button-outline-width-primary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-primary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-filled: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-outline-width-secondary-outline: var(--emu-semantic-outline-width-unset);
  --emu-component-actions-button-color-border-primary-filled-light: var(--emu-component-actions-button-color-border-primary-filled-default-light);
  --emu-component-actions-button-color-border-primary-filled-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-border-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-border-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-primary-outline-light: var(--emu-component-actions-button-color-border-primary-outline-default-light);
  --emu-component-actions-button-color-border-primary-outline-dark: var(--emu-component-actions-button-color-border-primary-filled-default-dark);
  --emu-component-actions-button-color-border-primary-outline-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-border-primary-outline-default-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-default-dark: var(--emu-common-colors-white);
  --emu-component-actions-button-color-border-primary-outline-disabled-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-border-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-filled-light: var(--emu-component-actions-button-color-border-secondary-filled-default-light);
  --emu-component-actions-button-color-border-secondary-filled-dark: var(--emu-component-actions-button-color-border-secondary-filled-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-border-secondary-outline-light: var(--emu-component-actions-button-color-border-secondary-outline-default-light);
  --emu-component-actions-button-color-border-secondary-outline-dark: var(--emu-component-actions-button-color-border-secondary-outline-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-focus-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-active-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-active-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-border-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-border-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-border-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-primary-filled-light: var(--emu-component-actions-button-color-background-primary-filled-default-light);
  --emu-component-actions-button-color-background-primary-filled-dark: var(--emu-component-actions-button-color-background-primary-filled-default-dark);
  --emu-component-actions-button-color-background-primary-filled-focus-light: var(--emu-semantic-colors-actions-primary-focus-light);
  --emu-component-actions-button-color-background-primary-filled-focus-dark: var(--emu-semantic-colors-actions-primary-focus-dark);
  --emu-component-actions-button-color-background-primary-filled-hover-light: var(--emu-semantic-colors-actions-primary-hover-light);
  --emu-component-actions-button-color-background-primary-filled-hover-dark: var(--emu-semantic-colors-actions-primary-hover-dark);
  --emu-component-actions-button-color-background-primary-filled-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-button-color-background-primary-filled-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-button-color-background-primary-filled-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-component-actions-button-color-background-primary-filled-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-color-background-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-color-background-primary-outline-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-dark: var(--emu-component-actions-button-color-background-primary-outline-default-dark);
  --emu-component-actions-button-color-background-primary-outline-focus-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-focus-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-hover-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-background-primary-outline-hover-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-active-light: var(--emu-component-actions-button-color-background-primary-outline-default-light);
  --emu-component-actions-button-color-background-primary-outline-active-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-component-actions-button-color-background-primary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-primary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-filled-light: var(--emu-component-actions-button-color-background-secondary-filled-default-light);
  --emu-component-actions-button-color-background-secondary-filled-dark: var(--emu-component-actions-button-color-background-secondary-filled-disabled-dark);
  --emu-component-actions-button-color-background-secondary-filled-focus-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-background-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-secondary-focus-dark);
  --emu-component-actions-button-color-background-secondary-filled-hover-light: var(--emu-common-colors-white);
  --emu-component-actions-button-color-background-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-secondary-hover-dark);
  --emu-component-actions-button-color-background-secondary-filled-active-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-background-secondary-filled-active-dark: var(--emu-semantic-colors-actions-secondary-active-dark);
  --emu-component-actions-button-color-background-secondary-filled-default-light: var(--emu-common-colors-black);
  --emu-component-actions-button-color-background-secondary-filled-default-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-color-background-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-color-background-secondary-outline-light: var(--emu-component-actions-button-color-background-secondary-outline-default-light);
  --emu-component-actions-button-color-background-secondary-outline-dark: var(--emu-component-actions-button-color-background-secondary-outline-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-focus-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-hover-light: var(--emu-semantic-colors-actions-secondary-default-light);
  --emu-component-actions-button-color-background-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-secondary-default-dark);
  --emu-component-actions-button-color-background-secondary-outline-active-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-active-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-default-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-light: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-background-secondary-outline-disabled-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-button-color-outline-color-primary-filled-light: var(--emu-component-actions-button-color-outline-color-primary-filled-default-light);
  --emu-component-actions-button-color-outline-color-primary-filled-dark: var(--emu-component-actions-button-color-outline-color-primary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-light: var(--emu-component-actions-button-color-outline-color-primary-outline-default-light);
  --emu-component-actions-button-color-outline-color-primary-outline-dark: var(--emu-component-actions-button-color-outline-color-primary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-primary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-light: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-light);
  --emu-component-actions-button-color-outline-color-secondary-filled-dark: var(--emu-component-actions-button-color-outline-color-secondary-filled-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-light: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-light);
  --emu-component-actions-button-color-outline-color-secondary-outline-dark: var(--emu-component-actions-button-color-outline-color-secondary-outline-default-dark);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-active-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-default-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-light: var(--emu-semantic-colors-none);
  --emu-component-actions-button-color-outline-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-none);
  --emu-component-actions-button-group-horizontal-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-horizontal-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-horizontal-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-gap: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-button-group-vertical-padding-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-group-vertical-padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-actions-button-icon-spacing: var(--emu-common-spacing-xs);
  --emu-component-actions-button-icon-thickness: var(--emu-semantic-border-width-medium);
  --emu-component-actions-button-icon-transform-open: rotate(0);
  --emu-component-actions-button-icon-transform-closed: rotate(180deg);
  --emu-component-actions-button-icon-transition: var(--emu-common-other-time-duration-instant);
  --emu-component-actions-button-icon-color-primary-filled-light: var(--emu-component-actions-button-icon-color-primary-filled-default-light);
  --emu-component-actions-button-icon-color-primary-filled-dark: var(--emu-component-actions-button-icon-color-primary-filled-default-dark);
  --emu-component-actions-button-icon-color-primary-filled-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-default-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-default-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-filled-disabled-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-light: var(--emu-component-actions-button-icon-color-primary-outline-default-light);
  --emu-component-actions-button-icon-color-primary-outline-dark: var(--emu-component-actions-button-icon-color-primary-outline-default-dark);
  --emu-component-actions-button-icon-color-primary-outline-focus-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-focus-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-default-light: var(--emu-semantic-colors-actions-primary-light);
  --emu-component-actions-button-icon-color-primary-outline-default-dark: var(--emu-semantic-colors-actions-primary-dark);
  --emu-component-actions-button-icon-color-primary-outline-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-button-icon-color-primary-outline-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-filled-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-default-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-default-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-filled-disabled-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-filled-light: var(--emu-component-actions-button-icon-color-secondary-filled-default-light);
  --emu-component-actions-button-icon-color-secondary-filled-dark: var(--emu-component-actions-button-icon-color-secondary-filled-default-dark);
  --emu-component-actions-button-icon-color-secondary-outline-hover-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-hover-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-focus-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-focus-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-active-light: var(--emu-semantic-colors-actions-on-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-active-dark: var(--emu-semantic-colors-actions-on-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-default-light: var(--emu-semantic-colors-actions-secondary-light);
  --emu-component-actions-button-icon-color-secondary-outline-default-dark: var(--emu-semantic-colors-actions-secondary-dark);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-light: var(--emu-semantic-colors-actions-secondary-disabled-light);
  --emu-component-actions-button-icon-color-secondary-outline-disabled-dark: var(--emu-semantic-colors-actions-secondary-disabled-dark);
  --emu-component-actions-button-icon-color-secondary-outline-light: var(--emu-component-actions-button-icon-color-secondary-outline-default-light);
  --emu-component-actions-button-icon-color-secondary-outline-dark: var(--emu-component-actions-button-icon-color-secondary-outline-default-dark);
  --emu-component-actions-button-icon-size-height-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-height-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-size-width-narrow: calc(var(--emu-semantic-sizing-one-line-height-narrow) * .75);
  --emu-component-actions-button-icon-size-width-wide: calc(var(--emu-semantic-sizing-one-line-height-wide) * .75);
  --emu-component-actions-button-icon-border-radius: var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none) var(--emu-semantic-border-radius-none);
  --emu-component-actions-button-padding-top-narrow: 11px;
  --emu-component-actions-button-padding-top-wide: 11px;
  --emu-component-actions-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-button-padding-bottom-narrow: 11px;
  --emu-component-actions-button-padding-bottom-wide: 11px;
  --emu-component-actions-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-onerem-narrow);
  --emu-component-actions-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-onerem-wide);
  --emu-component-actions-button-border-radius-primary-filled: var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs);
  --emu-component-actions-button-border-radius-primary-outline: var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs);
  --emu-component-actions-button-border-radius-secondary-filled: var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs);
  --emu-component-actions-button-border-radius-secondary-outline: var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs) var(--emu-semantic-border-radius-xs);
  --emu-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-button-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-button-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-button-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-button-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-button-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-light: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-active-dark: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-light: var(--emu-semantic-colors-text-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-focus-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-light: var(--emu-semantic-colors-actions-primary-disabled-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-disabled-dark: var(--emu-semantic-colors-actions-primary-disabled-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-text-color-filled-error-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-light: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-default-outline-dark: var(--emu-common-colors-transparent);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-hover-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-filled-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-active-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-focus-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-disabled-outline-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-light: var(--emu-semantic-colors-surface-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-filled-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-light: var(--emu-semantic-colors-actions-primary-error-light);
  --emu-component-actions-add-to-calendar-dropdown-option-color-background-error-outline-dark: var(--emu-semantic-colors-actions-primary-error-dark);
  --emu-component-actions-add-to-calendar-dropdown-option-width: 165px;
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-narrow: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-filled-wide: var(--emu-common-border-width-none);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-narrow: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-width-outline-wide: var(--emu-common-border-width-thin);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-component-actions-add-to-calendar-dropdown-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-actions-add-to-calendar-dropdown-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-actions-add-to-calendar-dropdown-icon-color-fill-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-height-wide: 24px;
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-narrow: var(--emu-semantic-sizing-small);
  --emu-component-actions-add-to-calendar-dropdown-icon-size-width-wide: 24px;
  --emu-component-actions-pagination-icon-narrow: 24px;
  --emu-component-actions-pagination-icon-wide: 24px;
  --emu-component-actions-pagination-text-color-hover-light: var(--emu-common-colors-grey-1000);
  --emu-component-actions-pagination-text-color-hover-dark: var(--emu-common-colors-grey-100);
  --emu-component-actions-pagination-text-color-default-light: var(--emu-common-colors-grey-1000);
  --emu-component-actions-pagination-text-color-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-actions-pagination-text-color-active-light: var(--emu-common-colors-grey-1000);
  --emu-component-actions-pagination-text-color-active-dark: var(--emu-common-colors-grey-100);
  --emu-component-actions-pagination-text-color-focus-light: var(--emu-common-colors-grey-1000);
  --emu-component-actions-pagination-text-color-focus-dark: var(--emu-common-colors-grey-100);
  --emu-component-actions-pagination-text-typography-narrow: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-actions-pagination-text-typography-wide: var(--emu-semantic-typography-wide-body-regular);
  --emu-component-actions-pagination-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-actions-pagination-gap-wide: var(--emu-common-spacing-small);
  --emu-component-actions-pagination-margin-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-common-spacing-none) var(--emu-semantic-spacing-vertical-narrow) var(--emu-common-spacing-none);
  --emu-component-actions-pagination-margin-wide: var(--emu-semantic-spacing-vertical-narrow) var(--emu-common-spacing-none) var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-spacing-none);
  --emu-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-comparison-slider-divider-color-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-common-spacing-small);
  --emu-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-common-spacing-small);
  --emu-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-common-colors-beige-200);
  --emu-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-common-colors-light-brown-500);
  --emu-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-common-colors-beige-200);
  --emu-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-common-colors-light-brown-500);
  --emu-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-common-colors-dark-brown-500);
  --emu-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-common-colors-white);
  --emu-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-component-containers-tabs-content-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-tabs-content-color-background-light: var(--emu-semantic-colors-none);
  --emu-component-containers-tabs-content-color-background-dark: var(--emu-semantic-colors-none);
  --emu-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-component-containers-isi-open-content-size: 30vh;
  --emu-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-component-containers-container-max-width: 100%;
  --emu-component-containers-container-padding-top-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-top-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-right-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-right-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-bottom-narrow: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-bottom-wide: var(--emu-semantic-spacing-rem-vertical);
  --emu-component-containers-container-padding-left-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-left-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-padding-narrow: var(--emu-component-containers-container-padding-top-narrow) var(--emu-component-containers-container-padding-right-narrow) var(--emu-component-containers-container-padding-bottom-narrow) var(--emu-component-containers-container-padding-left-narrow);
  --emu-component-containers-container-padding-wide: var(--emu-component-containers-container-padding-top-wide) var(--emu-component-containers-container-padding-right-wide) var(--emu-component-containers-container-padding-bottom-wide) var(--emu-component-containers-container-padding-left-wide);
  --emu-component-containers-container-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-container-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-container-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-none);
  --emu-component-containers-carousel-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-gap-horizontal-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-horizontal-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-rem-horizontal);
  --emu-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-common-spacing-small);
  --emu-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-semantic-colors-primary-light);
  --emu-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-common-colors-beige-600);
  --emu-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-secondary-light);
  --emu-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-common-colors-beige-800);
  --emu-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-primary-light);
  --emu-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-common-colors-beige-600);
  --emu-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-secondary-light);
  --emu-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-common-colors-beige-800);
  --emu-component-containers-carousel-indicators-dots-border-width-all: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-common-border-width-thin);
  --emu-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-common-spacing-small);
  --emu-component-containers-carousel-indicators-dots-gap-wide: var(--emu-common-spacing-small);
  --emu-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-common-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-common-spacing-none);
  --emu-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-horizontal-narrow) var(--emu-common-spacing-none) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-common-spacing-none);
  --emu-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-light);
  --emu-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark);
  --emu-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-light);
  --emu-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark);
  --emu-component-containers-carousel-indicators-image-border-width-all: var(--emu-component-containers-carousel-indicators-thumbnail-border-width-narrow);
  --emu-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-primary-light);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-common-border-width-thick);
  --emu-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-component-containers-carousel-controls-padding-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-border-width-all: var(--emu-common-border-width-none);
  --emu-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-component-containers-carousel-controls-icon-size-narrow: 14px;
  --emu-component-containers-carousel-controls-icon-size-wide: 14px;
  --emu-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-carousel-frame-color-background-default-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-frame-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-containers-carousel-frame-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-thin);
  --emu-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-thin);
  --emu-component-containers-embed-gap-horizontal-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-horizontal-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-narrow: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-gap-vertical-wide: var(--emu-semantic-spacing-none);
  --emu-component-containers-embed-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-component-containers-embed-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-component-containers-embed-player-vimeo-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-vimeo-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-vimeo-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-vimeo-color-background-light: #000;
  --emu-component-containers-embed-player-vimeo-color-background-dark: #000;
  --emu-component-containers-embed-player-vimeo-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-vimeo-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-vimeo-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-vimeo-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-vimeo-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-vimeo-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-vimeo-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-vimeo-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-vimeo-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-vimeo-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-vimeo-controls-global-padding: 7px;
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-vimeo-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-vimeo-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-vimeo-tooltip-padding: 5px;
  --emu-component-containers-embed-player-vimeo-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-vimeo-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one: 0 0 0 1.5px var(--emu-common-colors-black);
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-vimeo-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-vimeo-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-vimeo-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-vimeo-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-vimeo-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-vimeo-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-vimeo-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-global-color-light: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-color-dark: #00b3ff;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-base: 15px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-small: 13px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-large: 18px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-size-xlarge: 21px;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-regular: 400;
  --emu-component-containers-embed-player-youtube-global-text-typography-font-weight-bold: 600;
  --emu-component-containers-embed-player-youtube-global-text-typography-line-height: 1.7;
  --emu-component-containers-embed-player-youtube-color-background-light: #000;
  --emu-component-containers-embed-player-youtube-color-background-dark: #000;
  --emu-component-containers-embed-player-youtube-outline-color-focus-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-outline-color-focus-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-badge-border-radius-all: 2px;
  --emu-component-containers-embed-player-youtube-badge-color-background-light: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-color-background-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-badge-text-typography-font-size: 9px;
  --emu-component-containers-embed-player-youtube-badge-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-badge-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-menu-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-menu-color-border-light: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-color-border-dark: #dcdfe5;
  --emu-component-containers-embed-player-youtube-menu-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-light: #728197;
  --emu-component-containers-embed-player-youtube-menu-item-arrow-color-dark: #728197;
  --emu-component-containers-embed-player-youtube-menu-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-embed-player-youtube-menu-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-menu-border-radius-all: 4px;
  --emu-component-containers-embed-player-youtube-menu-arrow-size: 6px;
  --emu-component-containers-embed-player-youtube-controls-global-spacing: 10px;
  --emu-component-containers-embed-player-youtube-controls-global-icon-sizing: 18px;
  --emu-component-containers-embed-player-youtube-controls-global-padding: 7px;
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-global-toggle-color-background-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-global-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-video-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-video-control-icon-fill-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-start: #0000;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-gradient-end: #000000bf;
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-controls-audio-color-background-hover-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-light: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-hover-dark: #fff;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-light: #4a5464;
  --emu-component-containers-embed-player-youtube-controls-audio-control-icon-fill-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-light: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-color-background-dark: #ffffffe6;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-light: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-text-color-dark: #4a5464;
  --emu-component-containers-embed-player-youtube-tooltip-shadow: 0 1 2 0 #00000026;
  --emu-component-containers-embed-player-youtube-tooltip-border-radius-all: 3px;
  --emu-component-containers-embed-player-youtube-tooltip-padding: 5px;
  --emu-component-containers-embed-player-youtube-tooltip-arrow-size: 4px;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-light: var(--emu-component-containers-embed-player-vimeo-global-color-light);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-active-dark: var(--emu-component-containers-embed-player-vimeo-global-color-dark);
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-light: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-color-background-loading-dark: #23282f99;
  --emu-component-containers-embed-player-youtube-scrubber-sizing-height: 5px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-sizing-height: 13px;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-light: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-color-background-dark: #fff;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-one: 0 1 1 0 #d71a1226;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-two: 0 0 0 1 #d71a1233;
  --emu-component-containers-embed-player-youtube-scrubber-thumb-shadow-width-active: 3px;
  --emu-component-containers-embed-player-youtube-scrubber-loading-size: 25px;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-light: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-progress-color-background-buffered-dark: #c1c8d199;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-light: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-thumb-shadow-active-dark: #d71a121a;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-audio-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-progress-color-background-buffered-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-light: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-thumb-shadow-active-dark: #ffffff80;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-light: #ffffff40;
  --emu-component-containers-embed-player-youtube-scrubber-video-track-color-background-dark: #ffffff40;
  --emu-component-containers-embed-player-youtube-captions-text-color-light: #fff;
  --emu-component-containers-embed-player-youtube-captions-text-color-dark: #fff;
  --emu-component-containers-embed-player-youtube-captions-color-background-light: #000c;
  --emu-component-containers-embed-player-youtube-captions-color-background-dark: #000c;
  --emu-component-containers-embed-player-youtube-time-text-typography-font-size: var(--emu-component-containers-embed-player-vimeo-global-text-typography-font-size-small);
  --emu-component-containers-gallery-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-padding-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-right-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-separator-content-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-separator-content-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-separator-content-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-sizing-width-narrow: 100%;
  --emu-component-containers-gallery-item-sizing-width-wide: calc(33.33% - 8px);
  --emu-component-containers-gallery-item-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-item-border-width: var(--emu-semantic-border-width-thin);
  --emu-component-containers-gallery-item-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-active-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-active-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-text-color-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-text-color-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-background-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-background-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-item-hover-color-border-light: var(--emu-common-colors-black);
  --emu-component-containers-gallery-item-hover-color-border-dark: var(--emu-common-colors-white);
  --emu-component-containers-gallery-stage-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-stage-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-padding-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-top-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-right-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-bottom-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-narrow: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-margin-left-wide: var(--emu-common-sizing-none);
  --emu-component-containers-gallery-preview-container-gap-narrow: var(--emu-common-sizing-xs);
  --emu-component-containers-gallery-preview-container-gap-wide: var(--emu-common-sizing-xs);
  --emu-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-component-layers-modal-body-sizing-width: 93%;
  --emu-component-layers-modal-body-sizing-max-width: 488px;
  --emu-component-layers-modal-body-padding-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-body-padding-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-layers-modal-body-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-body-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-layers-modal-body-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-body-padding-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-layers-modal-body-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-body-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-none);
  --emu-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-component-layers-modal-close-size-height-narrow: 22px;
  --emu-component-layers-modal-close-size-height-wide: 22px;
  --emu-component-layers-modal-close-size-width-narrow: 22px;
  --emu-component-layers-modal-close-size-width-wide: 22px;
  --emu-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-color-background-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) 12px / 17px var(--emu-semantic-font-families-heading);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-top-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-right-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-padding-left-wide: 8px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-outline-width: 1px;
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-common-colors-white);
  --emu-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-cards-teaser-text-color-light: var(--emu-common-colors-white);
  --emu-figma-component-cards-teaser-text-color-dark: var(--emu-common-colors-black);
  --emu-figma-component-cards-teaser-title-max-width: 66.6667%;
  --emu-figma-component-cards-teaser-title-margin-narrow: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-narrow) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-title-margin-wide: var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-none) var(--emu-semantic-spacing-one-line-height-wide) var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-top-wide: var(--emu-common-spacing-xs);
  --emu-figma-component-cards-teaser-description-margin-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-cards-teaser-description-margin-bottom-wide: var(--emu-common-spacing-small);
  --emu-figma-component-cards-teaser-padding-narrow: var(--emu-semantic-spacing-two-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-padding-wide: var(--emu-semantic-spacing-two-line-height-wide) var(--emu-common-sizing-medium);
  --emu-figma-component-cards-teaser-actions-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-actions-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-cards-teaser-actions-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-actions-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-cards-teaser-disclaimer-padding-narrow: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-cards-teaser-disclaimer-padding-wide: var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-half-line-height-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-banner-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-background-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-background-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-banner-item-color-border-default-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-light: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-color-border-hover-dark: var(--emu-common-colors-grey-800);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-default-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-light: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-banner-item-text-color-hover-dark: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-banner-item-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-banner-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-bottom-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-banner-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-lists-navigation-dropdown-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-light: var(--emu-common-colors-white);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-light: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-color-background-hover-dark: var(--emu-common-colors-grey-100);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-lists-navigation-dropdown-item-text-color-hover-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-lists-navigation-z-index: var(--emu-common-other-z-index-header);
  --emu-figma-component-lists-navigation-text-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-lists-navigation-text-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-lists-navigation-text-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-lists-navigation-text-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-lists-navigation-text-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-feeds-instagram-padding-top-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-top-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-right-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-feeds-instagram-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-feeds-instagram-padding-left-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-padding-left-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-feeds-instagram-post-gap-narrow: 40px;
  --emu-figma-component-feeds-instagram-post-gap-wide: 40px;
  --emu-figma-component-ingredients-google-map-color-background-light: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-background-dark: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-ingredients-google-map-color-border-light: var(--emu-semantic-colors-none);
  --emu-figma-component-ingredients-google-map-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-ingredients-google-map-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-ingredients-google-map-margin-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-ingredients-google-map-margin-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-ingredients-google-map-border-radius-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-radius-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-ingredients-google-map-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-ingredients-google-map-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-ingredients-image-color-background-light: var(--emu-semantic-colors-background-light);
  --emu-figma-component-ingredients-image-color-background-dark: var(--emu-semantic-colors-background-dark);
  --emu-figma-component-actions-back-to-top-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-actions-back-to-top-icon-size-height: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-icon-size-width: var(--emu-common-sizing-medium);
  --emu-figma-component-actions-back-to-top-position-bottom-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-bottom-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-left-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-left-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-right-narrow: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-right-wide: var(--emu-common-spacing-none);
  --emu-figma-component-actions-back-to-top-position-top-narrow: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-position-top-wide: var(--emu-common-spacing-unset);
  --emu-figma-component-actions-back-to-top-margin-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-left-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-left-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-right-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-right-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-margin-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-margin-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-top-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-left-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-narrow: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-border-radius-bottom-right-wide: var(--emu-semantic-border-radius-none);
  --emu-figma-component-actions-back-to-top-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-actions-back-to-top-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-actions-back-to-top-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-actions-back-to-top-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-actions-back-to-top-border-width-all-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-border-width-all-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-actions-back-to-top-color-border-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-actions-back-to-top-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-actions-back-to-top-color-background-default-dark: var(--emu-common-colors-grey-200);
  --emu-figma-component-actions-back-to-top-color-icon-default-light: var(--emu-common-colors-white);
  --emu-figma-component-actions-back-to-top-color-icon-default-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-scroll-reveal-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-figma-component-containers-scroll-reveal-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-figma-component-containers-scroll-reveal-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-padding-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-scroll-reveal-padding-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-progress-indicator-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-progress-bar-color-bar-dark: var(--emu-common-colors-grey-300);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-right-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-static-section-margin-left-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-right-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-scroll-reveal-cards-padding-left-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-medium);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-typography-wide: var(--emu-semantic-typography-wide-headings-medium);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-header-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-narrow: var(--emu-semantic-typography-narrow-headings-large);
  --emu-figma-component-containers-scroll-reveal-cards-text-typography-wide: var(--emu-semantic-typography-wide-headings-large);
  --emu-figma-component-containers-scroll-reveal-cards-text-color-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-scroll-reveal-cards-text-color-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-comparison-slider-divider-color-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-comparison-slider-divider-color-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-comparison-slider-divider-size-width-narrow: 2px;
  --emu-figma-component-containers-comparison-slider-divider-size-width-wide: 2px;
  --emu-figma-component-containers-comparison-slider-handle-size-width-narrow: var(--emu-semantic-sizing-medium);
  --emu-figma-component-containers-comparison-slider-handle-size-width-wide: var(--emu-semantic-sizing-large);
  --emu-figma-component-containers-comparison-slider-handle-shadow-narrow-light: 0 0 6 0 var(--emu-common-colors-black);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-top-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-top-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-right-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-right-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-bottom-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-bottom-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-left-narrow: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-padding-left-wide: var(--emu-semantic-sizing-xs);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-size-width-narrow: var(--emu-common-sizing-small);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-size-width-wide: var(--emu-common-sizing-small);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-color-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-thickness-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-comparison-slider-custom-handle-arrow-thickness-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-comparison-slider-custom-handle-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-containers-comparison-slider-custom-handle-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-color-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-color-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-line-height-narrow: var(--emu-semantic-line-heights-narrow-medium);
  --emu-figma-component-containers-comparison-slider-custom-handle-text-line-height-wide: var(--emu-semantic-line-heights-wide-medium);
  --emu-figma-component-containers-tabs-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-figma-component-containers-tabs-navigation-item-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-containers-tabs-navigation-item-gap-vertical-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-top-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-right-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-bottom-active: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-default: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-border-width-left-active: var(--emu-semantic-border-width-none);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-border-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-default-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-tabs-navigation-item-color-background-active-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-default-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-light: var(--emu-semantic-colors-text-light);
  --emu-figma-component-containers-tabs-navigation-item-text-color-active-dark: var(--emu-semantic-colors-text-dark);
  --emu-figma-component-containers-tabs-content-padding-top-narrow: var(--emu-component-containers-container-padding-top-narrow);
  --emu-figma-component-containers-tabs-content-padding-top-wide: var(--emu-component-containers-container-padding-top-wide);
  --emu-figma-component-containers-tabs-content-padding-right-narrow: var(--emu-component-containers-container-padding-right-narrow);
  --emu-figma-component-containers-tabs-content-padding-right-wide: var(--emu-component-containers-container-padding-right-wide);
  --emu-figma-component-containers-tabs-content-padding-bottom-narrow: var(--emu-component-containers-container-padding-bottom-narrow);
  --emu-figma-component-containers-tabs-content-padding-bottom-wide: var(--emu-component-containers-container-padding-bottom-wide);
  --emu-figma-component-containers-tabs-content-padding-left-narrow: var(--emu-component-containers-container-padding-left-narrow);
  --emu-figma-component-containers-tabs-content-padding-left-wide: var(--emu-component-containers-container-padding-left-wide);
  --emu-figma-component-containers-tabs-content-gap-horizontal-narrow: var(--emu-component-containers-container-gap-horizontal-narrow);
  --emu-figma-component-containers-tabs-content-gap-horizontal-wide: var(--emu-component-containers-container-gap-horizontal-wide);
  --emu-figma-component-containers-tabs-content-gap-vertical-narrow: var(--emu-component-containers-container-gap-vertical-narrow);
  --emu-figma-component-containers-tabs-content-gap-vertical-wide: var(--emu-component-containers-container-gap-vertical-wide);
  --emu-figma-component-containers-tabs-content-color-background-light: var(--emu-component-containers-container-color-background-light);
  --emu-figma-component-containers-tabs-content-color-background-dark: var(--emu-component-containers-container-color-background-dark);
  --emu-figma-component-containers-isi-header-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-header-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-header-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-header-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-body-padding-top-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-top-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-right-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-bottom-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-narrow: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-padding-left-wide: var(--emu-common-spacing-medium);
  --emu-figma-component-containers-isi-body-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-color-border-top-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-isi-body-color-border-top-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-isi-body-border-width-top: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-isi-open-content-size: 30vh;
  --emu-figma-component-containers-isi-z-index: var(--emu-common-other-z-index-isi);
  --emu-figma-component-containers-carousel-gap-horizontal-narrow: calc(var(--emu-semantic-spacing-horizontal-narrow) * 4);
  --emu-figma-component-containers-carousel-gap-horizontal-wide: calc(var(--emu-semantic-spacing-horizontal-wide) * 4);
  --emu-figma-component-containers-carousel-gap-vertical-narrow: var(--emu-semantic-spacing-one-line-height-narrow);
  --emu-figma-component-containers-carousel-gap-vertical-wide: var(--emu-semantic-spacing-two-line-height-wide);
  --emu-figma-component-containers-carousel-indicators-margin-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-margin-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-margin-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-margin-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-gap-horizontal-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-gap-horizontal-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-background-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-default-light: var(--emu-semantic-colors-actions-primary-default-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-default-dark: var(--emu-semantic-colors-actions-primary-default-dark);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-active-light: var(--emu-semantic-colors-actions-primary-active-light);
  --emu-figma-component-containers-carousel-indicators-dots-color-border-active-dark: var(--emu-semantic-colors-actions-primary-active-dark);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-all: var(--emu-figma-component-containers-carousel-indicators-dots-border-width-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-dots-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-dots-size-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-dots-size-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-dots-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-dots-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-dots-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-image-container-size-narrow: 160px;
  --emu-figma-component-containers-carousel-indicators-image-container-size-wide: 160px;
  --emu-figma-component-containers-carousel-indicators-image-color-border-default-light: var(--emu-common-colors-grey-100);
  --emu-figma-component-containers-carousel-indicators-image-color-border-default-dark: var(--emu-common-colors-grey-100);
  --emu-figma-component-containers-carousel-indicators-image-color-border-active-light: var(--emu-common-colors-grey-200);
  --emu-figma-component-containers-carousel-indicators-image-color-border-active-dark: var(--emu-common-colors-grey-200);
  --emu-figma-component-containers-carousel-indicators-image-border-width-all: var(--emu-figma-component-containers-carousel-indicators-image-border-width-narrow);
  --emu-figma-component-containers-carousel-indicators-image-border-width-narrow: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-indicators-image-border-width-wide: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-indicators-image-size-narrow: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-indicators-image-size-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-indicators-text-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-indicators-text-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-indicators-text-color-text-light: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-indicators-text-color-text-dark: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-indicators-text-padding-top-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-top-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-right-narrow: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-right-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-bottom-narrow: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-bottom-wide: var(--emu-common-sizing-xs);
  --emu-figma-component-containers-carousel-indicators-text-padding-left-narrow: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-padding-left-wide: var(--emu-common-sizing-none);
  --emu-figma-component-containers-carousel-indicators-text-font-size-narrow: var(--emu-semantic-font-sizes-narrow-medium);
  --emu-figma-component-containers-carousel-indicators-text-font-size-wide: var(--emu-semantic-font-sizes-wide-medium);
  --emu-figma-component-containers-carousel-indicators-thumbnail-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-indicators-thumbnail-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-indicators-thumbnail-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-indicators-thumbnail-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-indicators-thumbnail-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-indicators-thumbnail-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-indicators-thumbnail-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-controls-color-background-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-controls-color-background-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-controls-color-border-light: var(--emu-common-colors-white);
  --emu-figma-component-containers-carousel-controls-color-border-dark: var(--emu-common-colors-black);
  --emu-figma-component-containers-carousel-controls-border-width-all: var(--emu-figma-component-containers-carousel-controls-border-width-narrow);
  --emu-figma-component-containers-carousel-controls-border-width-narrow: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-controls-border-width-wide: var(--emu-common-border-width-none);
  --emu-figma-component-containers-carousel-controls-z-index: var(--emu-common-other-z-index-layer);
  --emu-figma-component-containers-carousel-controls-icon-color-fill-light: var(--emu-component-ingredients-icon-color-fill-light);
  --emu-figma-component-containers-carousel-controls-icon-color-fill-dark: var(--emu-component-ingredients-icon-color-fill-dark);
  --emu-figma-component-containers-carousel-controls-icon-size-narrow: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-controls-icon-size-wide: var(--emu-common-sizing-medium);
  --emu-figma-component-containers-carousel-controls-padding-narrow: var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow) var(--emu-semantic-spacing-vertical-narrow) var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-controls-padding-wide: var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide) var(--emu-semantic-spacing-vertical-wide) var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-color-border-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-color-border-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-container-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-container-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-container-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-containers-carousel-frame-container-gap-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-containers-carousel-frame-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-frame-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-containers-carousel-frame-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-containers-carousel-frame-color-border-default-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-frame-color-border-default-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-color-border-active-light: var(--emu-semantic-colors-foreground-light);
  --emu-figma-component-containers-carousel-frame-color-border-active-dark: var(--emu-semantic-colors-foreground-dark);
  --emu-figma-component-containers-carousel-frame-border-width-default-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-default-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-active-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-frame-border-width-active-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-border-width-narrow: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-border-width-wide: var(--emu-semantic-border-width-thin);
  --emu-figma-component-containers-carousel-padding-narrow: var(--emu-semantic-spacing-none);
  --emu-figma-component-containers-carousel-padding-wide: var(--emu-semantic-spacing-none);
  --emu-figma-component-layers-modal-z-index: var(--emu-common-other-z-index-modal);
  --emu-figma-component-layers-modal-scrim-color-background: var(--emu-semantic-colors-surface-modal-backdrop);
  --emu-figma-component-layers-modal-body-sizing-width: 80%;
  --emu-figma-component-layers-modal-body-sizing-max-width: 400px;
  --emu-figma-component-layers-modal-body-padding-top-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-body-padding-top-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-modal-body-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-body-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-modal-body-padding-bottom-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-body-padding-bottom-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-modal-body-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-body-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-modal-body-color-background-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-background-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-color-border-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-body-color-border-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-body-border-width-all: var(--emu-semantic-border-width-none);
  --emu-figma-component-layers-modal-actions-close-padding-top: var(--emu-common-spacing-none);
  --emu-figma-component-layers-modal-actions-close-padding-right: var(--emu-common-spacing-none);
  --emu-figma-component-layers-modal-actions-close-padding-bottom: var(--emu-common-spacing-none);
  --emu-figma-component-layers-modal-actions-close-padding-left: var(--emu-common-spacing-none);
  --emu-figma-component-layers-modal-actions-close-text-typography: var(--emu-semantic-typography-narrow-headings-xxl);
  --emu-figma-component-layers-modal-actions-close-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-modal-actions-close-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-actions-prompt-button-group-gap-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-actions-prompt-button-group-margin-top: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-modal-close-size-height-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-close-size-height-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-close-size-width-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-modal-close-size-width-wide: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-z-index: var(--emu-common-other-z-index-cookie-banner);
  --emu-figma-component-layers-cookie-consent-banner-max-width: var(--emu-component-containers-container-max-width) px;
  --emu-figma-component-layers-cookie-consent-banner-text-color-light: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-text-color-dark: var(--emu-common-colors-white);
  --emu-figma-component-layers-cookie-consent-banner-color-background-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-color-background-dark: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-shadow: 4px 4px 20px 4px #0003;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-top-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-right-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-bottom-wide: 24px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-narrow: 32px;
  --emu-figma-component-layers-cookie-consent-banner-padding-left-wide: 32px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-narrow: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-wide: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-narrow-medium) / var(--emu-semantic-line-heights-narrow-medium) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-typography-large: var(--emu-semantic-font-weight-regular) var(--emu-semantic-font-sizes-wide-large) / var(--emu-semantic-line-heights-wide-large) var(--emu-semantic-font-families-heading);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-light: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-focus-dark: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-hover-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-light: var(--emu-semantic-colors-actions-on-primary-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-text-color-active-dark: var(--emu-semantic-colors-actions-on-primary-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-top-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-right-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-narrow: var(--emu-semantic-spacing-vertical-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-bottom-wide: var(--emu-semantic-spacing-vertical-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-narrow: var(--emu-semantic-spacing-horizontal-narrow);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-padding-left-wide: var(--emu-semantic-spacing-horizontal-wide);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-narrow: 24px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-top-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-right-wide: 0px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-bottom-wide: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-narrow: auto;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-margin-left-wide: 56px;
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-width: var(--emu-common-border-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-outline-width: var(--emu-semantic-outline-width-thin);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-top-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-right: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-border-radius-bottom-left: var(--emu-semantic-border-radius-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-focus-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-hover-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-light: var(--emu-semantic-colors-surface-dark);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-border-active-dark: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-light: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-focus-dark: var(--emu-common-colors-transparent);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-hover-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-light: var(--emu-semantic-colors-surface-light);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-background-active-dark: var(--emu-common-colors-black);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-focus-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-light: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-hover-dark: var(--emu-semantic-colors-none);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-light: var(--emu-common-colors-grey-300);
  --emu-figma-component-layers-cookie-consent-banner-settings-button-color-outline-color-active-dark: var(--emu-common-colors-grey-300);
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-overflow-scrolling: touch;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -400% 0;
  }

  100% {
    background-position: 400% 0;
  }
}

body {
  background-color: var(--emu-common-colors-light-brown-100);
  transition: background-color .6s ease-in, opacity .6s ease-in;
  position: relative;
}

body:before, body:after {
  content: "";
  z-index: var(--emu-common-other-z-index-header);
  pointer-events: none;
  background: linear-gradient(to right, #f5ecea 8%, #e0c4bd 50%, #f5ecea 92%) 0 0 / 400%;
  width: 100%;
  height: 496px;
  transition: opacity .4s ease-in-out;
  animation-name: placeHolderShimmer;
  animation-duration: 10s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.cq-Editable-dom--container body:before, .cq-Editable-dom--container body:after {
  display: none !important;
}

body:after {
  background: linear-gradient(to right, #f1f1f1 8%, #fff 50%, #f1f1f1 92%);
  max-width: 1120px;
  height: 100vh;
  top: 496px;
  left: 50%;
  transform: translateX(-50%);
}

body header + div, body header + main, body footer, body .footer {
  opacity: 0;
  transition: all .6s ease-in .4s;
}

.cq-Editable-dom--container body header + div, .cq-Editable-dom--container body header + main, .cq-Editable-dom--container body footer, .cq-Editable-dom--container body .footer {
  opacity: 1 !important;
}

body.visible {
  background-color: var(--emu-common-colors-transparent);
}

body.visible:before, body.visible:after {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

body.visible header + div, body.visible header + main, body.visible footer, body.visible .footer {
  opacity: 1;
}

html, body {
  font: var(--emu-semantic-typography-narrow-body-regular);
  scroll-padding-top: 70px;
}

@media (min-width: 1024px) {
  html, body {
    font: var(--emu-semantic-typography-wide-body-regular);
    scroll-padding-top: 160px;
  }
}

html .xf-content-height, body .xf-content-height {
  margin: inherit;
}

html .experiencefragment, body .experiencefragment {
  padding: 0;
}

html, body, h1, h2, h3, h4, h5, h6, p, li, a, span {
  margin: 0;
  padding: 0;
}

* {
  outline: var(--emu-semantic-colors-surface-dark);
}

*, :before, :after {
  box-sizing: border-box;
}

.cmp-image__image, image {
  width: auto;
  max-width: 100%;
}

button, input, optgroup, select, textarea {
  font: inherit;
}

sup {
  vertical-align: super;
  font-size: 80%;
  line-height: 0;
  position: relative;
  top: 3px;
}

a {
  color: inherit;
}

a:hover, a:focus, a, a:active {
  text-underline-offset: var(--emu-common-spacing-xs);
  text-decoration: underline;
}

ul, ol {
  padding-left: var(--emu-common-spacing-medium);
  margin-top: var(--emu-semantic-spacing-half-line-height-narrow);
  margin-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
}

label {
  cursor: pointer;
}

main [data-component="text"] a {
  text-underline-offset: var(--emu-common-spacing-xs);
  text-decoration: underline;
}

.u-body-font--xs {
  font: var(--emu-semantic-typography-narrow-body-xs);
  letter-spacing: normal !important;
}

@media (min-width: 1024px) {
  .u-body-font--xs {
    font: var(--emu-semantic-typography-wide-body-xs);
    letter-spacing: 1px !important;
  }
}

.u-body-font--small {
  font: var(--emu-semantic-typography-narrow-body-small);
}

@media (min-width: 1024px) {
  .u-body-font--small {
    font: var(--emu-semantic-typography-wide-body-small);
  }
}

.u-body-font--large {
  font: var(--emu-semantic-typography-narrow-body-large);
}

@media (min-width: 1024px) {
  .u-body-font--large {
    font: var(--emu-semantic-typography-wide-body-large);
  }
}

.u-body-font--xl {
  font: var(--emu-semantic-typography-narrow-body-xl);
  letter-spacing: .5px !important;
}

@media (min-width: 1024px) {
  .u-body-font--xl {
    font: var(--emu-semantic-typography-wide-body-xl);
  }
}

.u-body-font--eyebrow {
  font: var(--emu-semantic-typography-narrow-body-eyebrow);
  text-transform: uppercase;
  letter-spacing: 3px !important;
}

@media (min-width: 1024px) {
  .u-body-font--eyebrow {
    font: var(--emu-semantic-typography-wide-body-eyebrow);
  }
}

.u-body-font--link {
  font: var(--emu-semantic-typography-narrow-body-link);
  letter-spacing: 1.8px !important;
}

@media (min-width: 1024px) {
  .u-body-font--link {
    font: var(--emu-semantic-typography-wide-body-link);
  }
}

.u-body-font--cta {
  font: var(--emu-semantic-typography-narrow-body-cta);
  letter-spacing: 1.8px !important;
}

@media (min-width: 1024px) {
  .u-body-font--cta {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

.u-body-font--h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

.u-body-font--h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

.u-body-font--h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

.u-body-font--h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

.u-body-font--h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

.u-body-font--h6 {
  font: var(--emu-component-ingredients-title-h6-typography-narrow);
}

@media (min-width: 1024px) {
  .u-body-font--h6 {
    font: var(--emu-component-ingredients-title-h6-typography-wide);
  }
}

[class*="u-body-font"] h1, [class*="u-body-font"] h2, [class*="u-body-font"] h3, [class*="u-body-font"] h4, [class*="u-body-font"] h5, [class*="u-body-font"] h6, [class*="u-body-font"] p, [class*="u-body-font"] li, [class*="u-body-font"] a, [class*="u-body-font"] span {
  font: inherit !important;
  color: inherit !important;
  letter-spacing: inherit !important;
  text-transform: inherit !important;
}

.u-text-transform--all-capitals {
  text-transform: uppercase;
}

.u-text-break--no-break h1, .u-text-break--no-break h2, .u-text-break--no-break h3, .u-text-break--no-break h4, .u-text-break--no-break h5, .u-text-break--no-break h6, .u-text-break--no-break p, .u-text-break--no-break li, .u-text-break--no-break a, .u-text-break--no-break span {
  white-space: nowrap;
}

.u-font-weight--light h1, .u-font-weight--light h2, .u-font-weight--light h3, .u-font-weight--light h4, .u-font-weight--light h5, .u-font-weight--light h6, .u-font-weight--light p, .u-font-weight--light li, .u-font-weight--light span, .u-font-weight--light a {
  font-weight: var(--emu-semantic-font-weight-light);
}

.u-font-weight--bold h1, .u-font-weight--bold h2, .u-font-weight--bold h3, .u-font-weight--bold h4, .u-font-weight--bold h5, .u-font-weight--bold h6, .u-font-weight--bold p, .u-font-weight--bold li, .u-font-weight--bold span, .u-font-weight--bold a {
  font-weight: var(--emu-semantic-font-weight-bold);
}

.u-text-align--center-narrow {
  text-align: center;
}

@media (min-width: 1024px) {
  .u-text-align--center-narrow {
    text-align: left;
  }
}

[class*="u-text-align--"] h1, [class*="u-text-align--"] h2, [class*="u-text-align--"] h3, [class*="u-text-align--"] h4, [class*="u-text-align--"] h5, [class*="u-text-align--"] h6, [class*="u-text-align--"] p, [class*="u-text-align--"] li, [class*="u-text-align--"] span, [class*="u-text-align--"] a, [class*="u-text-align--"] button {
  text-align: inherit !important;
}

.u-text-align--center {
  text-align: center;
}

.u-text-align--right {
  text-align: right;
}

.u-text-align--left {
  text-align: left;
}

[class*="u-container--"] > .container {
  margin-left: auto;
  margin-right: auto;
}

[class*="u-container--"] > .container > .aaaem-container {
  padding-top: var(--emu-semantic-spacing-vertical-narrow);
  padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
}

@media (min-width: 1024px) {
  [class*="u-container--"] > .container > .aaaem-container {
    padding-top: var(--emu-semantic-spacing-vertical-wide);
    padding-right: var(--emu-semantic-spacing-horizontal-wide);
    padding-bottom: var(--emu-semantic-spacing-vertical-wide);
    padding-left: var(--emu-semantic-spacing-horizontal-wide);
  }
}

.u-container--default > .container {
  max-width: var(--emu-semantic-sizing-xxxl);
}

.u-container--large > .container {
  max-width: var(--emu-semantic-sizing-container);
}

.u-container--with-height-regular > .container > .aaaem-container {
  min-height: 500px;
}

.u-p-regular {
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
}

@media (min-width: 1024px) {
  .u-p-regular {
    padding-top: var(--emu-semantic-spacing-vertical-wide) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-wide) !important;
    padding-bottom: var(--emu-semantic-spacing-vertical-wide) !important;
    padding-left: var(--emu-semantic-spacing-horizontal-wide) !important;
  }
}

.u-mt-half {
  margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-mt-half-narrow {
    margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-mt-half-wide {
    margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-pt-half {
  padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-pt-half-narrow {
    padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-pt-half-wide {
    padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-mb-half {
  margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-mb-half-narrow {
    margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-mb-half-wide {
    margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-pb-half {
  padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-pb-half-narrow {
    padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-pb-half-wide {
    padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-my-half {
  margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-my-half-narrow {
    margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-my-half-wide {
    margin-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-py-half {
  padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-py-half-narrow {
    padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-py-half-wide {
    padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    padding-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-mt-regular {
  margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-mt-regular-narrow {
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-mt-regular-wide {
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-pt-regular {
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-pt-regular-narrow {
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-pt-regular-wide {
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-mb-regular {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-mb-regular-narrow {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-mb-regular-wide {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-pb-regular {
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-pb-regular-narrow {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-pb-regular-wide {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-my-regular {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-my-regular-narrow {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-my-regular-wide {
    margin-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    margin-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-py-regular {
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
  padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
}

@media (max-width: 1023px) {
  .u-py-regular-narrow {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

@media (min-width: 1024px) {
  .u-py-regular-wide {
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow) !important;
    padding-top: var(--emu-semantic-spacing-vertical-narrow) !important;
  }
}

.u-mt-double {
  margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-mt-double-narrow {
    margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-mt-double-wide {
    margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-pt-double {
  padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-pt-double-narrow {
    padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-pt-double-wide {
    padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-mb-double {
  margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-mb-double-narrow {
    margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-mb-double-wide {
    margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-pb-double {
  padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-pb-double-narrow {
    padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-pb-double-wide {
    padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-my-double {
  margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-my-double-narrow {
    margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-my-double-wide {
    margin-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    margin-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

.u-py-double {
  padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
}

@media (max-width: 1023px) {
  .u-py-double-narrow {
    padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (min-width: 1024px) {
  .u-py-double-wide {
    padding-top: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
    padding-bottom: calc(2 * var(--emu-semantic-spacing-vertical-narrow)) !important;
  }
}

@media (max-width: 1023px) {
  .u-mt-none-narrow {
    margin-top: var(--emu-common-spacing-none) !important;
  }

  .u-pt-none-narrow {
    padding-top: var(--emu-common-spacing-none) !important;
  }

  .u-mb-none-narrow {
    margin-bottom: var(--emu-common-spacing-none) !important;
  }

  .u-pb-none-narrow {
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

@media (max-width: 1023px) {
  .u-my-none-narrow {
    margin-top: var(--emu-common-spacing-none) !important;
    margin-bottom: var(--emu-common-spacing-none) !important;
  }
}

@media (max-width: 1023px) {
  .u-py-none-narrow {
    padding-top: var(--emu-common-spacing-none) !important;
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

@media (min-width: 1024px) {
  .u-mt-none-wide {
    margin-top: var(--emu-common-spacing-none) !important;
  }

  .u-pt-none-wide {
    padding-top: var(--emu-common-spacing-none) !important;
  }

  .u-mb-none-wide {
    margin-bottom: var(--emu-common-spacing-none) !important;
  }

  .u-pb-none-wide {
    padding-bottom: var(--emu-common-spacing-none) !important;
  }

  .u-my-none-wide {
    margin-top: var(--emu-common-spacing-none) !important;
    margin-bottom: var(--emu-common-spacing-none) !important;
  }

  .u-py-none-wide {
    padding-top: var(--emu-common-spacing-none) !important;
    padding-bottom: var(--emu-common-spacing-none) !important;
  }
}

.u-mt-none {
  margin-top: var(--emu-common-spacing-none) !important;
}

.u-mb-none {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.u-pt-none {
  padding-top: var(--emu-common-spacing-none) !important;
}

.u-pb-none {
  padding-bottom: var(--emu-common-spacing-none) !important;
}

.u-my-none {
  margin-top: var(--emu-common-spacing-none) !important;
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.u-py-none {
  padding-top: var(--emu-common-spacing-none) !important;
  padding-bottom: var(--emu-common-spacing-none) !important;
}

.u-m-none {
  margin: var(--emu-common-spacing-none) !important;
}

.u-p-none {
  padding: var(--emu-common-spacing-none) !important;
}

@media (max-width: 1023px) {
  .u-px-none-narrow {
    padding-left: var(--emu-common-spacing-none) !important;
    padding-right: var(--emu-common-spacing-none) !important;
  }
}

@media (min-width: 1024px) {
  .u-px-none-wide {
    padding-left: var(--emu-common-spacing-none) !important;
    padding-right: var(--emu-common-spacing-none) !important;
  }
}

.u-px-none {
  padding-left: var(--emu-common-spacing-none) !important;
  padding-right: var(--emu-common-spacing-none) !important;
}

.u-px-regular {
  padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
  padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
}

@media (min-width: 1024px) {
  .u-px-regular-wide {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  }
}

@media (max-width: 1023px) {
  .u-px-regular-narrow {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow) !important;
    padding-right: var(--emu-semantic-spacing-horizontal-narrow) !important;
  }
}

.u-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.embed:has(.u-mx-auto) {
  margin-left: auto;
  margin-right: auto;
}

div:has(.u-mx-auto) {
  margin-left: auto;
  margin-right: auto;
}

.image:has(.u-mx-auto) img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.u-bg--white {
  background-color: var(--emu-common-colors-white);
}

.u-bg--black {
  background-color: var(--emu-common-colors-black);
}

.u-bg--grey-1000 {
  background-color: var(--emu-common-colors-grey-1000);
}

.u-bg--brown-500 {
  background-color: var(--emu-common-colors-light-brown-500);
}

.u-bg--dark-brown-500 {
  background-color: var(--emu-common-colors-dark-brown-500);
}

.u-bg--medium-brown-100 {
  background-color: var(--emu-common-colors-medium-brown-100);
}

.u-bg--light-brown-50 {
  background-color: var(--emu-common-colors-light-brown-50);
}

.u-bg--light-brown-100 {
  background-color: var(--emu-common-colors-light-brown-100);
}

.u-bg--light-grey-100 {
  background-color: var(--emu-common-colors-light-grey-100);
}

.u-bg--light-grey-500 {
  background-color: var(--emu-common-colors-light-grey-500);
}

.u-bg--light-brown-200 {
  background-color: var(--emu-common-colors-light-brown-200);
}

.u-bg--blush-100 {
  background-color: var(--emu-common-colors-blush-100);
}

.u-bg--common-grey-100 {
  background-color: var(--emu-common-colors-grey-100);
}

.u-bg--beige-100 {
  background-color: var(--emu-common-colors-beige-100);
}

.u-text--white, .u-text--white.emu-navigation > ul > li > a, .u-text--white.emu-navigation > ul > li > a:hover, .u-text--white.emu-navigation > ul > li > a:focus, .u-text--white.emu-navigation > ul > li > a:active, .u-text--white.emu-navigation > ul > li > span, .u-text--white.emu-navigation > ul > li > span:hover, .u-text--white.emu-navigation > ul > li > span:focus, .u-text--white.emu-navigation > ul > li > span:active {
  color: var(--emu-common-colors-white);
}

.u-text--black {
  color: var(--emu-common-colors-black);
}

.u-text--grey-1000 {
  color: var(--emu-common-colors-grey-1000);
}

.u-text--brown-500 {
  color: var(--emu-common-colors-light-brown-500);
}

.u-text--dark-brown-500 {
  color: var(--emu-common-colors-dark-brown-500);
}

.u-text--medium-brown-100 {
  color: var(--emu-common-colors-medium-brown-100);
}

.u-text--medium-brown-500 {
  color: var(--emu-common-colors-medium-brown-500);
}

.u-text--light-brown-50 {
  color: var(--emu-common-colors-light-brown-50);
}

.u-text--light-brown-100 {
  color: var(--emu-common-colors-light-brown-100);
}

.u-text--light-grey-100 {
  color: var(--emu-common-colors-light-grey-100);
}

.u-text--error {
  color: var(--emu-semantic-colors-error-light);
}

[class*="u-text--"] h1, [class*="u-text--"] h2, [class*="u-text--"] h3, [class*="u-text--"] h4, [class*="u-text--"] h5, [class*="u-text--"] h6, [class*="u-text--"] p, [class*="u-text--"] li, [class*="u-text--"] a, [class*="u-text--"] span, [class*="u-text--"] button.btn--as-link {
  color: inherit !important;
}

:is(.button:has(.btn--disabled), .button:has(a:disabled), .button:has(button:disabled)) {
  opacity: .5;
  cursor: not-allowed;
  transition: all .2s;
}

:is(.button:has(.btn--disabled):hover, .button:has(a:disabled):hover, .button:has(button:disabled):hover) {
  opacity: .25;
}

.button .btn--disabled, .button a:disabled, .button button:disabled {
  pointer-events: none;
}

.button .btn--icon.emu-button-v2.emu-button.emu-button, .button .btn--icon.emu-button-v2.emu-button.emu-button:active, .button .btn--icon.emu-button-v2.emu-button.emu-button:hover, .button .btn--icon.emu-button-v2.emu-button.emu-button:focus {
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-transparent);
  outline: var(--emu-common-outline-width-unset);
  border: var(--emu-common-border-width-none);
  transition: none;
}

.button .btn--icon.emu-button-v2.emu-button.emu-button span {
  display: none;
}

.button .btn--icon-white-bg.emu-button-v2.emu-button.emu-button.btn--icon {
  border-radius: var(--emu-component-actions-button-border-radius-primary-outline);
  padding: var(--emu-common-spacing-small);
  background-color: var(--emu-common-colors-white);
}

.button .btn--icon-white-bg.emu-button-v2.emu-button.emu-button.btn--icon img {
  width: var(--emu-common-sizing-small);
}

.button .btn--as-link.emu-button-v2.emu-button {
  gap: var(--emu-common-spacing-small);
}

.button .btn--as-link.emu-button-v2.emu-button, .button .btn--as-link.emu-button-v2.emu-button:active, .button .btn--as-link.emu-button-v2.emu-button:hover, .button .btn--as-link.emu-button-v2.emu-button:focus {
  font-family: var(--emu-semantic-font-families-heading);
  padding: var(--emu-common-spacing-none);
  background-color: var(--emu-common-colors-transparent);
  color: var(--emu-common-colors-black);
  outline: var(--emu-common-outline-width-unset);
  border: var(--emu-common-border-width-none);
  text-decoration: underline;
  transition: none;
}

.button .btn--close {
  width: 14px;
  height: 14px;
  position: relative;
  overflow: hidden;
}

.button .btn--close.emu-button-v2.emu-button {
  display: block;
}

.button .btn--close span, .button .btn--close svg {
  display: none;
}

.button .btn--close:before, .button .btn--close:after {
  content: "";
  width: var(--emu-common-border-width-thin);
  background-color: var(--emu-common-colors-black);
  height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.button .btn--close:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.btn-container {
  justify-content: center;
  align-items: center;
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  flex-wrap: wrap;
  width: 100%;
  display: flex;
}

.btn-container--left-align {
  justify-content: flex-start;
}

.btn-container--right-align {
  justify-content: flex-end;
}

[data-component="carousel-v2"].emu-carousel.u-carousel--regular .tns-liveregion {
  display: none;
}

[data-component="carousel-v2"].emu-carousel.u-carousel--regular .tns-controls.js-show {
  padding: var(--emu-common-spacing-none);
  width: 100%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

[data-component="carousel-v2"].emu-carousel.u-carousel--regular .emu-carousel__action {
  background-color: var(--emu-common-colors-transparent);
  height: var(--emu-common-sizing-small);
  position: absolute;
  top: 0;
}

[data-component="carousel-v2"].emu-carousel.u-carousel--regular .emu-carousel__action-prev {
  transform: translateX(-100%)translateY(-50%)translateY(-8px);
}

@media (min-width: 1024px) {
  [data-component="carousel-v2"].emu-carousel.u-carousel--regular .emu-carousel__action-prev {
    transform: translateX(-100%) translateX(calc(-1 * var(--emu-semantic-spacing-horizontal-narrow))) translateY(-50%) translateY(-8px);
  }
}

[data-component="carousel-v2"].emu-carousel.u-carousel--regular .emu-carousel__action-next {
  transform: translateX(100%)translateY(-50%)translateY(-8px);
}

@media (min-width: 1024px) {
  [data-component="carousel-v2"].emu-carousel.u-carousel--regular .emu-carousel__action-next {
    transform: translateX(100%) translateX(var(--emu-semantic-spacing-horizontal-narrow)) translateY(-50%) translateY(-8px);
  }
}

[data-component="carousel-v2"].emu-carousel.u-carousel--disabled.emu-carousel .tns-inner {
  justify-content: center;
  display: flex;
}

[data-component="carousel-v2"].emu-carousel.u-carousel--disabled.emu-carousel .emu-carousel__content {
  width: auto !important;
  transform: none !important;
}

[data-component="carousel-v2"].emu-carousel.u-carousel--disabled.emu-carousel .tns-slide-cloned {
  display: none;
}

@media (max-width: 1023px) {
  [data-component="carousel-v2"].emu-carousel.u-carousel--hide-arrows-in-mobile.emu-carousel .tns-controls.js-show {
    display: none;
  }
}

[data-component="carousel-v2"].emu-carousel.u-carousel--vertical-center-content .emu-carousel__content {
  align-items: center;
  display: flex;
}

.u-carousel-title {
  max-width: 40%;
  margin-bottom: -24px;
}

@media (min-width: 1024px) {
  .u-carousel-title {
    max-width: 50%;
  }
}

.cq-Editable-dom--container .u-carousel-title {
  margin-bottom: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .teaser .u-teaser--overlapped-content.emu-teaser .content-container {
    padding-bottom: 120px;
  }

  .teaser .u-teaser--overlapped-content-xs.emu-teaser .content-container {
    padding-bottom: 40px;
  }

  .teaser .u-teaser--overlapped-content-sm.emu-teaser .content-container {
    padding-bottom: 80px;
  }

  .teaser .u-teaser--overlapped-content-lg.emu-teaser .content-container {
    padding-bottom: 160px;
  }
}

.teaser .u-teaser--no-content.emu-teaser.cmp-teaser .content-container {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .teaser .u-teaser--content-vertically-centered-to-img.emu-teaser .content-container {
    height: 100%;
    top: 0;
  }
}

.teaser .u-teaser--img-first-in-mobile.emu-teaser .teaser-container {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .teaser-image-container .cmp-teaser__image img {
    min-height: auto;
  }
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container {
  padding-top: var(--emu-semantic-spacing-vertical-narrow);
  height: 100%;
  top: 0;
}

@media (min-width: 1024px) {
  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container {
    padding: var(--emu-common-spacing-none);
  }
}

@media (max-width: 1023px) {
  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container.text-theme-light {
    background-color: var(--emu-common-colors-light-brown-500);
  }
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container.text-theme-light .content {
  background-color: var(--emu-common-colors-light-brown-500);
}

@media (max-width: 1023px) {
  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container.text-theme-dark {
    background-color: var(--emu-common-colors-white);
  }
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container.text-theme-dark .content {
  background-color: var(--emu-common-colors-white);
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content {
  max-width: 450px;
}

@media (min-width: 1024px) {
  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content {
    border: var(--emu-semantic-border-width-thin) solid var(--emu-common-colors-dark-brown-700);
    border-radius: var(--emu-common-border-radius-xxs);
    padding: var(--emu-semantic-spacing-vertical-narrow);
  }

  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-top-left, .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-middle-left, .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-bottom-left {
    margin-left: calc(2 * var(--emu-semantic-spacing-vertical-narrow));
  }

  .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-top-right, .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-middle-right, .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .content.content-bottom-right {
    margin-right: calc(2 * var(--emu-semantic-spacing-vertical-narrow));
  }
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .emu-teaser__title, .teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .emu-teaser__description {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .emu-teaser__description {
  margin-top: var(--emu-common-spacing-none);
  letter-spacing: .5px !important;
}

.teaser .u-teaser--content-boxed.emu-teaser.inline-text-below .content-container .emu-teaser__action-container {
  margin: var(--emu-common-spacing-none);
}

.teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .teaser-image-container .cmp-teaser__image--mobile img {
  object-fit: cover;
  min-height: 400px;
}

@media (max-width: 1023px) {
  .teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .content-container {
    padding-top: var(--emu-common-spacing-large);
    padding-bottom: var(--emu-common-spacing-large);
    position: absolute;
    top: 0;
  }

  .teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .content-container .content {
    margin-top: var(--emu-common-spacing-none);
    padding-top: var(--emu-semantic-spacing-vertical-narrow);
    padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
    padding-left: calc(.5 * var(--emu-semantic-spacing-vertical-narrow));
    padding-right: calc(.5 * var(--emu-semantic-spacing-vertical-narrow));
    border-radius: var(--emu-common-border-radius-xxs);
    background-color: var(--emu-common-colors-white);
    opacity: .75;
  }

  .teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .content-container .content .emu-teaser__description {
    margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow));
  }

  .teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .content-container .content .emu-teaser__description p {
    font-size: var(--emu-semantic-font-sizes-narrow-medium);
    line-height: var(--emu-semantic-line-heights-narrow-medium);
    letter-spacing: 1px !important;
  }

  .teaser .u-teaser--overlay-text-in-mobile.inline-text-below .teaser-container .content-container .content .emu-teaser__action-container {
    margin-top: var(--emu-semantic-spacing-vertical-narrow);
  }

  .teaser .u-teaser--hide-disclaimer-in-mobile.inline-text-below .teaser-container .emu-teaser__disclaimer {
    display: none;
  }
}

.cq-Editable-dom--container .teaser .u-teaser--hide-disclaimer-in-mobile.inline-text-below .teaser-container .emu-teaser__disclaimer {
  display: block;
}

[data-component="modal"].u-modal--large .modal-content {
  max-width: 600px;
}

[data-component="modal"].u-modal--extra-large .modal-content {
  max-width: 800px;
}

[data-component="modal"].u-modal--hide-cancel-cta .modal-content .button-group [data-ref="close-cta"], [data-component="modal"].u-modal--hide-confirm-cta .modal-content .button-group [data-ref="confirm-cta"] {
  display: none;
}

[data-component="modal"].u-modal--reverse-ctas .modal-content .modal-cta-container.button-group {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

[data-component="modal"].u-modal--ctas-on-right .modal-content .modal-cta-container.button-group {
  justify-content: flex-end;
}

[data-component="modal"].u-modal--ctas-on-right.u-modal--reverse-ctas .modal-content .modal-cta-container.button-group {
  justify-content: flex-start;
}

[data-component="modal"].u-modal--ctas-in-center .modal-content .modal-cta-container.button-group, [data-component="modal"].u-modal--ctas-in-center.u-modal--reverse-ctas .modal-content .modal-cta-container.button-group {
  justify-content: center;
}

@media (min-width: 1024px) {
  .u-page--has-static-header .header__main-nav-container {
    position: static;
  }

  body:has(.resourcedetails) .header__main-nav-container {
    position: static;
  }
}

.u-letter-spacing--sm {
  letter-spacing: .5px !important;
}

.u-letter-spacing--md {
  letter-spacing: 1px !important;
}

.u-letter-spacing--l {
  letter-spacing: 1.8px !important;
}

.u-letter-spacing--xl {
  letter-spacing: 2px !important;
}

.u-letter-spacing--xxl {
  letter-spacing: 3px !important;
}

[class*="u-letter-spacing"] h1, [class*="u-letter-spacing"] h2, [class*="u-letter-spacing"] h3, [class*="u-letter-spacing"] h4, [class*="u-letter-spacing"] h5, [class*="u-letter-spacing"] h6, [class*="u-letter-spacing"] p, [class*="u-letter-spacing"] li, [class*="u-letter-spacing"] a, [class*="u-letter-spacing"] span {
  letter-spacing: inherit !important;
}

.u-image--natural img.cmp-image__image {
  width: auto;
  max-width: 100%;
  height: auto;
}

.u-image--full img.cmp-image__image {
  width: 100%;
  height: auto;
}

[class*="u-image--with-caption"] {
  position: relative;
}

[class*="u-image--with-caption"] .cmp-image__title {
  position: absolute;
}

.u-image--with-caption-white .cmp-image__title {
  color: var(--emu-common-colors-white);
}

.u-image--with-caption-bottom-left .cmp-image__title {
  bottom: 4px;
  left: 4px;
}

.u-image--with-caption-bottom-right .cmp-image__title {
  bottom: 4px;
  right: 4px;
}

div:has( > .u-width--xs) {
  width: 450px;
  max-width: 100%;
}

div:has( > .u-width--sm) {
  width: 575px;
  max-width: 100%;
}

div:has( > .u-width--md) {
  width: 700px;
  max-width: 100%;
}

div:has( > .u-width--xs-strict) {
  width: 450px !important;
  max-width: 100% !important;
}

.u-separator--white .emu-separator__rule {
  background-color: var(--emu-common-colors-white);
}

.u-separator--black .emu-separator__rule {
  background-color: var(--emu-common-colors-black);
}

.u-separator--thin .emu-separator__rule {
  height: var(--emu-common-border-width-thin);
}

.u-separator--thick .emu-separator__rule {
  height: var(--emu-common-border-width-thick);
}

.u-separator--fixed-width .emu-separator__rule {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.u-separator--fixed-width-large .emu-separator__rule {
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
}

.desktop-view {
  display: none;
}

@media (min-width: 1024px) {
  .desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .desktop-view {
  display: initial !important;
}

.tablet-desktop-view {
  display: none;
}

@media (min-width: 768px) {
  .tablet-desktop-view {
    display: block;
  }
}

.cq-Editable-dom--container .tablet-desktop-view {
  display: initial !important;
}

.tablet-mobile-view {
  display: block;
}

@media (min-width: 1024px) {
  .tablet-mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .tablet-mobile-view {
  display: initial !important;
}

.mobile-view {
  display: block;
}

@media (min-width: 768px) {
  .mobile-view {
    display: none;
  }
}

.cq-Editable-dom--container .mobile-view {
  display: initial !important;
}

.no-pad {
  padding: var(--emu-common-spacing-none);
}

.no-x-pad {
  padding-left: var(--emu-common-spacing-none);
  padding-right: var(--emu-common-spacing-none);
}

.no-y-pad {
  padding-top: var(--emu-common-spacing-none);
  padding-bottom: var(--emu-common-spacing-none);
}

.u-text-decoration--none a, .u-text-decoration--none a:hover, .u-text-decoration--none a:focus, .u-text-decoration--none a:active, .u-text-decoration--on-hover a {
  text-decoration: none !important;
}

.u-text-decoration--on-hover a:hover {
  text-decoration: underline !important;
}

@media (min-width: 1024px) {
  .teaser:has(.u-teaser--overlapped-content) {
    z-index: var(--emu-common-other-z-index-base);
    margin-bottom: -120px;
  }
}

.teaser:has(.u-teaser--overlapped-content) + * {
  z-index: var(--emu-common-other-z-index-layer);
  position: relative;
}

@media (min-width: 1024px) {
  .teaser:has(.u-teaser--overlapped-content-sm) {
    margin-bottom: -80px;
  }

  .teaser:has(.u-teaser--overlapped-content-xs) {
    margin-bottom: -40px;
  }

  .teaser:has(.u-teaser--overlapped-content-lg) {
    margin-bottom: -160px;
  }
}

@media (max-width: 1023px) {
  .mobile-menu-open {
    top: var(--scrollTopPos, 0);
    inline-size: 100%;
    position: fixed;
  }
}

.u-hide {
  display: none !important;
}

.cq-Editable-dom--container .u-hide {
  display: block !important;
}

.u-spaced-paragraphs--sm p {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .u-spaced-paragraphs--sm p {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

.u-spaced-paragraphs--sm p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

.u-spaced-paragraphs p {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

.u-spaced-paragraphs p:last-child {
  margin-bottom: var(--emu-common-spacing-none);
}

.u-flex {
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.u-flex--center-align {
  justify-content: center;
}

.u-flex--right-align {
  justify-content: flex-end;
}

.ami__wrapper {
  position: relative;
}

@media (min-width: 1024px) {
  .ami__wrapper {
    flex-wrap: wrap;
    display: flex;
  }

  .ami__wrapper > .container:has(.ami__content) {
    margin-right: calc(2 * var(--emu-semantic-spacing-horizontal-narrow));
    width: 268px;
  }

  .ami__wrapper > .container:has(.ami__main) {
    flex: 1;
    align-self: center;
  }
}

.ami__wrapper--has-msg .ami__tab-content--has-msg .ami__msg-container {
  display: flex;
}

.ami__wrapper--non-ami .ami__msg--non-ami, .ami__wrapper--no-courses .ami__msg--no-courses, .ami__wrapper--no-events .ami__msg--no-events {
  display: block;
}

.ami__content .image {
  justify-content: center;
  display: flex;
}

@media (min-width: 1024px) {
  .ami__content .image {
    justify-content: flex-start;
  }
}

.ami__content img {
  object-fit: contain;
  object-position: left top;
  max-width: min(400px, 100%);
  height: 300px;
}

@media (min-width: 1024px) {
  .ami__main {
    min-height: 350px;
    display: flex;
  }
}

.cq-Editable-dom--container .ami__main {
  display: block !important;
}

@media (min-width: 1024px) {
  .ami__main .tabs {
    flex: 1;
    min-height: 100%;
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui {
    flex-wrap: wrap;
    min-height: 100%;
    display: flex;
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tablist {
    border-right: var(--emu-common-border-width-medium) solid var(--emu-common-colors-dark-brown-700);
    flex-direction: column;
    min-height: 100%;
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tablist .aaaem-tabs__tab {
    writing-mode: vertical-rl;
    margin: var(--emu-common-spacing-none);
    margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);
    transform: rotate(180deg);
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tablist .aaaem-tabs__tab a {
    padding: var(--emu-component-containers-tabs-navigation-item-padding-right-wide) var(--emu-component-containers-tabs-navigation-item-padding-top-wide) var(--emu-component-containers-tabs-navigation-item-padding-left-wide) var(--emu-component-containers-tabs-navigation-item-padding-bottom-wide);
    border-radius: 0 12px 12px 0;
  }
}

.ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanels {
  border-radius: var(--emu-common-border-radius-xs);
  border-top: var(--emu-common-border-width-medium) solid var(--emu-common-colors-dark-brown-500);
}

@media (min-width: 1024px) {
  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanels {
    border: none;
    flex: 1;
    position: relative;
  }
}

.ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanel {
  opacity: 1;
  transition: all .2s linear .4s;
}

.ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanel[hidden] {
  opacity: 0;
  pointer-events: none;
  transition: all .2s linear;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanel {
    min-height: 100%;
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanel:has(.ami__tab-content--has-msg) {
    align-items: center;
    width: 100%;
    display: flex;
  }

  .ami__tabs.aaaem-tabs.aaaem-tabs__horizontal-ui .aaaem-tabs__tabpanel:has(.ami__tab-content--has-msg) > .container {
    width: 100%;
  }
}

.ami__msg {
  display: none;
}

.ami__msg-container {
  justify-content: center;
  align-items: center;
  min-height: 200px;
  display: none;
}

@media (min-width: 1024px) {
  .ami__msg-container {
    justify-content: flex-start;
  }
}

.cq-Editable-dom--container .ami__msg {
  display: block;
}

.ami__stage {
  margin-top: var(--emu-semantic-spacing-vertical-narrow);
}

.ami__stage.aaaem-container {
  padding-left: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .ami__stage .ami__card:first-child {
    margin-top: var(--emu-common-spacing-none);
  }
}

.ami__card {
  margin-top: var(--emu-semantic-spacing-vertical-narrow);
  background-color: var(--emu-common-colors-light-grey-100);
  border-radius: var(--emu-common-border-radius-xs);
  padding-top: var(--emu-semantic-spacing-vertical-narrow);
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
  padding-left: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
  padding-right: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
}

@media (min-width: 1024px) {
  .ami__card {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
    padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  }

  .ami__card-inner-container {
    display: flex;
  }
}

.ami__card-img-container {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .ami__card-img-container {
    margin-bottom: var(--emu-common-spacing-none);
    margin-right: var(--emu-semantic-spacing-horizontal-narrow);
  }
}

.ami__card-img-container img {
  width: 125px;
  height: auto;
  display: block;
}

@media (min-width: 1024px) {
  .ami__card-content-container {
    flex: 1;
  }
}

.ami__card-content-pretitle-container {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .ami__card-content-pretitle-container {
    margin-bottom: var(--emu-common-spacing-small);
    flex-wrap: wrap;
    display: flex;
  }
}

.ami__card-content-pretitle--type {
  margin-bottom: var(--emu-common-spacing-xs);
  display: flex;
}

@media (min-width: 1024px) {
  .ami__card-content-pretitle--type {
    margin-right: var(--emu-semantic-spacing-horizontal-narrow);
    margin-bottom: var(--emu-common-spacing-none);
  }
}

.ami__card-content-pretitle--type:before {
  content: "";
  background-image: url("resources/images/user-icon.png");
  background-size: cover;
  width: 17px;
  height: 13px;
  margin-right: 12px;
  display: inline-block;
}

.ami__card-content-title {
  margin-bottom: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .ami__card-content-title {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

.ami__card-content-cta .emu-button-v2.emu-button {
  font-family: var(--emu-semantic-font-families-heading);
}

.aaaem-accordion {
  border-bottom: var(--emu-component-lists-accordion-item-border-width-bottom-open) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
}

.aaaem-accordion__title {
  padding-right: var(--emu-common-spacing-medium);
}

.aaaem-accordion__header {
  border-bottom: var(--emu-common-border-width-none);
  border-top: var(--emu-component-lists-accordion-item-border-width-bottom-open) solid var(--emu-component-lists-accordion-item-color-border-bottom-closed-light);
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__button:not(.caret-icon) .aaaem-accordion__icon {
  transform: rotate(45deg);
}

.aaaem-accordion__header.-ba-is-active .aaaem-accordion__button:not(.caret-icon) .aaaem-accordion__icon:before {
  transform: rotate(0);
}

.aaaem-accordion__button.caret-icon .aaaem-accordion__icon:before {
  transform: rotate(45deg)translateX(1px)translateY(-1px);
}

.aaaem-accordion__icon {
  transition-duration: var(--emu-component-lists-accordion-item-header-icon-transition);
}

.aaaem-accordion__item:first-child .aaaem-accordion__header:first-child {
  border-top: var(--emu-common-border-width-none);
}

.ajaxContainer form {
  margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
  width: calc(100% + 2 * var(--emu-semantic-spacing-horizontal-narrow));
}

.ajaxContainer form > div {
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  padding-right: var(--emu-semantic-spacing-horizontal-narrow);
}

.ajaxContainer .emu-form-checkbox, .ajaxContainer .emu-form-radio, .ajaxContainer .emu-form-text, .ajaxContainer .emu-form-textarea, .ajaxContainer .emu-form-dropdown, .ajaxContainer .input-with-custom-text {
  margin-top: var(--emu-common-spacing-none);
}

.article__main h1 {
  font: var(--emu-component-ingredients-title-h1-typography-narrow);
}

@media (min-width: 1024px) {
  .article__main h1 {
    font: var(--emu-component-ingredients-title-h1-typography-wide);
  }
}

.article__main h2 {
  font: var(--emu-component-ingredients-title-h2-typography-narrow);
}

@media (min-width: 1024px) {
  .article__main h2 {
    font: var(--emu-component-ingredients-title-h2-typography-wide);
  }
}

.article__main h3 {
  font: var(--emu-component-ingredients-title-h3-typography-narrow);
}

@media (min-width: 1024px) {
  .article__main h3 {
    font: var(--emu-component-ingredients-title-h3-typography-wide);
  }
}

.article__main h4 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  .article__main h4 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

.article__main h5 {
  font: var(--emu-component-ingredients-title-h5-typography-narrow);
}

@media (min-width: 1024px) {
  .article__main h5 {
    font: var(--emu-component-ingredients-title-h5-typography-wide);
  }
}

.article__main .article-content-container {
  width: 750px;
  max-width: 100%;
}

.article__main div:has( > .u-width--xs) {
  width: 750px;
  max-width: 100%;
}

.article__main div:has( > .u-width--md) {
  width: 750px;
  max-width: 100%;
}

.article__main .article-content-container {
  margin-left: auto;
  margin-right: auto;
}

.article__grid {
  padding-top: var(--emu-common-spacing-none);
  justify-content: center;
  gap: 20px;
  display: grid;
}

@media (min-width: 768px) {
  .article__grid {
    grid-template-columns: repeat(3, 1fr);
    justify-content: flex-start;
  }
}

@media (min-width: 1120px) {
  .article__grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.article__card {
  border-radius: 6px;
  justify-self: center;
  width: 100%;
  max-width: 331px;
  display: none;
  position: relative;
}

@media (min-width: 1024px) {
  .article__card {
    max-width: none;
  }
}

.article__card--visible {
  display: block;
}

.article__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.article__image img {
  object-fit: cover;
  width: 100%;
  height: 190px;
  display: block;
}

.article__image--restricted {
  z-index: var(--emu-common-other-z-index-base);
  position: relative;
}

.article__image--restricted:before {
  content: "";
  pointer-events: none;
  z-index: var(--emu-common-other-z-index-overlay);
  background-color: #00000080;
  background-image: url("resources/images/lock.png");
  background-position: 19px 19px;
  background-repeat: no-repeat;
  position: absolute;
  inset: 0;
}

.article-list .article__filters {
  justify-content: center;
  gap: var(--emu-common-spacing-large);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  .article-list .article__filters {
    flex-direction: row;
  }
}

.article-list .article__filters .emu-form-dropdown {
  flex-direction: column;
  display: flex;
}

.article-list .article__filters .emu-dropdown-menu__button {
  min-width: 200px;
  height: 58px;
}

.article-list .article__filters .emu-dropdown-menu__button svg {
  width: var(--emu-common-sizing-small);
}

.article-list .article__pagination .first-btn, .article-list .article__pagination .last-btn, .article-list .article__pagination .page-counter {
  display: none;
}

.article-list .article__cta--back a:before {
  content: "";
  width: var(--emu-common-sizing-small);
  height: var(--emu-common-sizing-small);
  background-image: url("resources/images/prev-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}

.article-list .article__grid {
  padding-top: var(--emu-common-spacing-large);
  gap: 20px;
}

@media (min-width: 768px) {
  .article-list .article__grid {
    grid-template-columns: repeat(2, 300px);
    justify-content: center;
  }
}

@media (min-width: 1024px) {
  .article-list .article__grid {
    padding-top: var(--emu-common-spacing-xl);
    margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
    margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
    grid-template-columns: repeat(3, 1fr);
    max-width: none;
  }
}

.article-list .article__message {
  min-height: 348px;
  display: block;
}

.article-list .article__message--hidden {
  display: none;
}

.u-author-only {
  position: relative;
  display: none !important;
}

.cq-Editable-dom--container .u-author-only {
  display: block !important;
}

.u-author-only:after {
  content: "";
  border: 5px dashed var(--emu-common-colors-dark-brown-200);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.u-author-only:before {
  content: "ONLY VISIBLE IN AUTHOR MODE";
  padding: var(--emu-common-spacing-small);
  background-color: var(--emu-common-colors-dark-brown-200);
  text-align: center;
  opacity: .75;
  font: var(--emu-semantic-typography-narrow-body-eyebrow);
  text-transform: uppercase;
  width: 100%;
  display: block;
  letter-spacing: .5px !important;
  letter-spacing: 3px !important;
}

@media (min-width: 1024px) {
  .u-author-only:before {
    font: var(--emu-semantic-typography-wide-body-eyebrow);
  }
}

@media (min-width: 768px) {
  .brand-showcase--as-grid .brand-showcase__content-wrapper {
    column-gap: var(--emu-semantic-spacing-horizontal-narrow);
    row-gap: calc(2 * var(--emu-semantic-spacing-horizontal-narrow));
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 1024px) {
  .brand-showcase--as-grid .brand-showcase__content-wrapper:has( > .container:only-child) {
    grid-template-columns: repeat(1, 1fr);
  }

  .brand-showcase--as-grid .brand-showcase__content {
    padding: var(--emu-common-spacing-none);
  }
}

.brand-showcase__content {
  padding-top: var(--emu-semantic-spacing-horizontal-narrow);
  padding-bottom: var(--emu-semantic-spacing-horizontal-narrow);
}

.brand-showcase__eyebrow {
  margin-bottom: var(--emu-common-spacing-small);
}

.brand-showcase__title .cmp-title__text {
  line-height: var(--emu-semantic-font-sizes-narrow-large);
  margin-bottom: 12px;
}

.brand-showcase__desc {
  margin-bottom: var(--emu-common-spacing-medium);
}

.emu-button-v2, .emu-form-button {
  text-transform: uppercase;
  gap: var(--emu-common-spacing-medium);
  align-items: center;
  transition: all .2s linear;
  display: inline-flex;
  letter-spacing: 1.8px !important;
}

[data-component="carousel-v2"].emu-carousel .tns-controls.js-show {
  padding-bottom: var(--emu-common-spacing-small);
  flex-direction: row;
  justify-content: flex-end;
  gap: 70px;
  display: flex;
}

[data-component="carousel-v2"].emu-carousel .emu-carousel__action {
  position: relative;
  transform: none;
}

[data-component="carousel-v2"].emu-carousel .tns-liveregion {
  left: unset;
  font: var(--emu-semantic-typography-narrow-body-xs);
  text-transform: uppercase;
  position: absolute;
  top: 3px;
  right: 34px;
  letter-spacing: 3px !important;
}

[data-component="carousel-v2"].emu-carousel .tns-liveregion:not(.can-show) {
  display: none;
}

@media (min-width: 1024px) {
  [data-component="carousel-v2"].emu-carousel .tns-liveregion {
    font: var(--emu-semantic-typography-wide-body-xs);
  }
}

@media (min-width: 1024px) {
  .contact-us {
    gap: calc(1.5 * var(--emu-semantic-spacing-horizontal-wide));
    justify-content: center;
    display: flex;
  }
}

.cq-Editable-dom--container .contact-us {
  display: block !important;
}

.cq-Editable-dom--container .contact-us > .container {
  margin-bottom: var(--emu-semantic-spacing-horizontal-wide);
}

@media (min-width: 1024px) {
  .contact-us > .container {
    max-width: 380px;
  }

  .contact-us > .container:first-child .contact-us__section {
    padding-left: var(--emu-common-spacing-none);
    border-left: none;
  }
}

.contact-us__section {
  border-left: var(--emu-common-border-width-thin) solid var(--emu-common-colors-medium-brown-500);
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
}

.contact-us__text a {
  word-break: break-all;
  display: inline-block;
}

.country-selector {
  opacity: 0;
}

.country-selector--show {
  opacity: 1;
  transition: all var(--emu-common-other-time-transition-base);
}

.country-selector__main-content {
  opacity: 0;
  transition: all var(--emu-common-other-time-transition-base);
}

.country-selector__main-content--show {
  opacity: 1;
}

@media (min-width: 1024px) {
  .country-selector__main-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.cq-Editable-dom--container .country-selector__main-content {
  opacity: 1;
  position: static;
}

.country-selector__main-content-container {
  position: relative;
}

.country-selector__title .cmp-title__text {
  word-wrap: break-word;
}

@media (max-width: 1023px) {
  .country-selector__title .cmp-title__text {
    font-size: var(--emu-semantic-font-sizes-narrow-xl);
    line-height: var(--emu-semantic-line-heights-narrow-xxl);
  }
}

@media (min-width: 1024px) {
  .country-selector div:has( > .u-width--xs) {
    width: 100%;
  }

  .country-selector__image img {
    object-fit: cover;
  }
}

.country-selector__form {
  max-width: 400px;
}

.country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__button, .country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__item, .country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__group {
  border-color: var(--emu-common-colors-light-grey-400);
  font-size: var(--emu-semantic-font-sizes-narrow-small);
  letter-spacing: .5px !important;
}

.country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__button {
  background-color: var(--emu-common-colors-white);
}

.country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__item:first-child[data-value="--"] {
  display: none;
}

.country-selector__dropdown.emu-form-dropdown .emu-dropdown-menu__group {
  max-height: calc(.5 * var(--emu-common-sizing-xxxl));
  transition-duration: .5s;
  overflow: auto;
}

.country-selector__dropdown.emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__group {
  transform: translateY(0);
}

.faq__container--show-results-section .faq__search-results, .faq__container--show-results-section .faq__search-results-stage {
  display: block;
}

.faq__container--show-results-section .faq__search-main .button .faq__back-to-support.emu-button {
  display: inline-flex;
}

.faq__container--show-results-section .faq__stage, .faq__container--show-no-results .faq__search-stage {
  display: none;
}

.faq__container--show-no-results .faq__search-results-no-results-container {
  display: block;
}

.faq__search-main .button .faq__back-to-support.emu-button {
  display: none;
}

.faq__search-main i {
  font-style: normal;
}

.faq__search-wrapper > .container {
  flex: 1;
  width: 100%;
  min-width: 100px;
  max-width: 232px;
}

.faq__search-wrapper .emu-form-text {
  margin: var(--emu-common-spacing-none);
}

.faq__search-field input {
  padding-top: var(--emu-semantic-spacing-half-line-height-narrow);
  padding-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
}

@media (min-width: 1024px) {
  .faq__search-field input {
    padding-top: var(--emu-common-spacing-small);
    padding-bottom: var(--emu-common-spacing-small);
  }
}

.faq__search-results {
  display: none;
}

@media (min-width: 1024px) {
  .faq__search-results {
    max-width: calc(100% - 232px);
  }
}

.faq__search-results-no-results-container {
  text-align: center;
  display: none;
}

.faq__search-results-no-results-container img {
  display: inline-block;
}

.faq__search-results-stage {
  display: none;
}

.faq__search-results-stage .aaaem-accordion dt:first-child .aaaem-accordion__header {
  border-top: var(--emu-common-border-width-none);
}

.faq__title {
  padding-bottom: var(--emu-common-spacing-medium);
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
}

@media (min-width: 1024px) {
  .faq__title {
    max-width: 232px;
  }
}

.faq__stage {
  flex-wrap: wrap;
  display: flex;
}

@media (min-width: 1024px) {
  .faq__stage {
    gap: var(--emu-common-spacing-xl);
    flex-wrap: nowrap;
  }
}

.faq__stage > .container {
  width: 100%;
}

@media (min-width: 1024px) {
  .faq__stage > .container:has(.faq__sidebar) {
    max-width: 232px;
  }

  .faq__stage > .container:has(.faq__tabs-container) {
    flex: 1;
  }

  .faq__sidebar .emu-form-dropdown .emu-dropdown-menu__button {
    display: none;
  }
}

.faq__sidebar .emu-form-dropdown .emu-dropdown-menu__group {
  transition: none;
  width: 100% !important;
}

@media (min-width: 1024px) {
  .faq__sidebar .emu-form-dropdown.emu-form-dropdown, .faq__sidebar .emu-form-dropdown.emu-form-dropdown.js-hide-dropdown {
    margin: var(--emu-common-spacing-none);
  }

  .faq__sidebar .emu-form-dropdown.emu-form-dropdown .emu-dropdown-menu__group, .faq__sidebar .emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__group {
    opacity: 1;
    pointer-events: initial;
    border: var(--emu-common-border-width-none);
    margin-top: var(--emu-common-spacing-none);
    height: auto;
    position: static;
    transform: translateY(0);
    width: 100% !important;
  }

  .faq__sidebar .emu-form-dropdown.emu-form-dropdown .emu-dropdown-menu__item:first-child, .faq__sidebar .emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__item:first-child {
    border-top: var(--emu-common-border-width-none);
  }

  .faq__sidebar .emu-form-dropdown.emu-form-dropdown .emu-dropdown-menu__item:last-child, .faq__sidebar .emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__item:last-child {
    border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  }
}

.faq__tabs-container .aaaem-tabs__tablist {
  display: none;
}

.faq__tabs-container .aaaem-tabs__tabpanel .aaaem-container {
  padding: var(--emu-common-spacing-none);
}

.faq__tabs-container .aaaem-tabs__vertical-ui {
  display: block;
}

.faq__loader {
  z-index: calc(var(--emu-common-other-z-index-cookie-banner)  + 1);
  position: fixed;
  inset: 0;
}

.cq-Editable-dom--container .faq__search-results, .cq-Editable-dom--container .faq__search-results-stage, .cq-Editable-dom--container .faq__search-stage, .cq-Editable-dom--container .faq__search-results-no-results-container {
  display: block !important;
}

.cq-Editable-dom--container .faq__back-to-support {
  display: inline-flex !important;
}

.cq-Editable-dom--container .faq__stage {
  display: flex !important;
}

@media (min-width: 1120px) {
  .footer > .container > .aaaem-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
    padding-bottom: var(--emu-common-spacing-xl);
  }
}

.footer__main-wrapper {
  flex-direction: column;
  gap: 49px;
  display: flex;
}

@media (min-width: 1120px) {
  .footer__main-wrapper {
    gap: 33px;
  }
}

.footer__top-section {
  flex-direction: column;
  gap: 49px;
  display: flex;
}

@media (min-width: 1120px) {
  .footer__top-section {
    justify-content: space-between;
    gap: var(--emu-semantic-spacing-none);
    flex-direction: row;
  }
}

.footer__links {
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1120px) {
  .footer__links {
    gap: 33px;
  }
}

.footer__logo a {
  display: inline-block;
}

.footer__logo img, .footer__logo svg {
  width: 147px;
  height: auto;
  display: block;
}

.footer__social-links {
  align-items: center;
  gap: 12px;
  display: flex;
}

.footer__social-links svg, .footer__social-links img {
  width: 24px;
  display: block;
}

.footer__social-links a {
  display: block;
}

.footer__nav ul {
  margin: var(--emu-common-spacing-none);
  padding: var(--emu-common-sizing-none);
  list-style: none;
}

.footer__nav ul li:not(:last-child) {
  margin-bottom: 12px;
}

.footer__nav ul a {
  display: inline-block;
}

.footer__copyright {
  letter-spacing: .5px !important;
}

.footer__copyright a[href*="tel:"], .footer__copyright a[href*="tel:"]:hover, .footer__copyright a[href*="tel:"]:focus, .footer__copyright a[href*="tel:"]:active {
  color: inherit;
  text-decoration: none;
}

.emu-form-text__label, .emu-form-textarea__label, .emu-form-dropdown__label, .emu-form-checkbox__label, .emu-form-radio__label {
  margin-left: var(--emu-common-spacing-none);
  margin-top: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
  cursor: pointer;
}

.emu-form-checkbox__label, .emu-form-radio__label {
  margin-bottom: var(--emu-common-spacing-none);
}

.emu-form-text__label, .emu-form-textarea__label, .emu-form-dropdown__label {
  font: var(--emu-semantic-typography-narrow-body-cta);
  display: inline-block;
  letter-spacing: 1.8px !important;
}

@media (min-width: 1024px) {
  .emu-form-text__label, .emu-form-textarea__label, .emu-form-dropdown__label {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

.options .emu-form-radio, .options .emu-form-checkbox {
  column-gap: var(--emu-semantic-spacing-horizontal-narrow);
  row-gap: var(--emu-common-spacing-medium);
  flex-wrap: wrap;
  display: flex;
}

.options .emu-form-radio .emu-form-checkbox__label, .options .emu-form-radio .emu-form-radio__label, .options .emu-form-checkbox .emu-form-checkbox__label, .options .emu-form-checkbox .emu-form-radio__label {
  gap: var(--emu-common-spacing-none);
  letter-spacing: .5px !important;
}

.options .emu-form-radio .emu-form-checkbox__label span, .options .emu-form-radio .emu-form-radio__label span, .options .emu-form-checkbox .emu-form-checkbox__label span, .options .emu-form-checkbox .emu-form-radio__label span {
  margin-left: var(--emu-component-ingredients-checkbox-input-label-gap-narrow);
}

.options .emu-form-radio:not([data-layout="stacked"]), .options .emu-form-checkbox:not([data-layout="stacked"]) {
  gap: var(--emu-common-spacing-none);
}

.options .emu-form-radio:not([data-layout="stacked"]) label, .options .emu-form-checkbox:not([data-layout="stacked"]) label {
  margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);
}

.options .emu-form-radio:not([data-layout="stacked"]) label:not(:last-child), .options .emu-form-checkbox:not([data-layout="stacked"]) label:not(:last-child) {
  margin-right: var(--emu-semantic-spacing-horizontal-narrow);
}

.options .emu-form-radio:not([data-layout="stacked"]) label:only-of-type, .options .emu-form-checkbox:not([data-layout="stacked"]) label:only-of-type {
  margin-bottom: var(--emu-common-spacing-none);
  margin-right: var(--emu-common-spacing-none);
}

.options .emu-form-radio[data-layout="stacked"], .options .emu-form-checkbox[data-layout="stacked"] {
  gap: var(--emu-common-spacing-none);
  flex-direction: column;
}

.options .emu-form-radio[data-layout="stacked"] .emu-form-checkbox__label, .options .emu-form-radio[data-layout="stacked"] .emu-form-radio__label, .options .emu-form-checkbox[data-layout="stacked"] .emu-form-checkbox__label, .options .emu-form-checkbox[data-layout="stacked"] .emu-form-radio__label {
  padding: var(--emu-semantic-spacing-half-line-height-narrow) var(--emu-common-spacing-medium);
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-light-grey-400);
  display: flex;
}

.options .emu-form-radio[data-layout="stacked"] .emu-form-checkbox__label:not(:last-of-type), .options .emu-form-radio[data-layout="stacked"] .emu-form-radio__label:not(:last-of-type), .options .emu-form-checkbox[data-layout="stacked"] .emu-form-checkbox__label:not(:last-of-type), .options .emu-form-checkbox[data-layout="stacked"] .emu-form-radio__label:not(:last-of-type) {
  margin-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
}

.options .emu-form-radio[data-customize] .emu-form-radio__input {
  cursor: pointer;
  margin-bottom: var(--emu-common-spacing-none);
  min-width: var(--emu-component-ingredients-radio-input-control-size-all-narrow);
}

@media (min-width: 1024px) {
  .options .emu-form-radio[data-customize] .emu-form-radio__input {
    min-width: var(--emu-component-ingredients-radio-input-control-size-all-wide);
  }
}

.options .emu-form-radio[data-customize] .emu-form-radio__input:checked:hover {
  background-color: var(--emu-component-ingredients-radio-input-control-color-background-checked-light);
  border-color: var(--emu-component-ingredients-radio-input-control-color-border-checked-light);
}

.options .emu-form-radio[data-customize] .emu-form-radio__input:before {
  content: "";
  box-shadow: inset 1em 1em var(--emu-component-ingredients-checkbox-input-icon-color-fill-checked-light);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0, 43% 62%);
  transition: all var(--emu-common-other-time-transition-short) linear;
  transform-origin: center;
  background-color: canvastext;
  width: 100%;
  height: 100%;
  display: block;
  transform: scale(0)rotate(60deg);
}

.options .emu-form-radio[data-customize] .emu-form-radio__input:checked:before {
  transform: scale(.75)rotate(9deg);
}

.options .emu-form-radio[data-customize] .emu-form-radio__label {
  align-items: center;
}

.options .emu-form-checkbox[data-customize] .emu-form-checkbox__input {
  margin-bottom: var(--emu-common-spacing-none);
  cursor: pointer;
  min-width: var(--emu-component-ingredients-checkbox-input-control-size-all-narrow);
}

@media (min-width: 1024px) {
  .options .emu-form-checkbox[data-customize] .emu-form-checkbox__input {
    min-width: var(--emu-component-ingredients-checkbox-input-control-size-all-wide);
  }
}

.options .emu-form-checkbox[data-customize] .emu-form-checkbox__input:before {
  transition: all var(--emu-common-other-time-transition-short) linear;
  transform: scale(0)rotate(60deg);
}

.options .emu-form-checkbox[data-customize] .emu-form-checkbox__input:checked:before {
  transform: scale(.75)rotate(9deg);
}

.options .emu-form-checkbox[data-customize] .emu-form-checkbox__input:checked:hover {
  background-color: var(--emu-component-ingredients-checkbox-input-control-color-background-checked-light);
  border-color: var(--emu-component-ingredients-checkbox-input-control-color-border-checked-light);
}

.options .emu-form-checkbox[data-customize] .emu-form-checkbox__label {
  align-items: center;
}

[data-component="form-text-input"].emu-form-text input, [data-component="form-text-textarea"].emu-form-textarea textarea, .emu-form-dropdown .emu-dropdown-menu__button, .emu-form-dropdown .emu-dropdown-menu__item {
  font: var(--emu-semantic-typography-narrow-body-cta);
  letter-spacing: 1.8px !important;
}

@media (min-width: 1024px) {
  [data-component="form-text-input"].emu-form-text input, [data-component="form-text-textarea"].emu-form-textarea textarea, .emu-form-dropdown .emu-dropdown-menu__button, .emu-form-dropdown .emu-dropdown-menu__item {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

[data-component="form-text-textarea"].emu-form-textarea .emu-form-textarea__container {
  width: 100%;
}

[data-component="form-text-textarea"].emu-form-textarea textarea {
  resize: none;
  width: 100%;
}

[data-component="form-text-textarea"].emu-form-textarea textarea::placeholder {
  color: inherit;
}

[data-component="form-text-input"].emu-form-text input {
  width: 100%;
}

[data-component="form-text-input"].emu-form-text input::placeholder {
  color: inherit;
}

.emu-form-dropdown {
  position: relative;
}

.emu-form-dropdown [data-disabled] .emu-dropdown-menu__button {
  background-color: var(--emu-common-colors-transparent);
}

.emu-form-dropdown:not(.emu-form-dropdown__outline) .emu-dropdown-menu__button {
  background-color: var(--emu-component-ingredients-select-input-option-color-background-hover-filled-light);
}

.emu-form-dropdown:not(.emu-form-dropdown__outline) .emu-dropdown-menu__group {
  box-shadow: 0 4px 4px #00000040;
}

.emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__group {
  opacity: 0;
  pointer-events: none;
  height: auto;
  transform: translateY(40px);
}

.emu-form-dropdown.emu-form-dropdown.js-hide-dropdown .emu-dropdown-menu__item {
  visibility: visible;
}

.emu-form-dropdown.emu-form-dropdown:not(.js-hide-dropdown) .emu-dropdown-menu__group {
  opacity: 1;
  pointer-events: initial;
  z-index: var(--emu-common-other-z-index-overlay);
  transform: translateY(0);
}

.emu-form-dropdown .emu-dropdown-menu__group {
  transition: all var(--emu-common-other-time-transition-short) linear;
  max-width: 100%;
  width: 100% !important;
}

.emu-form-dropdown .emu-dropdown-menu__button {
  text-align: left;
  width: 100% !important;
}

.emu-form-dropdown .emu-dropdown-menu__button svg {
  transition: all var(--emu-common-other-time-transition-short) linear;
  width: 20px;
  height: auto;
}

.emu-form-dropdown .emu-dropdown-menu__item {
  visibility: visible;
}

.input-with-custom-text {
  margin-bottom: var(--emu-common-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  display: inline-flex;
  position: relative;
}

.input-with-custom-text [data-ref="error-message"].js-show, .input-with-custom-text label span {
  display: none;
}

.input-with-custom-text label {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.input-with-custom-text__label {
  z-index: var(--emu-common-other-z-index-layer);
  padding-left: calc(var(--emu-component-ingredients-checkbox-input-control-size-all-narrow)  + var(--emu-component-ingredients-checkbox-input-label-gap-narrow));
  margin-top: calc(-1 * var(--emu-component-ingredients-checkbox-input-control-size-all-narrow));
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
  cursor: pointer;
  position: relative;
  letter-spacing: .5px !important;
}

.input-with-custom-text__label > * {
  color: inherit;
}

.input-with-custom-text__label--has-error {
  color: var(--emu-component-ingredients-checkbox-input-error-message-text-color-light);
}

@media (min-width: 1024px) {
  .input-with-custom-text__label {
    padding-left: calc(var(--emu-component-ingredients-checkbox-input-control-size-all-wide)  + var(--emu-component-ingredients-checkbox-input-label-gap-wide));
    margin-top: calc(-1 * var(--emu-component-ingredients-checkbox-input-control-size-all-wide));
  }
}

.cq-Editable-dom--container .input-with-custom-text__label {
  margin-top: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-none);
}

.input-with-custom-text__msg {
  margin-top: calc(-1 * var(--emu-component-ingredients-checkbox-input-control-size-all-narrow));
  margin-bottom: var(--emu-semantic-spacing-vertical-wide);
}

@media (min-width: 1024px) {
  .input-with-custom-text__msg {
    margin-top: calc(-1 * var(--emu-component-ingredients-checkbox-input-control-size-all-wide));
  }
}

.cq-Editable-dom--container .input-with-custom-text__msg {
  margin-top: var(--emu-common-spacing-none);
  display: block !important;
}

[data-ref="error-message"], .input-with-custom-text__msg {
  font: var(--emu-semantic-typography-narrow-body-cta);
  color: var(--emu-component-ingredients-checkbox-input-error-message-text-color-light);
  letter-spacing: .5px !important;
}

@media (min-width: 1024px) {
  [data-ref="error-message"], .input-with-custom-text__msg {
    font: var(--emu-semantic-typography-wide-body-cta);
  }
}

[data-ref="error-message"] p, .input-with-custom-text__msg p {
  font: inherit;
  color: inherit;
  letter-spacing: inherit;
}

.options .emu-form-radio.js-has-error, .options .emu-form-checkbox.js-has-error {
  flex-wrap: wrap;
}

.options .emu-form-radio.js-has-error [data-ref="error-message"], .options .emu-form-checkbox.js-has-error [data-ref="error-message"] {
  width: 100%;
}

.emu-form__error-message {
  padding: var(--emu-component-ingredients-text-input-text-padding-wide);
  border: var(--emu-component-ingredients-text-input-border-width-wide) solid var(--emu-component-ingredients-text-input-color-border-error-light);
  color: var(--emu-component-ingredients-text-input-label-text-color-error-light);
  margin-left: var(--emu-semantic-spacing-horizontal-narrow);
  margin-right: var(--emu-semantic-spacing-horizontal-narrow);
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

.modal-content .emu-form__success-message h1, .modal-content .emu-form__success-message h2, .modal-content .emu-form__success-message h3 {
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

@media (min-width: 1024px) {
  .modal-content .emu-form__success-message h1, .modal-content .emu-form__success-message h2, .modal-content .emu-form__success-message h3 {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

.emu-form__success-message {
  opacity: 0;
  pointer-events: none;
  max-height: 0;
  transition: all .2s linear;
  display: block;
}

.emu-form.u-form--with-success-animation > :not(.emu-form__success-message) {
  opacity: 1;
  max-height: 400px;
  transition: all .2s linear;
}

.cq-Editable-dom--container .emu-form.u-form--with-success-animation > :not(.emu-form__success-message) {
  max-height: none;
}

.emu-form.u-form--with-success-animation .emu-form__loader {
  max-height: 100%;
}

.form--show-success-msg .emu-form.u-form--with-success-animation > :not(.emu-form__success-message) {
  opacity: 0;
  max-height: 0;
  transition: all .2s;
  overflow: hidden;
}

.form--show-success-msg .emu-form.u-form--with-success-animation .emu-form__success-message {
  opacity: 1;
  pointer-events: initial;
  max-height: 400px;
  transition: all .2s linear .2s;
}

.u-form-element--disabled label {
  pointer-events: none;
  color: var(--emu-component-ingredients-text-input-label-text-color-disabled-light);
}

.u-form-element--disabled .emu-form-text__input {
  background-color: var(--emu-component-ingredients-text-input-color-background-disabled-light);
  border-color: var(--emu-component-ingredients-text-input-color-border-disabled-light);
  color: var(--emu-component-ingredients-text-input-text-color-disabled-light);
  pointer-events: none;
}

.u-form-element--disabled .emu-form-textarea__textarea {
  background-color: var(--emu-component-ingredients-textarea-input-color-background-disabled-light);
  border-color: var(--emu-component-ingredients-textarea-input-color-border-disabled-light);
  color: var(--emu-component-ingredients-textarea-input-text-color-disabled-light);
  pointer-events: none;
}

.u-form-element--disabled .emu-dropdown-menu__button, .u-form-element--disabled .emu-dropdown-menu__item {
  pointer-events: none;
  background-color: var(--emu-component-ingredients-select-input-option-color-background-disabled-filled-light);
  border-color: var(--emu-component-ingredients-select-input-color-border-disabled-filled-light);
  color: var(--emu-component-ingredients-select-input-option-text-color-filled-disabled-light);
}

.u-form-element--disabled .emu-form-radio__input {
  pointer-events: none;
  background-color: var(--emu-component-ingredients-radio-input-control-color-background-disabled-light);
  border-color: var(--emu-component-ingredients-radio-input-control-color-border-disabled-light);
  pointer-events: none;
}

.u-form-element--disabled .emu-form-radio__input + span {
  color: var(--emu-component-ingredients-radio-input-label-text-color-disabled-light);
}

.u-form-element--disabled .emu-form-checkbox__input {
  background-color: var(--emu-component-ingredients-checkbox-input-control-color-background-disabled-light);
  border-color: var(--emu-component-ingredients-checkbox-input-control-color-border-disabled-light);
  pointer-events: none;
}

.u-form-element--disabled .emu-form-checkbox__input + span {
  color: var(--emu-component-ingredients-checkbox-input-label-text-color-disabled-light);
}

header {
  --eyebrow-header-height: 67px;
}

@media (min-width: 1024px) {
  header {
    --eyebrow-header-height: 84px;
  }
}

header.experiencefragment {
  z-index: var(--emu-common-other-z-index-header);
  width: 100%;
  position: sticky;
  top: 0;
}

header.header {
  position: relative;
}

.header__desktop-navbar {
  display: none;
}

.header__logo img {
  width: auto;
  max-width: 268px;
  height: auto;
}

@media (min-width: 1024px) {
  .header__logo img {
    max-width: 240px;
  }
}

@media (min-width: 1200px) {
  .header__logo img {
    max-width: 268px;
  }
}

.header__mobile-navbar > .container > .aaaem-container {
  padding-top: 12px;
  padding-bottom: 12px;
}

.header__mobile-navbar-inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.header__mobile-navbar-inner > .image {
  max-width: calc(100% - 48px);
}

.header__mobile-navbar-inner > .image img {
  width: 100%;
}

.header__mobile-navbar-trigger-container {
  position: relative;
}

.header__mobile-navbar-trigger-container .button {
  display: flex;
  position: static;
}

.header__mobile-navbar-trigger--close {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.header__mobile-navbar-trigger--close img {
  width: var(--emu-component-layers-modal-close-size-width-narrow);
  height: var(--emu-component-layers-modal-close-size-height-narrow);
}

.header__mobile-navbar-trigger--open:after {
  content: "";
  background-color: var(--emu-semantic-colors-surface-modal-backdrop);
  opacity: 0;
  pointer-events: none;
  width: 100%;
  height: calc(100vh - 67px);
  position: fixed;
  top: 67px;
  left: 0;
}

.header__navs-container {
  opacity: 0;
  pointer-events: none;
  transition: all var(--emu-common-other-time-duration-instant) ease-out;
  max-height: calc(100vh - var(--eyebrow-header-height));
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  overflow: hidden;
}

@media (min-width: 1024px) {
  .header__navs-container {
    pointer-events: initial;
    opacity: 1;
    overflow: initial;
    transition: none;
    position: relative;
    transform: none;
  }
}

.header__navs-container > .container {
  transition: inherit;
  transform: translateX(100%);
}

@media (min-width: 1024px) {
  .header__navs-container > .container {
    transform: none;
  }
}

.header__navs-container .u-container--large > .container > .aaaem-container {
  padding-top: var(--emu-semantic-spacing-horizontal-narrow);
  padding-bottom: var(--emu-semantic-spacing-horizontal-narrow);
}

@media (min-width: 1024px) {
  .header__navs-container .u-container--large > .container > .aaaem-container {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .header__navs-container .u-container--large > .container > .aaaem-container.header__eyebrow-nav-inner {
    padding-top: 19px;
    padding-bottom: 19px;
  }
}

.emu-navigation__content-wrapper .header__eyebrow-nav > ul > li:last-child:hover a:after {
  transform: scaleX(1)translateX(10px);
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper .header__eyebrow-nav > ul > li:last-child a {
    padding-right: var(--emu-common-spacing-none);
  }

  .header__eyebrow-nav-inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .header__eyebrow-nav-inner > .image {
    flex-shrink: 0;
    min-width: 268px;
  }

  .header__eyebrow-nav--desktop .emu-navigation__menu--level-0 {
    gap: var(--emu-common-spacing-none);
  }

  .header__eyebrow-nav--desktop .emu-navigation__menu--level-0 li a {
    padding-left: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
    padding-right: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
  }
}

@media (min-width: 1200px) {
  .header__eyebrow-nav--desktop .emu-navigation__menu--level-0 {
    gap: var(--emu-semantic-spacing-horizontal-narrow);
  }

  .header__eyebrow-nav--desktop .emu-navigation__menu--level-0 li a {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
    padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  }
}

@media (min-width: 1024px) {
  .header__main-nav-container {
    transition: all var(--emu-common-other-time-duration-instant);
    border-bottom: var(--emu-common-border-width-thin) solid #0000;
    background-color: #ffffff80;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .header__main-nav-container > .container > .aaaem-container {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .header__main-nav-inner {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.header .header__cta-container {
  gap: var(--emu-common-spacing-large);
  margin-top: var(--emu-common-spacing-medium);
  display: flex;
}

@media (min-width: 1024px) {
  .header .header__cta-container {
    margin-top: var(--emu-common-spacing-none);
  }
}

#header-main-wrapper.js-toggle-on .header__mobile-navbar-trigger--open img, #header-main-wrapper-loggedin.js-toggle-on .header__mobile-navbar-trigger--open img {
  opacity: 0;
}

#header-main-wrapper.js-toggle-on .header__mobile-navbar-trigger--close, #header-main-wrapper-loggedin.js-toggle-on .header__mobile-navbar-trigger--close {
  opacity: 1;
}

#header-main-wrapper.js-toggle-on .header__navs-container, #header-main-wrapper-loggedin.js-toggle-on .header__navs-container {
  opacity: 1;
  pointer-events: initial;
}

#header-main-wrapper.js-toggle-on .header__navs-container > .container, #header-main-wrapper-loggedin.js-toggle-on .header__navs-container > .container {
  transform: translateX(0);
}

@media (max-width: 1023px) {
  .mobile-menu-open header.experiencefragment .header__mobile-navbar-trigger--open:after {
    opacity: 1;
    pointer-events: initial;
  }

  .mobile-menu-open .header__navs-container {
    overflow-y: auto;
  }
}

@media (min-width: 1024px) {
  .has-sticky-header .header__main-nav-container {
    background-color: var(--emu-common-colors-white);
    border-bottom-color: #0000001a;
  }
}

.emu-navigation__content-wrapper {
  position: static;
}

.emu-navigation__content-wrapper a sup, .emu-navigation__content-wrapper span sup {
  font-size: 80%;
  top: 1px;
}

.emu-navigation__content-wrapper ul {
  margin-top: var(--emu-common-spacing-none);
  margin-bottom: var(--emu-common-spacing-none);
}

.emu-navigation__content-wrapper nav > ul {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav > ul {
    gap: var(--emu-semantic-spacing-horizontal-narrow);
    flex-flow: wrap;
    align-items: center;
  }

  .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent:hover > ul {
    opacity: 1;
    max-height: 500px;
    left: 0;
  }

  .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent:hover > ul > li a, .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item-parent:hover > ul > li span {
    pointer-events: initial;
  }

  .emu-navigation__content-wrapper nav > ul > li:hover > a:after, .emu-navigation__content-wrapper nav > ul > li:hover > span:after, .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item--active > a:after, .emu-navigation__content-wrapper nav > ul > li.emu-navigation__item--active > span:after {
    transform-origin: 0 100%;
    transform: scaleX(1)translateX(0);
  }
}

.emu-navigation__content-wrapper nav > ul > li > a, .emu-navigation__content-wrapper nav > ul > li > span {
  position: relative;
}

.emu-navigation__content-wrapper nav > ul > li > a:after, .emu-navigation__content-wrapper nav > ul > li > span:after {
  content: "";
  height: var(--emu-common-border-width-thin);
  transform-origin: 100%;
  transition: transform var(--emu-common-other-time-transition-short) ease-out;
  background-color: currentColor;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scaleX(0)translateX(-20px);
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav > ul li.emu-navigation__item.emu-navigation__item-parent ul.emu-navigation__menu--level-1 {
    position: absolute;
  }
}

.emu-navigation__content-wrapper nav > ul li ul {
  margin-left: var(--emu-semantic-spacing-horizontal-narrow);
  position: static;
}

@media (min-width: 1024px) {
  .emu-navigation__content-wrapper nav > ul li ul.emu-navigation__menu--level-1 {
    margin-left: var(--emu-common-spacing-none);
    min-width: 300px;
    top: 100%;
    left: 0;
  }
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent {
  position: relative;
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.js-open--mobile > ul, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.emu-navigation__item-parent--active > ul {
  opacity: 1;
  max-height: 500px;
  left: 0;
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.js-open--mobile > ul > li a, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.js-open--mobile > ul > li span, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.emu-navigation__item-parent--active > ul > li a, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.emu-navigation__item-parent--active > ul > li span {
  pointer-events: initial;
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.js-open--mobile > ul, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent.emu-navigation__item-parent--active > ul {
  position: relative;
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent > ul {
  max-height: var(--emu-common-sizing-none);
  transition: all var(--emu-common-other-time-duration-instant) linear;
  opacity: 0;
  display: block;
  left: -22px;
  overflow: hidden;
}

.emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent > ul a, .emu-navigation__content-wrapper nav > ul li.emu-navigation__item-parent > ul span {
  pointer-events: none;
}

.knowledge-hub__grid {
  justify-content: center;
  gap: var(--emu-semantic-spacing-horizontal-wide);
  display: grid;
}

@media (min-width: 1024px) {
  .knowledge-hub__grid {
    justify-content: space-between;
    gap: var(--emu-semantic-spacing-horizontal-narrow);
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1120px) {
  .knowledge-hub__grid {
    gap: var(--emu-semantic-spacing-horizontal-wide);
  }
}

.knowledge-hub__grid > .container {
  max-width: 450px;
}

@media (min-width: 1024px) {
  .knowledge-hub__grid > .container {
    max-width: 100%;
  }
}

.knowledge-hub__card {
  position: relative;
}

.knowledge-hub__image img {
  object-fit: cover;
  width: 100%;
  min-height: 412px;
}

.knowledge-hub__card-text {
  bottom: var(--emu-semantic-spacing-horizontal-narrow);
  border-radius: var(--emu-common-border-radius-xs);
  margin-left: var(--emu-semantic-spacing-horizontal-narrow);
  margin-right: var(--emu-semantic-spacing-horizontal-narrow);
  background: #fffc;
  max-width: 100%;
  position: absolute;
}

.cq-Editable-dom--container .knowledge-hub__card-text {
  position: static;
}

@media (min-width: 768px) {
  .emu-language-nav[data-layout="pipes"] {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
    padding-right: var(--emu-semantic-spacing-horizontal-narrow);
  }
}

@media (min-width: 1024px) {
  .emu-language-nav[data-layout="pipes"] {
    padding: var(--emu-common-spacing-none);
  }
}

@media (min-width: 1200px) {
  .emu-language-nav[data-layout="pipes"] {
    margin-right: var(--emu-semantic-spacing-horizontal-wide);
  }
}

.emu-language-nav[data-layout="pipes"] .emu-language-nav__item {
  font-size: var(--emu-semantic-font-sizes-narrow-xs);
  line-height: var(--emu-semantic-line-heights-narrow-medium);
  position: relative;
  letter-spacing: 1.8px !important;
}

.emu-language-nav[data-layout="pipes"] .emu-language-nav__item:after {
  content: "";
  width: var(--emu-common-sizing-xxs);
  background-color: var(--emu-common-colors-white);
  height: 45%;
  position: absolute;
  top: 28%;
  right: 0;
}

.emu-language-nav[data-layout="pipes"] .emu-language-nav__item:last-of-type:after {
  content: none;
}

.emu-language-nav[data-layout="pipes"] .emu-language-nav__item--active a.emu-language-nav__item-link {
  font-weight: var(--emu-semantic-font-weight-bold);
}

.emu-language-nav[data-layout="pipes"] .emu-language-nav__item--active a.emu-language-nav__item-link:after {
  content: none;
}

.loader, .emu-form__loader {
  left: var(--emu-semantic-spacing-none);
  top: var(--emu-semantic-spacing-none);
  z-index: var(--emu-common-other-z-index-layer);
  background-color: #ffffffb3;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
}

.loader:after, .emu-form__loader:after {
  content: "";
  height: var(--emu-common-sizing-large);
  width: var(--emu-common-sizing-large);
  background-image: url("resources/images/spinner.gif");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

:is(.loader--active, .loader:has(.loader-wrapper svg), .emu-form__loader--active, .emu-form__loader:has(.loader-wrapper svg)) {
  display: block;
}

.cq-Editable-dom--container .loader, .cq-Editable-dom--container .emu-form__loader {
  display: none !important;
}

.emu-form__loader svg {
  display: none;
}

.logo-carousel {
  min-height: 50px;
}

.logo-carousel img {
  object-fit: contain;
  object-position: center;
  width: auto;
  height: auto;
  margin: auto;
}

[data-component="modal"] .modal-wrapper {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all var(--emu-common-other-time-transition-short) linear;
  transition-delay: var(--emu-common-other-time-transition-short);
  align-content: center;
  display: block;
}

.cq-Editable-dom--container [data-component="modal"] .modal-wrapper {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
  inset: unset;
  align-content: initial;
  height: auto;
  max-height: none;
  transition-delay: 0s;
}

[data-component="modal"] .modal-wrapper .modal-content {
  transition: all var(--emu-common-other-time-transition-short) linear;
  opacity: 0;
  transition-delay: 0s;
  transform: translateY(-80px);
}

.cq-Editable-dom--container [data-component="modal"] .modal-wrapper .modal-content {
  opacity: 1;
  transition-delay: 0s;
  transform: translateY(0);
}

[data-component="modal"] .modal-wrapper.is-open {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
  transition-delay: 0s;
}

[data-component="modal"] .modal-wrapper.is-open .modal-content {
  transition-delay: var(--emu-common-other-time-transition-short);
  opacity: 1;
  transform: translateY(0);
}

[data-component="modal"] .modal-content {
  padding: var(--emu-component-layers-modal-body-padding-top-narrow) var(--emu-component-layers-modal-body-padding-right-narrow) var(--emu-component-layers-modal-body-padding-bottom-narrow) var(--emu-component-layers-modal-body-padding-left-narrow);
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal-content {
    padding: var(--emu-component-layers-modal-body-padding-top-wide) var(--emu-component-layers-modal-body-padding-right-wide) var(--emu-component-layers-modal-body-padding-bottom-wide) var(--emu-component-layers-modal-body-padding-left-wide);
  }
}

[data-component="modal"] .modal-content .modal__first-el, [data-component="modal"] .modal-content .emu-form__success-message > :first-child {
  margin-right: calc(var(--emu-component-layers-modal-close-size-width-narrow)  + var(--emu-common-spacing-small));
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal-content .modal__first-el, [data-component="modal"] .modal-content .emu-form__success-message > :first-child {
    margin-right: calc(var(--emu-component-layers-modal-close-size-width-wide)  + var(--emu-common-spacing-small));
  }
}

[data-component="modal"] .modal-content .close {
  width: var(--emu-component-layers-modal-close-size-width-narrow);
  height: var(--emu-component-layers-modal-close-size-height-narrow);
  opacity: 1;
  top: var(--emu-component-layers-modal-body-padding-top-narrow);
  right: var(--emu-component-layers-modal-body-padding-right-narrow);
  overflow: hidden;
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal-content .close {
    top: var(--emu-component-layers-modal-body-padding-top-wide);
    right: var(--emu-component-layers-modal-body-padding-right-wide);
  }
}

[data-component="modal"] .modal-content .close.main-close {
  z-index: var(--emu-common-other-z-index-layer);
}

[data-component="modal"] .modal-content .close span {
  display: none;
}

[data-component="modal"] .modal-content .close:before, [data-component="modal"] .modal-content .close:after {
  content: "";
  width: var(--emu-common-border-width-thin);
  background-color: var(--emu-common-colors-black);
  height: 150%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(45deg);
}

[data-component="modal"] .modal-content .close:after {
  transform: translate(-50%, -50%)rotate(-45deg);
}

[data-component="modal"] .modal-cta-container {
  justify-content: flex-start;
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal-cta-container {
    margin-top: var(--emu-common-spacing-large);
  }
}

[data-component="modal"] .modal__pretitle, [data-component="modal"] .modal__title {
  margin-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

@media (min-width: 1024px) {
  [data-component="modal"] .modal__pretitle, [data-component="modal"] .modal__title {
    margin-bottom: var(--emu-common-spacing-large);
  }
}

.js-modal-opened {
  padding-right: var(--scrollbar-width);
  max-height: 100vh;
  overflow: hidden;
}

[data-component="modal"] .modal-content:has(.form--show-success-msg) .modal__title {
  display: none;
}

@media (min-width: 1024px) {
  .my-account {
    gap: var(--emu-semantic-spacing-horizontal-wide);
    flex-direction: row;
    width: 100%;
    display: flex;
  }

  .my-account__sidebar {
    width: 300px;
  }
}

.my-account__sidebar-title {
  word-break: break-word;
}

.my-account__action {
  align-items: center;
  display: flex;
}

.my-account__action--mobile {
  justify-content: center;
}

.my-account__action:before {
  content: "";
  margin-right: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
  background-image: url("resources/images/logout.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 19px;
  height: 18px;
  display: inline-block;
}

@media (min-width: 1024px) {
  .my-account__action:before {
    margin-right: var(--emu-semantic-spacing-horizontal-wide);
  }

  .my-account__main {
    flex-direction: column;
    flex: 1;
    max-width: 600px;
    display: flex;
  }
}

.my-account__main-title {
  padding-bottom: calc(.5 * var(--emu-semantic-spacing-vertical-narrow));
  border-bottom: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
}

.my-account__card {
  border-radius: var(--emu-common-border-radius-xs);
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .my-account__card {
    align-items: unset;
  }
}

.my-account__card-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (min-width: 1024px) {
  .my-account__card-wrapper {
    justify-content: space-between;
    gap: var(--emu-semantic-spacing-horizontal-narrow);
    flex-direction: row;
  }
}

.my-account__card-text {
  letter-spacing: .5px !important;
}

.my-account__card-text--center-in-mobile {
  text-align: center;
}

@media (min-width: 1024px) {
  .my-account__card-text--center-in-mobile {
    text-align: left;
  }
}

.order-materials__label-container {
  justify-content: space-between;
  gap: var(--emu-common-spacing-medium);
  display: flex;
}

.order-materials__label-value span {
  display: block;
}

.aaaem-pf__loader {
  display: none;
}

.pagination button {
  background-color: var(--emu-common-colors-light-grey-500);
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  max-width: 13vw;
  height: 48px;
  display: flex;
}

.pagination button.active {
  background-color: var(--emu-common-colors-blush-100);
}

.plyr.plyr--video {
  --plyr-color-main: var(--emu-common-colors-dark-brown-700);
  --plyr-video-control-color: var(--emu-common-colors-black);
  --plyr-video-controls-background: var(--emu-common-colors-white);
  --plyr-video-range-track-background: var(--emu-common-colors-light-brown-50);
  --plyr-range-track-height: calc(3 * var(--emu-common-sizing-xxs));
  --plyr-control-radius: calc(.5 * var(--emu-common-border-radius-xxs));
  --plyr-control-icon-size: var(--emu-common-sizing-small);
  --plyr-control-spacing: var(--emu-common-spacing-small);
  --plyr-video-control-color-hover: var(--emu-common-colors-black);
  --plyr-video-control-background-hover: var(--emu-common-colors-transparent);
  --plyr-range-thumb-height: calc(.3 * var(--emu-common-sizing-small));
  --plyr-range-thumb-shadow: var(--emu-component-containers-embed-player-vimeo-scrubber-thumb-shadow-one);
  position: relative;
}

.plyr.plyr--video:after {
  content: "";
  opacity: 0;
  pointer-events: none;
  background-image: url("resources/images/spinner.gif");
  background-size: cover;
  width: 70px;
  height: 70px;
  transition: all .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr.plyr--video.plyr--loading:after {
  opacity: 1;
}

.plyr.plyr--video.plyr--playing button.plyr__control.plyr__control--overlaid[data-plyr="play"] {
  opacity: 0;
  pointer-events: none;
}

.plyr.plyr--video.plyr--playing .plyr__controls {
  opacity: 1;
  pointer-events: all;
}

.plyr.plyr--video .plyr__controls {
  opacity: 0;
  pointer-events: none;
}

.plyr.plyr--video button.plyr__control[data-plyr="play"] {
  background-color: var(--emu-common-colors-white);
  opacity: 1;
}

.plyr.plyr--video button.plyr__control[data-plyr="play"].plyr__control--overlaid {
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  display: flex;
}

.plyr.plyr--video .plyr__controls {
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  padding-top: var(--emu-semantic-spacing-half-line-height-narrow);
  padding-bottom: var(--emu-semantic-spacing-half-line-height-narrow);
  border-radius: var(--emu-semantic-border-radius-medium);
  max-width: min(87%, 289px);
  margin-bottom: 19px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1024px) {
  .plyr.plyr--video .plyr__controls {
    padding-left: var(--emu-semantic-spacing-horizontal-narrow);
    padding-right: var(--emu-semantic-spacing-horizontal-narrow);
    max-width: 393px;
  }
}

.plyr.plyr--video .plyr__controls button.plyr__control[data-plyr="play"] {
  padding: var(--emu-common-spacing-small);
  border: var(--emu-common-border-width-thin) solid var(--emu-common-colors-black);
  margin-right: calc(.5 * var(--emu-semantic-spacing-horizontal-narrow));
  border-radius: 100%;
}

.plyr.plyr--video .plyr__controls button.plyr__control[data-plyr="play"] svg {
  width: var(--emu-common-sizing-xs);
  height: var(--emu-common-sizing-xs);
}

.plyr.plyr--video .plyr__controls__item.plyr__progress__container {
  margin-right: var(--emu-common-spacing-small);
  border-radius: var(--emu-common-border-radius-xxs);
}

@media (min-width: 1024px) {
  .plyr.plyr--video .plyr__controls__item.plyr__progress__container {
    margin-right: var(--emu-semantic-spacing-horizontal-wide);
  }
}

.plyr.plyr--video .plyr__controls__item.plyr__volume button.plyr__control {
  padding: var(--emu-common-spacing-xs);
}

.plyr.plyr--video .plyr__controls__item.plyr__volume input[data-plyr="volume"] {
  margin-left: var(--emu-common-spacing-small);
  margin-right: var(--emu-common-spacing-medium);
}

@media (min-width: 1024px) {
  .plyr.plyr--video .plyr__controls__item.plyr__volume input[data-plyr="volume"] {
    margin-right: var(--emu-common-spacing-none);
  }
}

.plyr.plyr--video .plyr__controls button[data-plyr="fullscreen"] {
  padding: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .plyr.plyr--video .plyr__controls button[data-plyr="fullscreen"] {
    margin-left: var(--emu-semantic-spacing-vertical-narrow);
  }
}

.plyr.plyr--video.plyr--fullscreen-fallback {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-height: 100vh;
  display: flex;
  position: fixed;
}

.plyr.plyr--video.plyr--fullscreen-fallback .plyr__controls {
  z-index: var(--emu-common-other-z-index-overlay);
}

.product-card {
  text-align: center;
  border-radius: 6px;
  flex-direction: column;
  align-items: center;
  height: 100%;
  display: flex;
}

.product-card img {
  display: block;
}

:is(.product-card > .container:has(.product-card__button), .product-card > .container:has(.product-card__button-container)) {
  margin-top: auto;
}

.product-card__grid {
  justify-content: center;
  gap: var(--emu-common-spacing-large);
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

@media (min-width: 768px) {
  .product-card__grid {
    grid-template-columns: repeat(2, 350px);
  }
}

@media (min-width: 1024px) {
  .product-card__grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.product-card__grid > .container {
  justify-self: center;
  width: 100%;
  max-width: 400px;
}

.product-card > .container:has(.product-card__button) {
  width: 100%;
  height: 100%;
  display: flex;
}

.product-card__button-container, .product-card__button {
  margin-top: auto;
}

.resource-details__cta a.emu-button__primary-outline:hover:after {
  filter: invert();
}

.resource-details__cta a:after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  width: 19px;
  height: 19px;
  display: inline-block;
}

.resource-details__cta--next a:after, .resource-details__cta--prev a:after {
  background-image: url("resources/images/prev-icon.png");
}

.resource-details__cta--next span, .resource-details__cta--prev span {
  text-transform: none;
}

.resource-details__cta--next {
  justify-content: flex-end;
  display: flex;
}

@media (min-width: 1024px) {
  .resource-details__cta--next {
    margin-bottom: -2px;
  }
}

.resource-details__cta--next a:after {
  transform: rotate(180deg);
}

.resource-details__cta--prev a {
  flex-direction: row-reverse;
}

.resource-details__cta--prev a:after {
  background-image: url("resources/images/prev-icon.png");
}

.resource-details__cta--download a, .resource-details__cta--order a {
  justify-content: center;
  min-width: 160px;
}

.resource-details__cta--download a:after {
  background-image: url("resources/images/download.png");
}

.resource-details__cta--order a:after {
  background-image: url("resources/images/order.png");
}

@media (min-width: 1024px) {
  .resource-details__title {
    font-weight: var(--emu-common-font-weight-light);
  }
}

.resource-details__media {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.resource-details__media--show-video .resource-details__image {
  display: none;
}

.resource-details__videos {
  width: 100%;
  display: block;
}

.resource-details__video {
  margin-bottom: var(--emu-common-spacing-large);
}

.resource-details__video:last-child {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.resource-details__video-title {
  letter-spacing: .5px !important;
}

.resource-details__video-container {
  margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
  margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
  max-width: 550px;
}

@media (min-width: 1024px) {
  .resource-details__video-container {
    margin-left: var(--emu-common-spacing-none);
    margin-right: var(--emu-common-spacing-none);
  }
}

.resource-details__image {
  width: 100%;
  max-width: 318px;
}

.resource-details__content {
  max-width: 584px;
}

.resource-details__list {
  padding-left: var(--emu-common-spacing-none);
  list-style: none;
}

.resource-details__list li {
  gap: var(--emu-semantic-sizing-two-line-height-narrow);
  align-items: center;
  display: flex;
}

.resource-details__list li:last-child {
  margin-bottom: var(--emu-common-spacing-none) !important;
}

.resource-details__list li label {
  min-width: 90px;
  max-width: 40%;
}

.resources-list--blur {
  filter: blur(4px);
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
  cursor: default;
}

.resources-list .product-card {
  text-align: left;
  justify-self: center;
  align-items: flex-start;
  width: 100%;
  max-width: 350px;
  display: none;
  position: relative;
}

.resources-list .product-card--visible {
  display: flex;
}

.resources-list .product-card__link {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.resources-list .product-card__grid {
  gap: 20px;
}

@media (min-width: 1024px) {
  .resources-list .product-card__grid {
    margin-left: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
    margin-right: calc(-1 * var(--emu-semantic-spacing-horizontal-narrow));
  }
}

.resources-list .product-card__image img {
  object-fit: cover;
  width: 100%;
  height: 232px;
}

.resources-list .product-card__pretitle, .resources-list .product-card__title {
  width: 100%;
}

.resources-list .product-card__button.emu-button-v2.emu-button {
  text-decoration: none;
}

.resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--view:after, .resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--download:after, .resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--order:after {
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  width: 12px;
  height: 12px;
  display: inline-flex;
}

.resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--view:after {
  background-image: url("resources/images/view.png");
}

.resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--download:after {
  background-image: url("resources/images/download.png");
}

.resources-list .product-card__button.emu-button-v2.emu-button.product-card__button--order:after {
  background-image: url("resources/images/order.png");
}

.resources-list .product-card__button-container {
  margin-top: auto;
}

.resources-list .product-card__pagination .first-btn, .resources-list .product-card__pagination .last-btn, .resources-list .product-card__pagination .page-counter {
  display: none;
}

.emu-separator {
  margin: var(--emu-common-spacing-none);
}

.cq-Editable-dom--container .emu-separator {
  padding-top: var(--emu-semantic-spacing-vertical-narrow);
  padding-bottom: var(--emu-semantic-spacing-vertical-narrow);
}

.step-form {
  position: relative;
}

.step-form.u-step-form--showing-success .step-form__el, .step-form.u-step-form--showing-success .step-form__cta-container {
  display: none;
}

.step-form.u-step-form--showing-success .step-form__msg--success {
  display: block;
}

.step-form.u-step-form--showing-error .step-form__msg--success {
  display: none;
}

.step-form.u-step-form--showing-error .step-form__msg--error {
  display: block;
}

.step-form.u-step-form--showing-submit .emu-button.emu-button-v2.step-form__cta.step-form__cta--submit, .step-form.u-step-form--showing-next .emu-button.emu-button-v2.step-form__cta.step-form__cta--next, .step-form.u-step-form--showing-prev .emu-button.emu-button-v2.step-form__cta.step-form__cta--prev {
  display: inline-flex;
}

.step-form__msg {
  display: none;
}

.step-form__msg--error {
  border: var(--emu-common-border-width-thin) solid var(--emu-semantic-colors-error-light);
  color: var(--emu-semantic-colors-error-light);
}

.step-form__cta-container .emu-button.emu-button-v2.step-form__cta {
  display: none;
}

.cq-Editable-dom--container .step-form__cta-container .emu-button.emu-button-v2.step-form__cta {
  display: inline-flex !important;
}

.survey__modal--success .modal__title {
  display: none;
}

.survey__modal .modal-content {
  position: relative;
}

.survey__modal .modal-content .step-form {
  position: static;
}

.survey__modal .modal-content .step-form__cta-container {
  gap: var(--emu-common-spacing-none);
}

.aaaem-tabs.aaaem-tabs__horizontal-ui > ul:first-child li {
  margin-left: var(--emu-common-spacing-none);
}

.aaaem-tabs__tab a {
  text-align: center;
  font: var(--emu-semantic-typography-narrow-body-eyebrow);
  text-transform: uppercase;
  border-radius: 12px 12px 0 0;
  font-family: var(--emu-semantic-font-families-heading) !important;
  letter-spacing: 3px !important;
}

@media (min-width: 1024px) {
  .aaaem-tabs__tab a {
    font: var(--emu-semantic-typography-wide-body-eyebrow);
  }
}

.aaaem-tabs__tab a, .aaaem-tabs__tab a:active, .aaaem-tabs__tab a:hover, .aaaem-tabs__tab a:focus {
  text-decoration: none;
}

.cq-Editable-dom--container .aaaem-tabs__tabpanel[hidden] {
  visibility: hidden !important;
  display: none !important;
  position: static !important;
}

.teaser {
  position: relative;
}

.teaser:has(.inline-text-below) {
  height: 412px;
  min-height: 412px;
  transition: all .4s linear;
  overflow: hidden;
}

.teaser:has(.inline-text-below) .cmp-image__image--is-loading {
  max-height: 412px !important;
}

.teaser:has(.inline-text-below) img {
  object-fit: cover;
  opacity: 0;
  height: 412px;
  transition: all .4s linear;
}

.teaser:has(.inline-text-below).u-teaser--img-loaded {
  background-color: var(--emu-common-colors-transparent);
  height: auto;
  min-height: 0;
}

.teaser:has(.inline-text-below).u-teaser--img-loaded img {
  opacity: 1;
  height: auto;
  max-height: 1000px;
}

.teaser .inline-text-below .teaser-container {
  flex-direction: column-reverse;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .teaser-container {
    max-width: var(--emu-semantic-sizing-container);
    display: block;
  }
}

.teaser .inline-text-below.cmp-teaser .teaser-image-container .cmp-teaser__image img {
  width: 100%;
}

@media (min-width: 1024px) {
  .teaser .inline-text-below.cmp-teaser .teaser-image-container .cmp-teaser__image img {
    object-fit: cover;
    min-height: 412px;
  }
}

.teaser .inline-text-below .content-container {
  padding: var(--emu-component-cards-teaser-padding-top-narrow) var(--emu-component-cards-teaser-padding-right-narrow) var(--emu-component-cards-teaser-padding-bottom-narrow) var(--emu-component-cards-teaser-padding-left-narrow);
  position: static;
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .content-container {
    max-width: var(--emu-common-sizing-xxxl);
    padding-top: var(--emu-semantic-spacing-vertical-wide);
    width: 100%;
    height: calc(100% - 74px);
    min-height: 338px;
    position: absolute;
    top: 74px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (min-width: 1200px) {
  .teaser .inline-text-below .content-container {
    padding-left: var(--emu-common-spacing-none);
    padding-right: var(--emu-common-spacing-none);
  }
}

.teaser .inline-text-below .content-container .content {
  width: -moz-fit-content;
  width: fit-content;
}

.teaser .inline-text-below .content-container .content .title-lockup {
  margin-bottom: var(--emu-common-spacing-none);
}

.teaser .inline-text-below .content-container .content.content-top-left, .teaser .inline-text-below .content-container .content.content-middle-left, .teaser .inline-text-below .content-container .content.content-bottom-left {
  margin-left: var(--emu-common-spacing-none);
}

.teaser .inline-text-below .content-container .content.content-top-right, .teaser .inline-text-below .content-container .content.content-middle-right, .teaser .inline-text-below .content-container .content.content-bottom-right {
  margin-right: var(--emu-common-spacing-none);
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .content-container .content > div {
    margin: var(--emu-common-spacing-none);
    width: 100%;
    max-width: min(45vw, 600px);
  }
}

.teaser .inline-text-below .content-container .content br {
  display: none;
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .content-container .content br {
    display: block;
  }
}

.teaser .inline-text-below .content-container .content .emu-teaser__title {
  color: inherit;
  margin: var(--emu-common-spacing-none);
}

.teaser .inline-text-below .content-container .content .emu-teaser__pretitle {
  margin-top: var(--emu-common-spacing-medium);
  font: var(--emu-semantic-typography-narrow-body-eyebrow);
  text-transform: uppercase;
  letter-spacing: 3px !important;
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .content-container .content .emu-teaser__pretitle {
    font: var(--emu-semantic-typography-wide-body-eyebrow);
  }
}

.teaser .inline-text-below .content-container .content .emu-teaser__subtitle {
  margin-top: var(--emu-common-spacing-medium);
  font: var(--emu-component-ingredients-title-h4-typography-narrow);
}

@media (min-width: 1024px) {
  .teaser .inline-text-below .content-container .content .emu-teaser__subtitle {
    font: var(--emu-component-ingredients-title-h4-typography-wide);
  }
}

.teaser .inline-text-below .content-container .content .emu-teaser__description {
  margin-top: var(--emu-common-spacing-small);
  padding: var(--emu-common-spacing-none);
}

.teaser .inline-text-below .content-container .content .emu-teaser__description p {
  display: block !important;
}

.teaser .inline-text-below .content-container .content .emu-teaser__action-container {
  margin-top: var(--emu-common-spacing-large);
  gap: var(--emu-common-spacing-medium);
  display: flex;
}

.teaser .inline-text-below .content-container .content .emu-teaser__subtext {
  padding-top: var(--emu-common-spacing-none);
  margin-top: calc(.5 * var(--emu-semantic-spacing-vertical-narrow));
  font: var(--emu-semantic-typography-narrow-body-xs);
  letter-spacing: 1px !important;
}

.teaser .inline-text-below .content-container .content .emu-teaser__subtext p, .teaser .inline-text-below .content-container .content .emu-teaser__subtext li {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.teaser .inline-text-below .emu-teaser__disclaimer {
  padding: var(--emu-common-spacing-none);
  padding-left: var(--emu-common-spacing-medium);
  padding-right: var(--emu-common-spacing-medium);
  margin-top: var(--emu-common-spacing-none);
  font: var(--emu-semantic-typography-narrow-body-xs);
  width: 100%;
  display: none;
  position: absolute;
  bottom: 10px;
  left: 0;
  letter-spacing: 1px !important;
}

.cq-Editable-dom--container .teaser .inline-text-below .emu-teaser__disclaimer, .teaser .inline-text-below .emu-teaser__disclaimer.show {
  display: block;
}

.teaser .inline-text-below .emu-teaser__disclaimer.text-theme-light {
  color: var(--emu-component-cards-teaser-text-color-light);
}

.teaser .inline-text-below .emu-teaser__disclaimer.text-theme-dark {
  color: var(--emu-component-cards-teaser-text-color-dark);
}

.teaser .inline-text-below .emu-teaser__disclaimer p, .teaser .inline-text-below .emu-teaser__disclaimer li {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  font-family: inherit;
}

.text-field-with-close {
  position: relative;
}

.text-field-with-close .button {
  position: static;
}

.text-field-with-close:has(label) .emu-button {
  transform: scale(1) translateY(-50%) translateY(calc(.5 * var(--emu-semantic-line-heights-narrow-small)  + .5 * var(--emu-common-spacing-xs)));
}

@media (min-width: 1024px) {
  .text-field-with-close:has(label) .emu-button {
    transform: scale(1) translateY(-50%) translateY(calc(.5 * var(--emu-semantic-line-heights-wide-medium)  + .5 * var(--emu-common-spacing-xs)));
  }
}

.text-field-with-close.text-field-with-icon--on-right .emu-button {
  right: 54px;
}

.text-field-with-close.text-field-with-icon--on-right input {
  padding-right: 90px;
}

.text-field-with-close .emu-button {
  transition: all var(--emu-common-other-time-transition-short);
  opacity: 1;
  pointer-events: initial;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: scale(1)translateY(-50%);
}

.text-field-with-close input {
  transition: all var(--emu-common-other-time-transition-short);
  padding-right: 38px;
}

.text-field-with-close:has(input:placeholder-shown) .button {
  opacity: 0;
  pointer-events: none;
  transform: scale(0)translateY(-50%);
}

.cq-Editable-dom--container .cq-Editable-dom--container .text-field-with-close .button, .cq-Editable-dom--container .cq-Editable-dom--container .text-field-with-close .emu-button {
  opacity: 1 !important;
  pointer-events: initial !important;
  display: block !important;
  position: static !important;
  top: 0 !important;
  transform: scale(1)translateY(0) !important;
}

.cq-Editable-dom--container .text-field-with-close .button {
  position: relative !important;
}

.text-field-with-icon {
  position: relative;
}

.text-field-with-icon:has(label) img {
  transform: translateY(-50%) translateY(calc(.5 * var(--emu-semantic-line-heights-narrow-small)  + .5 * var(--emu-common-spacing-xs)));
}

@media (min-width: 1024px) {
  .text-field-with-icon:has(label) img {
    transform: translateY(-50%) translateY(calc(.5 * var(--emu-semantic-line-heights-wide-medium))) translateY(calc(.5 * var(--emu-common-spacing-xs)));
  }
}

.text-field-with-icon.text-field-with-icon--on-right img {
  left: auto;
  right: 16px;
}

.text-field-with-icon.text-field-with-icon--on-right input {
  padding-right: 60px;
}

.text-field-with-icon img {
  object-fit: contain;
  object-position: left center;
  transition: all var(--emu-common-other-time-transition-short);
  width: 22px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
}

.cq-Editable-dom--container .text-field-with-icon img {
  position: static !important;
  transform: none !important;
}

.text-field-with-icon:not(.text-field-with-icon--on-right) input {
  transition: all var(--emu-common-other-time-transition-short);
  padding-left: 60px;
}

.text-img-card {
  align-items: center;
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  flex-direction: column;
  display: flex;
}

@media (min-width: 1024px) {
  .text-img-card {
    gap: var(--emu-semantic-spacing-horizontal-wide);
    flex-direction: row;
  }
}

.text-img-card > .container {
  flex: 1;
  width: 100%;
}

.text-img-card > .image .aaaem-image img {
  width: 100%;
  display: block;
}

@media (min-width: 1024px) {
  .text-img-card--align-content-top {
    align-items: flex-start;
  }

  .text-img-card--align-content-bottom {
    align-items: flex-end;
  }
}

.text-img-card--reverse-in-desktop {
  flex-direction: column;
}

@media (min-width: 1024px) {
  .text-img-card--reverse-in-desktop {
    flex-direction: row-reverse;
  }
}

.text-img-card--hide-image-in-mobile > .image {
  display: none;
}

@media (min-width: 1024px) {
  .text-img-card--hide-image-in-mobile > .image {
    display: block;
  }
}

.text-img-card__eyebrow, .text-img-card__title {
  margin-bottom: var(--emu-semantic-spacing-horizontal-narrow);
}

@media (min-width: 1024px) {
  .text-img-card__eyebrow, .text-img-card__title {
    margin-bottom: var(--emu-common-spacing-small);
  }
}

.text-img-card___list, .text-img-card__disclaimer, .text-img-card__cta, .text-img-card__cta-container {
  margin-top: var(--emu-semantic-spacing-horizontal-narrow);
}

@media (min-width: 1024px) {
  .text-img-card___list, .text-img-card__disclaimer, .text-img-card__cta, .text-img-card__cta-container {
    margin-top: var(--emu-common-spacing-small);
  }
}

.text-img-card__desc p + p {
  margin-top: var(--emu-semantic-spacing-vertical-narrow);
}

.text-img-card__desc, .text-img-card__list {
  letter-spacing: .5px !important;
}

.text-img-card__list ul {
  margin: var(--emu-common-spacing-none);
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  padding-top: var(--emu-common-spacing-medium);
  padding-bottom: var(--emu-common-spacing-medium);
}

.text-img-card__cta-container {
  gap: var(--emu-common-spacing-medium);
  flex-wrap: wrap;
  display: flex;
}

.text-img-card__cta-container .text-img-card__cta {
  margin-top: var(--emu-common-spacing-none);
}

.tip-banner {
  align-items: center;
  gap: var(--emu-semantic-spacing-horizontal-narrow);
  padding-left: var(--emu-semantic-spacing-horizontal-narrow);
  border-left-width: var(--emu-common-border-width-thick);
  border-left-style: solid;
  display: flex;
  position: relative;
}

.tip-banner--error {
  border-left-color: var(--emu-common-colors-red-500);
}

.tip-banner--error .tip-banner__icon path {
  stroke: var(--emu-common-colors-red-500);
}

.tip-banner--success {
  border-left-color: #04aa6d;
}

.tip-banner--success .tip-banner__icon path {
  stroke: #04aa6d;
}

.tip-banner--info {
  border-left-color: var(--emu-common-colors-dark-brown-500);
}

.tip-banner--info .tip-banner__icon path {
  stroke: var(--emu-common-colors-dark-brown-500);
}

.tip-banner--warning {
  border-left-color: var(--emu-common-colors-red-300);
}

.tip-banner--warning .tip-banner__icon path {
  stroke: var(--emu-common-colors-red-300);
}

.tip-banner--hidden {
  display: none;
}

.tip-banner__close {
  position: absolute;
  top: 0;
  right: 0;
}

.tip-banner__img {
  max-width: var(--emu-common-sizing-small);
}

.tip-banner__icon {
  min-height: var(--emu-common-sizing-small);
  min-width: var(--emu-common-sizing-small);
}

/*# sourceMappingURL=main.css.map */
