[data-component=carousel-v2].emu-carousel {
  .tns-controls {
    &.js-show {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-bottom: var(--emu-common-spacing-small);
      gap: 70px; // As per the figma design.
    }
  }

  .emu-carousel__action {
    position: relative;
    transform: none;
  }

  .tns-liveregion {
    position: absolute;
    left: unset;
    top: 3px; // As per the figma design.
    right: 34px; // As per the figma design.
    font: var(--emu-semantic-typography-narrow-body-xs);
    @include letterSpacing('xxl');
    text-transform: uppercase;

    &:not(.can-show) {
      display: none;
    }

    @include mq('large') {
      font: var(--emu-semantic-typography-wide-body-xs);
    }
  }
}
