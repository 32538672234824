.country-selector {
  opacity: 0;

  &--show {
    opacity: 1;
    transition: all var(--emu-common-other-time-transition-base);
  }

  &__main-content {
    opacity: 0;
    transition: all var(--emu-common-other-time-transition-base);

    &--show {
      opacity: 1;
    }

    @include mq('large') {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    @include aem-editor-view {
      position: static;
      opacity: 1;
    }

    &-container {
      position: relative;
    }
  }

  &__title {
    .cmp-title__text {
      word-wrap: break-word;

      @include mq('1023px', 'max-width') {
        font-size: var(--emu-semantic-font-sizes-narrow-xl);
        line-height: var(--emu-semantic-line-heights-narrow-xxl);
      }
    }
  }

  div:has(> .u-width--xs) {
    @include mq('large') {
      width: 100%;
    }
  }

  &__image {
    img {
      @include mq('large') {
        object-fit: cover;
      }
    }
  }

  &__form {
    max-width: 400px; // as per design
  }

  &__dropdown.emu-form-dropdown {
    .emu-dropdown-menu__button,
    .emu-dropdown-menu__item,
    .emu-dropdown-menu__group {
      border-color: var(--emu-common-colors-light-grey-400);
      font-size: var(--emu-semantic-font-sizes-narrow-small);
      @include letterSpacing('sm');
    }

    .emu-dropdown-menu__button {
      background-color: var(--emu-common-colors-white);
    }

    // hiding the first child which contains default selection
    .emu-dropdown-menu__item {
      &:first-child[data-value='--'] {
        display: none;
      }
    }

    // to handle overflow in case of many countries and update transition duration
    .emu-dropdown-menu__group {
      max-height: calc(0.5 * var(--emu-common-sizing-xxxl));
      overflow: auto;
      transition-duration: 0.5s;
    }

    // to remove vertical animation for dropdown, added selectors to increase specificity
    &.emu-form-dropdown.js-hide-dropdown {
      .emu-dropdown-menu__group {
        transform: translateY(0);
      }
    }
  }
}
